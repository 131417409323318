import React, { createContext, ReactElement, useContext, useEffect, useState, } from 'react';


import { Button, Modal } from '@mui/material';
import instance from '../services/instance';

export type AuthProvideProps = {
  children: ReactElement;
};

interface AuthContextData {
  signed: boolean;
  id: number | null;
  name: string,
  email: string,
  celular: string
  mensagemError: string | null;
  errorMessage: string | null;

  Login(clientId: any, user: object): Promise<void>;

  Logout(): void;
}

export const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<AuthProvideProps> = ({
                                                           children,
                                                         }: AuthProvideProps): JSX.Element => {
  const [id, setUser] = useState<number | null>(0);
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    celular: ''
  })

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [mensagemDeErro, setMensagemDeErro] = useState({
    mensagemError: '',
  })

  let erroMensagem;


  useEffect(() => {
    const storagedToken = localStorage.getItem('@App:token');
    const storagedUserInfo = localStorage.getItem('@App:user')


    if (storagedToken && storagedUserInfo) {
      setUserData(JSON.parse(storagedUserInfo))
      // @ts-ignore
      instance.defaults.headers.Authorization = `Bearer ${storagedToken}`;
    }
  }, []);

  useEffect(() => {
    console.log("DadosUser:", userData)
  }, [userData]);


  async function Login(clientId: any, userData: object) {
    try {
      const response = await instance.post(`/api/Otg/Login?ClientId=${clientId}`, userData);
      // console.log("Dados User", response);

      setUserData({
        name: response.data.nome,
        email: response.data.email,
        celular: response.data.telefone
      });
      setUser(response.data.id);
      // @ts-ignore
      instance.defaults.headers.Authorization = `Bearer ${response.data.token}`;
      localStorage.setItem('@App:user', JSON.stringify({
        name: response.data.nome,
        email: response.data.email,
        celular: response.data.telefone
      }));
      localStorage.setItem('@App:id', JSON.stringify(response.data.id));
      localStorage.setItem('@App:token', response.data.token);
    } catch (error: any) {
      if (error.response) {
        setErrorMessage(error.response.data.message)

        sessionStorage.setItem('@App:errorAuth', errorMessage)
      }
      //setShowErrorModal(true)
    }
  }

  function Logout() {
    localStorage.setItem('@App:user', '')
    localStorage.setItem('@App:token', '')
    localStorage.setItem('@App:id', '')
    setUser(null);
  }

  interface ErrorModalProps {
    errorMessage: string;
    onClose: () => void;
  }

  
  const ErrorMsg: React.FC<ErrorModalProps> = ({errorMessage, onClose}) => {
    return (
      <Modal open={true} onClose={onClose} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <div style={{backgroundColor: '#fff', maxWidth: 400, maxHeight: 300, padding: 20, borderRadius: 5}}>
          <h2 style={{color: 'red'}}>Erro!</h2>
          <p>{errorMessage}</p>
          <Button onClick={onClose} variant="contained" color="primary" style={{
            background: '#801212',
            color: 'white'
          }}>Fechar</Button>
        </div>
      </Modal>
    );
  };


  return (
    <>
      <AuthContext.Provider value={{
        signed: Boolean(id),
        name: userData.name,
        email: userData.email,
        celular: userData.celular,
        id,
        Login,
        Logout,
        mensagemError: mensagemDeErro.mensagemError,
        errorMessage
      }}>

        {children}

      </AuthContext.Provider>

      {showErrorModal && (
        <ErrorMsg errorMessage={errorMessage} onClose={() => setShowErrorModal(false)}/>
      )}
    </>

  );
};

export function useAuth() {
  const context = useContext(AuthContext);


  return context;
}


