import React from "react";
import {useGameContext} from "../../../context/game.context";
import {
  BotaoVoltar,
  ContainerIcon,
  ContainerInfo,
  ContainerInnerInfos,
  ContainerTopBar,
  ContainerVoltar,
  Image
} from "./styles";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

export const TopBarGames = () => {
  const {grupoAtivo} = useGameContext();

  const {nomeGrupo} = useParams();
  const {t, i18n} = useTranslation('mineGame')

  return (
    <ContainerTopBar degradeColor={"linear-gradient(90deg, #074895 0%, #905CB6 100%);"}>
      <ContainerInfo>
        <ContainerVoltar to={`../../../../${sessionStorage.getItem('@Subdomain')}/ListaGrupos`}>
          <BotaoVoltar fontSize="large"/>
        </ContainerVoltar>
        <ContainerInnerInfos>
          <h1>{nomeGrupo}</h1>
        </ContainerInnerInfos>
      </ContainerInfo>
      <ContainerIcon>
        <Image imagem={grupoAtivo.iconUrl}/>
      </ContainerIcon>
    </ContainerTopBar>
  )
}
