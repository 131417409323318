import styled from 'styled-components';

interface BackgroundDivProps {
  corPrimaria: string;

}

export const BackgroundDiv = styled.div`
    /* background: linear-gradient(to bottom right, #ff0000 5%, #E0E0E0 100%); */
    background: #ffffff;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;

    
    color: black;

    @media screen and (max-width: 769px){
        max-height: 150vh;
        overflow-x: unset;
        overflow-y: hidden;
       

        max-width: 100vw;
        background: linear-gradient(0deg, ${(props: BackgroundDivProps) => props.corPrimaria}50, ${(props: BackgroundDivProps) => props.corPrimaria}50), var(--ImagemFundo-Main); 
        background-size: cover;
        

    }
`;
