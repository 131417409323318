import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {BoxMensagem, DivMensagemInteira, DivPai, HoraMensagem, MensagemTexto} from './styles';
import './styles.css';


import moment from "moment";
import {CSSTransition} from "react-transition-group";
import bgimg from '../../../assets/bgmsgopacity1.png'
import {useGameContext} from '../../../context/game.context';
import { useTranslation } from 'react-i18next';

interface MensagensProps {
  conteudo: any,
  status: any,
  key: any,
  hora: any,
  jogadaId: any,
  onRender?: () => void,
  setOtherMessages: any,
  ultimaAtualizacao: boolean,
  chatid: string | any,
  dadosCompletos: any,
  nomeAmigavel: any,
}

const Mensagem: React.FC<MensagensProps> = ({
                                              conteudo,
                                              status,
                                              ultimaAtualizacao,
                                              key,
                                              hora,
                                              jogadaId,
                                              onRender,
                                              setOtherMessages,
                                              chatid,
                                              dadosCompletos,
                                              nomeAmigavel
                                            }) => {
  const {chatId, afiliadoId, tipoMesa} = useParams();
  const [data, setData] = useState(null);
  const [show, setShow] = useState(false);


  const containerRef = useRef<HTMLDivElement>(null);
  const prevDataRef = useRef<any[]>([]);
  const navigate = useNavigate();
  const [clientId, setClientId] = useState<any>()
  const {t, i18n} = useTranslation('cardMensagem')

  useEffect(()=>{
    setClientId(sessionStorage.getItem('@ClientId'))
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setShow(false); // Limpa o estado de novas mensagens após 5 segundos
    }, 5000);

    return () => clearInterval(interval);

  }, []);


  const horaFormatada = (hora: moment.MomentInput) => {
    const dataHora = moment(hora, "M/D/YYYY h:mm:ss A");
    return dataHora.format("HH:mm");
  };

  const getHoraFormatada = (hora: moment.MomentInput) => {
    return horaFormatada(hora);
  };

  const [palavras, setPalavras] = useState<string[]>([]);
  const [primeiraPalavra, setPrimeiraPalavra] = useState<string>('');
  const [entradaConfirmada, setEntradaConfirmada] = useState<any>()
  //console.log(conteudo)
  useEffect(() => {
    const regex = /<b>([^<]+)<\/b>/;
    const match = regex.exec(conteudo);
    setEntradaConfirmada(match ? match[1] : null);

    if (entradaConfirmada) {
      const palavras = entradaConfirmada.split(' ');
      setPalavras(palavras);
      setPrimeiraPalavra(palavras[0]);
    }
  }, [conteudo]);


  const regexMesa = /<b>[^<]+<\/b>:\s*(.+)/;
  const matchMesa = regexMesa.exec(conteudo);
  const mesaJogo = matchMesa ? matchMesa[1].trim() : null;
  //console.log(mesaJogo)


  const regexPadraoTitulo = /<b>Padrão<\/b>: ([^<🚥]+)/;
  const matchPadraoTitulo = regexPadraoTitulo.exec(conteudo)
  const padraoTitulo = matchPadraoTitulo ? matchPadraoTitulo[1].trim() : null
  //console.log(padraoTitulo)

  const regexPadrao = /\[(.*?)\]<\/b>/;
  const matchPadrao = regexPadrao.exec(conteudo)
  const sequencia = matchPadrao ? matchPadrao[1] : null;
  //console.log(sequencia, 'sequencia')

  const regexLink = /<a href="([^"]+)">Computador<\/a>/;
  const matchLink = regexLink.exec(conteudo);
  const link = matchLink ? matchLink[1] : null;

  function handleClick() {
    // window.open(`${link}`, "_blank");
    setExibirDetalhes(true)
  }

  //console.log(link);

  const regexEstrategia = /<b>Estratégia<\/b>: ([^\n]+)/;
  const matchEstrategia = regexEstrategia.exec(conteudo);
  const estrategia = matchEstrategia ? matchEstrategia[1] : null;
  //console.log(estrategia)
  const {setExibirDetalhes, setActualDetails, setNomeRoletaAmigavel, nomeRoletaAmigavel} = useGameContext();


  return (

    <>
      <CSSTransition key={Math.random()} timeout={100} classNames="my-node">


        <DivPai onClick={() => {
          setExibirDetalhes(true);
          setActualDetails({dadosCompletos});
        }} fadeout={ultimaAtualizacao} className={show ? '' : 'fade-out'}
                style={{
                  height: conteudo.includes("GREEN") || conteudo.includes("RED") ? "fit-content" : "fit-content",
                }}
        >

          <DivMensagemInteira ref={containerRef}> {/* Aplica a animação de fade apenas se houver novas mensagens */}

            <BoxMensagem style={{
              height: status.includes("GREEN") || status.includes("RED") && !conteudo.includes("roleta") ? "150px" : conteudo.includes("roleta") ? "150px" : "150px",
              fontWeight: conteudo.includes("GREEN") || conteudo.includes("RED") ? "200" : "200",
              // border: status.includes("GREEN") ? "2px solid #049201" : status.includes("RED") && "2px solid #DA0000",

              marginBottom: `${(conteudo.includes("ANALISANDO") && !status.includes("aguardando status")) ? '70px' : '0px'}`,

              marginTop: `${conteudo.includes("ANALISANDO") && '0px'}`,

              color: 'white',
              width: '100%',

              background: 'rgba(0,0,0,1)',
              backgroundImage: `url(${bgimg})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              padding: '10px'
            }}>

              <MensagemTexto>
                <h2 style={{
                  color: 'var(--Titulo-Mensagens)',
                  textShadow: '10px 0 10px var(--Primary-color)',
                  fontFamily: 'Montserrat',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: '15px',
                  marginBottom: '7px',
                  paddingTop: '10px',
                  textTransform: 'uppercase'
                }}>
                  {status.includes("GREEN") | status.includes("RED") ? `${t("Resultado")}` : `${t(entradaConfirmada)}`}
                  {/* {palavras[0]} {palavras[1] && <span style={{color: 'var(--Primary-color)'}}>{palavras[1]}</span>} */}
                </h2>

                <span style={{
                  fontFamily: 'Montserrat',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: '12px',
                  marginBottom: '7px',
                  marginTop: '7px',
                  color: 'var(--Secondary-Text-color)',

                }}>
                  {mesaJogo}
                </span><br/>

                <p style={{
                  fontFamily: 'Montserrat',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '14px',
                  marginBottom: '7px',
                  marginTop: '7px',
                  color: 'var(--Secondary-Text-color)',
                  width: '75%',

                }}>
                  <span style={{fontWeight: 'bold', color: 'white'}}>{t("Padrão:")} {t(`${padraoTitulo}`)} </span>
                  <br/>

                </p>
                <span style={{color: 'var(--Secondary-Text-color)', fontWeight: '600'}}>
                  {sequencia}
                </span>

                {/* <p style={{
                  fontFamily: 'Montserrat',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '14px',
                  marginBottom: '7px',
                  marginTop: '7px',
                  color: 'var(--Secondary-Text-color)',
                  width: '75%',
                }}>
                  <span style={{ fontWeight: 'bold', color: 'white' }}>Estratégia: </span><br />
                   {estrategia}
              </p> */}
                {/* <TextoFormatado>

    </TextoFormatado> */}


                <button style={{
                  display: `${!conteudo.includes("ANALISANDO") && (status.includes("GREEN") || status.includes("RED")) ? 'none' : ''}`,
                  color: 'var(--Secondary-Text-color)',
                  position: 'absolute',
                  right: '10px',
                  top: '70px',
                  zIndex: 9,
                  border: 'none',
                  width: '70px',
                  height: '28px',
                  textAlign: 'center',
                  alignItems: 'center',
                  fontSize: '12px',
                  fontFamily: 'Montserrat',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '15px',
           
                  background: `${clientId == "16" ? 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)' : 'var(--Primary-color)'}`,
                  borderRadius: `${clientId == "16" ? '30px' : '30px'}`,
                  boxShadow: `${clientId == "16" ? '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)' : '0px 0px 5px 2px rgba(0,0,0,0.1), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
                


                }} onClick={handleClick}>{t("Acessar")}
                </button>

                <span style={{

                  position: 'absolute',
                  top: 20,
                  right: 10,

                  background: `${status.includes("GREEN") ? '#34C700' : '#DA0000'}`,
                  boxShadow: `${status.includes("GREEN") ? '0px 0px 5px 2px rgba(52, 199, 0, 0.37), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)' : '0px 0px 5px 2px rgba(218, 0, 0, 0.49), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`,
                  borderRadius: '20px',
                  width: '56px',
                  height: '20px',
                  display: `${(status.includes("GREEN") || status.includes('RED')) ? 'flex' : 'none'}`,
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '11px',
                  fontWeight: '500',


                }}>{status.includes('GREEN') ? 'GREEN' : 'RED'}</span>

                {/* <DivHora> */}
                <HoraMensagem>
                  {getHoraFormatada(hora)}
                  {/* {hora.slice(9, 14)} */}
                </HoraMensagem>

                {/* </DivHora> */}

              </MensagemTexto>

              {/* box-shadow: 0.5px 0.5px 10px 0.5px grey;*/}

            </BoxMensagem>


          </DivMensagemInteira>
        </DivPai>
      </CSSTransition>

    </>
  );
};

export default Mensagem;
