import React, {useEffect} from 'react'
import {ContainerLogin} from './styles'
import './styles.css'
import Register from '../../components/CardRegister'
import {ImageLoginPage} from "../Login/styles";
import {useCustomizacao} from '../../Customizacao';


const RegisterPage: React.FC = () => {

  const {setExibirMenu} = useCustomizacao()

  useEffect(() => {
    setExibirMenu(false);
    return () => {
      setExibirMenu(true);
    }
  }, [])

  return (
    <>
      <ContainerLogin>
        <ImageLoginPage/>
        <Register/>
      </ContainerLogin>
    </>
  )
}

export default RegisterPage
