import styled from 'styled-components';
import {IMaskInput} from "react-imask";
import InputMask from 'react-input-mask';


export const DivBannerTop = styled.div`
  background: #000;
  width: 100%;
  height: 160px;

`;

export const BannerTop = styled.img`
  width: 100%;
  height: 160px;
  object-fit: cover;
  background: #000;
  opacity: 0.4;
  position: relative;
  
`;

export const LogoBanner = styled.div`
  position: absolute;
  top: 30px;
  left: calc(50% - 109px/2);
  z-index: 1;
  background-image: var(--Logo-url);
  
`;


export const PaiLogin = styled.div`
  width: 100vw;
  max-width: 100vw;
  height: 150vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;


  @media screen and (max-width: 1022px){
    height: 100%;
  }
`
export const Cardlogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 99vw;
  

  @media screen and (min-width: 1023px){
    overflow-y: scroll;
  padding-bottom: 100px;
  padding-top: 100px;

  }

  @media screen and (max-width: 1022px){
    padding-bottom: 0px;
  }

    
`
export const ContentBackground = styled.div`
    background: var(--Background-color);
    width: 100%;
    height: 100vh;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: scroll;


    @media screen and (max-width: 1022px){
      background: var(--Register-image-mobile);
      background-color: var(--Secondary-Background-color);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 110%;
  
    }
`

export const PaiInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items:center;
  
  
`

export const StyledLabel = styled.label`
  width: 100%;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  /* Black */

  color: var(--Text-color);
  @media screen and (max-width: 1022px){
    color: var(--Secondary-Text-color);
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  background: #FFF;
  color: var(--Text-color);
  height: 52px;
  box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: none;
  padding-bottom: 0px !important;
  box-sizing: border-box;
  padding-left: 25px;
  font-family: 'Montserrat';
  max-width: 500px;
  font-size: 18px;

  &:focus{
    outline: none;
  }

  &::placeholder{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;

    color: #999999;
  }
`;

export const TelInput = styled(InputMask)`
background: #FFF;
  width: 100%;
  color: var(--Text-color);
  height: 50px;
  box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: none;
  padding-bottom: 0px !important;
  box-sizing: border-box;
  padding-left: 25px;
  font-family: 'Montserrat';
  max-width: 500px;
  font-size: 18px;

  &:focus{
    outline: none;
  }

  &::placeholder{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;

    color: #999999;
  }
`;

export const StyledButtonEntrar = styled.button`
background: var(--Primary-color);
  box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-width: 500px;
  height: 50px;
  color: white;
  border: none;
  font-family: 'montserrat', sans-serif;
  font-weight: bold;
  width: 100%;
  

  &:hover{
    cursor: pointer;
  }

`;

export const StyledButtonRegistrar = styled.button`
  max-width: 500px;
  height: 50px;
  color: white;
  border: none;
  background: var(--Secondary-color);
  box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-family: 'montserrat', sans-serif;
  font-weight: bold;
  width: 100%;

  &:hover{
    cursor: pointer;
  }

  @media screen and (max-width: 1022px){
    background: #636363;
  }

`;

export const StyledSpan = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  /* Red */

  color: var(--LinksColor-primary);

  text-decoration: none;
  
`;

export const MsgErrorLabel = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  /* identical to box height */
  /* status-error */
  max-width: 500px;
  color: #DA0000;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  max-width: 500px;
  gap: 8px;
`


export const StyledForm = styled.form`
  min-width: 400px;

  @media screen and (max-width: 770px){
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
`;