import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {useNavigate} from 'react-router-dom';
import {Circle, ContainerMenu, Icon, Menu, MenuBorder, MenuItem} from './BottomNav';
import {useCustomizacao} from '../../Customizacao';
import {Icon as Iconfy} from '@iconify/react';
import gambling from '../../assets/gambling.svg';


const useStyles = makeStyles({
  myIcon: {
    fontSize: 2,
    color: '#3f60a0',
    '&:hover': {
      color: '#801212',
      cursor: 'pointer',
    },
  },
});

const MenuBottom: React.FC = () => {
  const [active, setActive] = useState(false);
  const [menuAtivo, setMenuAtivo] = React.useState("2");
  const {exibirMenu, primaryColor} = useCustomizacao();

  const [clientId, setClientId] = useState<any>()

  useEffect(()=>{
    setClientId(sessionStorage.getItem('@ClientId'))
  }, [])

  const classes = useStyles();
  const navigate = useNavigate();
  //...

  const signOut = () => {
    sessionStorage.getItem('token')
    sessionStorage.removeItem('token')
    navigate(`/${sessionStorage.getItem('@Subdomain')}`)
  }


  const setBackgroundColor = () => {
    const activeItem = document.querySelector(".active");
    if (activeItem) {
      document.body.style.backgroundColor = "purple";
    }
  }

  const calculateMenuBorderPosition = (animateTransition = false) => {
    const activeItem = document.querySelector(".menu-item.active") as HTMLElement;
    const menuBorder = document.querySelector('.menu-border') as HTMLElement;
    if (!menuBorder || !activeItem) {
      return;
    }

    if (!animateTransition) {
      menuBorder.classList.remove('transition');
    }

    let box = activeItem.getBoundingClientRect();
    let left = box.left - ((menuBorder.offsetWidth - box.width) / 2) + "px";
    menuBorder.style.transform = `translate3d(${left}, 0 , 0)`;

    if (!animateTransition) {
      setTimeout(() => {
        menuBorder.classList.add('transition');
      }, 0);
    }
  }

  const handleMenu = (menu: string) => {
    setMenuAtivo(menu);

    setTimeout(() => {
      setBackgroundColor();
      calculateMenuBorderPosition(true);
    }, 100);
  };

  React.useEffect(() => {
    setBackgroundColor()
    calculateMenuBorderPosition()
  }, [])


  useEffect(() => {
    setActive(false)
    setActive(true)
  }, [menuAtivo])


  if (!exibirMenu)
    return null;

  return (

    <ContainerMenu>

      <Menu id='menu' style={{
        background:  `${clientId == "16" || clientId == "32" ? '#84040A' : `${primaryColor}80`}`,
      }}>
        <MenuItem to={`../${sessionStorage.getItem('@Subdomain')}/ebook`} onClick={() => {
          handleMenu('1')
        }} active={menuAtivo == "1"} className={menuAtivo == "1" ? 'menu-item active' : 'menu-item'}>
          {menuAtivo == "1" ?
            <Circle style={{
             
              background: `${clientId == '16' || clientId == "32" && 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)'}`,
              boxShadow: `${clientId == "16" || clientId == "32" && '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`,
              borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
              opacity: '1'


            }}>
              <Icon>

                <Iconfy icon="dashicons:book" color='#FFF'/>
              </Icon>
            </Circle>

            :

            <Icon style={{
              
            }}>

              <Iconfy icon="dashicons:book" color='#FFF'/>

            </Icon>

          }
        </MenuItem>

        <MenuItem to={`../${sessionStorage.getItem('@Subdomain')}/ListaGrupos`} onClick={() => {
          handleMenu('2')
        }} active={menuAtivo == "2"} className={menuAtivo == "2" ? 'menu-item active' : 'menu-item'}>
          {menuAtivo == "2" ?
            <Circle style={{
              background: `${clientId == '16' || clientId == "32" && 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)'}`,
              boxShadow: `${clientId == "16" || clientId == "32" && '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`,
              borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
            }}>
              <Icon>

                <img src={gambling} style={{width: '30px', height: '30px'}}/>

              </Icon>
            </Circle>

            :

            <Icon>

              <img src={gambling} style={{width: '30px', height: '30px'}}/>

            </Icon>

          }
        </MenuItem>

        <MenuItem to={`../${sessionStorage.getItem('@Subdomain')}/Perfil`} onClick={() => {
          handleMenu('3')
        }} active={menuAtivo == "3"} className={menuAtivo == "3" ? 'menu-item active' : 'menu-item'}>
          {menuAtivo == "3" ?
            <Circle style={{
             
              background: `${clientId == '16' || clientId == "32" && 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)'}`,
              boxShadow: `${clientId == "16" || clientId == "32" && '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`,
              borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
            }}>
              <Icon>

                <Iconfy icon="mingcute:user-3-fill" color='#FFF'/>

              </Icon>
            </Circle>

            :

            <Icon style={{
             
            }}>

              <Iconfy icon="mingcute:user-3-fill" color='#FFF'/>

            </Icon>

          }

        </MenuItem>


        <MenuBorder className='menu-border transition'>

        </MenuBorder>

      </Menu>
      <div>
        <svg viewBox="0 0 202.9 45.5">
          <clipPath id="menu" clipPathUnits="objectBoundingBox"
                    transform="scale(0.0049285362247413 0.021978021978022)">
            <path d="M6.7,45.5c5.7,0.1,14.1-0.4,23.3-4c5.7-2.3,9.9-5,18.1-10.5c10.7-7.1,11.8-9.2,20.6-14.3c5-2.9,9.2-5.2,15.2-7
                c7.1-2.1,13.3-2.3,17.6-2.1c4.2-0.2,10.5,0.1,17.6,2.1c6.1,1.8,10.2,4.1,15.2,7c8.8,5,9.9,7.1,20.6,14.3c8.3,5.5,12.4,8.2,18.1,10.5
                c9.2,3.6,17.6,4.2,23.3,4H6.7z"/>
          </clipPath>
        </svg>
      </div>
    </ContainerMenu>
  );

};

export default MenuBottom;
