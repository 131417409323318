import React, {useEffect, useState} from 'react';
import {
  BotaoGame,
  Container,
  ContainerDica,
  ContainerInfos,
  ContainerLinkJogo,
  ContainerPage,
  IframeContainer,
  InfoTopbar,
  StyledText,
  TextoDetalhes,
  TextoInfoJogo,
  TopBar
} from "./styles";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {useCustomizacao} from '../../../Customizacao';
import {useGameContext} from '../../../context/game.context';
import axios from 'axios';
import HeaderChatDetails from '../headerChatDetails';
import AdjustIcon from '@mui/icons-material/Adjust';
import Loading from '../../Loading';
import {animateScroll as scroll} from 'react-scroll';
import { useTranslation } from 'react-i18next';
import Header from '../../Header';
import { Live } from '../../Live';

//.....
const MineGameComponent: React.FC = () => {
  const {cores, logoUrl, iframeBool} = useCustomizacao();
  const {setExibirDetalhes, actualDetails} = useGameContext();
  const {activeGame, getUltimosDados} = useGameContext();

  const [nomeRoleta, setFinalNomeRoleta] = React.useState<string | null>(null);
  const [estrategiaDoPadrao, setEstrategiaDoPadrao] = React.useState<string | null>(null);
  const [estrategia, setEstrategia] = React.useState<string | null>(null);
  const [sequencia, setSequencia] = React.useState<string | null>(null);
  const [linkMobile, setLinkMobile] = useState<any>('')

  const [cobrir, setCobrir] = useState<any>()
  const [nomeAmigavel, setNomeAmigavel] = useState<any>()

  const [texto, setTexto] = React.useState<string>("");
  const [resultado, setResultado] = useState<any>();

  const [controleTexto, setControleTexto] = useState<any>(false);
  const [clientId, setClientId] = useState<any>()
  const [isDesktop, setIsDesktop] = useState<any>()
  const {t, i18n} = useTranslation('detailsPage')

  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 769);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

  }, [])
 
  useEffect(() => {
    setClientId(sessionStorage.getItem('@ClientId'))
  }, [])


  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  useEffect(() => {
    const nomeRoleta = /<b>Roleta<\/b>: ([^\n]+)/;
    const matchNomeRoleta = nomeRoleta.exec(actualDetails.dadosCompletos.texto);
    const finalNomeRoleta = matchNomeRoleta ? matchNomeRoleta[1] : null;

    const regexDoPadrao = /<b>Padrão<\/b>: ([^\n]+)/;
    const matchDoPadrao = regexDoPadrao.exec(actualDetails.dadosCompletos.texto);
    const finalestrategiaDoPadrao = matchDoPadrao ? matchDoPadrao[1] : null;

    const regexEstrategia = /<b>Estratégia<\/b>: ([^\n]+)/;
    const matchEstrategia = regexEstrategia.exec(actualDetails.dadosCompletos.texto);
    const finalestrategia = matchEstrategia ? matchEstrategia[1] : null;

    const regexPadrao = /\[(.*?)\]<\/b>/;
    const matchPadrao = regexPadrao.exec(actualDetails.dadosCompletos.texto)
    const finalSequencia = matchPadrao ? matchPadrao[1] : null;

    const regexCobrir = /🟢\s(.+?)\s🟢/;
    const matchCobrir = regexCobrir.exec(actualDetails.dadosCompletos.texto)
    const valorCobrir = matchCobrir ? matchCobrir[1] : null

    //...
    
    setFinalNomeRoleta(finalNomeRoleta);
    setEstrategiaDoPadrao(finalestrategiaDoPadrao);
    setEstrategia(finalestrategia);
    setSequencia(finalSequencia);
    setTexto(actualDetails.dadosCompletos.texto);
    setNomeAmigavel(actualDetails.dadosCompletos.nomeRoletaAmigavel)

    if(texto.includes("GREEN") || texto.includes("RED")){
      setControleTexto(true)
      setTexto(actualDetails.dadosCompletos.texto)
    }

    // console.log(texto)

    if (valorCobrir) {
      setCobrir(valorCobrir.replace(/<b>|<\/b>/g, ""))
    }

    const regexLinkMobile = /<a href="([^"]+)">Celular<\/a>/;
    const matchLinkMobile = regexLinkMobile.exec(actualDetails.dadosCompletos.texto)
    const linkFinal = matchLinkMobile ? matchLinkMobile[1] : null
    setLinkMobile(linkFinal)

  }, [])

  useEffect(()=>{
  
    if(texto.includes("GREEN") || texto.includes("RED")){
     
      const regexResultado = /\[(.*?)\]/;
      const matchResultado = regexResultado.exec(texto)
      const resultadoFinal = matchResultado ? matchResultado[1] : null
      // console.log(matchResultado, 'matchResultado')
      setResultado(resultadoFinal)
      
  }
  
  }, [texto])

  const fetchData = async () => {
    await axios.get(`https://a4.lineragames.com.br/api/Otg/GetJogadaId?jogadaId=${actualDetails.dadosCompletos.jogadaId}`)
      .then(r => {
        if (!r.data.length)
          setTexto("Cancelada");
        else {
          //console.log(r.data[0].texto);
          setTexto(r.data[0].texto);
        }
      })
  }

  useEffect(() => {
    fetchData()
    const interval = setInterval(() => {
      fetchData();
      
    }, 3000);

    return () => clearInterval(interval);
  }, [])

  //lalalal
  const createMarkup = () => {
    return {
      __html: activeGame.texto
        .split('\\r\\n\\r\\n')[0].split(`<p>🖥️</p>`)[0]
    };
  };

  return (
    <>
    {!isDesktop ? 
    
    <ContainerPage>
      <TopBar>
        <HeaderChatDetails gradient={sessionStorage.getItem('@GradientGrupo')}
                           nomeDoGrupo={sessionStorage.getItem('@NomeDoGrupo')}/>
      </TopBar>
    
      
      <Container style={{marginTop: '0px'}}>
      <div style={{display: 'flex', width: '100%', flexDirection: 'column', gap: '8px',height: 'auto'}}>
        <ContainerInfos>
          <div style={{}}>

            <TextoInfoJogo  analisando={ texto.includes("ANALISANDO") || texto.includes("CONFIRMADA")} >
            <div style={{
                  position: 'absolute',
                  display: `${texto.includes("ANALISANDO") || texto.includes("CONFIRMADA") ? 'flex' : 'none'}`,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  left: '4px',
                  top: '4px', 
                }}>
                <Loading text={""} tipoMesa={1}/>
              </div>

              <AdjustIcon style={{
                fill: 'var(--Primary-color)',
                fontWeight: 'bold',
                fontSize: '28px',
                display: `${texto.includes("GREEN") || texto.includes("RED") || texto.includes("Cancelada") ? 'flex' : 'none'}`
              }}/>
              
              {texto === 'Cancelada' ?
                <StyledText><span style={{color: 'white'}}>{t("Jogada")}</span> <span>{t("Cancelada")}</span></StyledText> :
                texto.includes("GREEN") ?
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                    <StyledText
                      style={{color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{t("Resultado")}
                      : &nbsp;

                      <span style={{color: 'white', background: '#34C700', borderRadius: '20px', boxShadow: '0px 0px 5px 2px rgba(52, 199, 0, 0.37), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)',
                      width: '56px',
                      height: '20px',
                      fontSize: '11px',
                      fontWeight: '500'
                    }}> 
                    {t("GREEN")} </span>

                    </StyledText></div> :
                  texto.includes("RED") ?
                    <div
                      style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                      <StyledText
                        style={{color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{t("Resultado")}
                        : &nbsp;
                        <span style={{color: 'white', background: '#DA0000', borderRadius: '20px', boxShadow: '0px 0px 5px 2px rgba(218, 0, 0, 0.49), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)',
                      width: '56px',
                      height: '20px',
                      fontSize: '11px',
                      fontWeight: '500'
                    }}>  
                      {t("RED")}
                    </span> </StyledText></div> :
                    texto.includes('ANALISANDO') ?

                      <StyledText>{t("Analisando")}</StyledText> :

                      <StyledText style={{color: 'white'}}>{t("Entrada")} <span
                        style={{color: 'var(--Primary-color)', fontSize: '16px'}}>{t("Confirmada")}</span>
                      </StyledText>}

                      {texto.includes("ANALISANDO") && 
                        <ContainerDica 
                        style={{ 
                          top: '-2px', 
                          right: '130px', 
                          width: '120px', 
                          height: '22px',
                          alignItems: 'center', 
                          justifyContent: 'center', 
                          textAlign: 'center', 
                          fontSize: '8px', 
                          borderRadius: '25px',
                          border: '1px',
                          boxShadow: '0px 0px 1px 1px var(--Primary-color)'

                          }}>
                        
                        <h1 style={{fontWeight: 'normal', fontFamily: 'Montserrat', fontSize: '10px', color: 'var(--Primary-color)'}}>
                          Aguarde a entrada
                        </h1>

                        </ContainerDica>
                    }

            </TextoInfoJogo>
          </div>
          <div style={{height: '2px'}} />
          <InfoTopbar>
            <h1 style={{marginLeft: '4px'}}>{nomeAmigavel}</h1>
          </InfoTopbar>
          <TextoDetalhes style={{marginLeft: '4px', marginBottom: '-2px'}}>

            <span style={{fontWeight: 'bold'}}>{texto.includes("GREEN") || texto.includes("RED") ? `` : 'Padrão: '} </span> 
            <span style={{fontWeight: '500', marginBottom: '-2px'}}>
            
              {texto.includes("GREEN") || texto.includes("RED") ? `[${resultado}]` : estrategiaDoPadrao}
            </span>
          </TextoDetalhes>

         
            <TextoDetalhes style={{display: `${texto.includes("GREEN") || texto.includes("RED") ? 'none' : ''}`}}>
              <span
              style={{marginLeft: '4px'}}>
                {`[${sequencia}]`} 
              </span>

            </TextoDetalhes>
            
          
          
        </ContainerInfos>
        <div style={{display: 'flex',width: '100%', alignItems: 'center', justifyContent: 'space-between', gap: '8px'}}>
        <ContainerDica style={{width: '100%'}} 
        corPrimaria={cores.primary_color} onClick={()=>{
          if(texto.includes("GREEN") || texto.includes("RED") || texto === 'Cancelada'){
            setExibirDetalhes(false)
          }
        }}>
          {texto.includes("GREEN") || texto.includes("RED") || texto === 'Cancelada' ?

            <h1>
              {t("Volte para a Tela de Sinais")}
            </h1>

            :  texto.includes("ANALISANDO") ?
            <>
            <h1 style={{fontWeight: 'normal'}}>{estrategia}</h1>
            <h1>{cobrir}</h1>
            </>
            :
            <>
              <h1 style={{fontWeight: 'normal'}}>{estrategia}</h1>
              <h1>{cobrir}</h1>
            </>
          }
        </ContainerDica>

        {!texto.includes("GREEN") || !texto.includes("RED") || !texto.includes("Cancelada") &&
          
          <ContainerDica corPrimaria={cores.primary_color} style={{ width: 'fit-content', minWidth: '78px' }}>
          <h1 style={{fontWeight: 'normal'}}>Gale</h1>
          <h1>2X</h1>

        </ContainerDica> }
        </div>
        
        </div>

        <div style={{width: '100%', alignSelf: 'center', height: '500px', margin: '10px 0'}}>
          <Live tela="details"/>
        </div>
        
        <IframeContainer style={{
          display: `${iframeBool != 1 && 'none'}`,
        }}>
          <iframe src={linkMobile} title="Jogo"/>
        </IframeContainer>

        <ContainerLinkJogo corPrimaria={cores.primary_color}>
          <p style={{color: 'var(--Secondary-Text-color)', textAlign: 'left'}}>{t("O jogo não abriu? Clique no botão acessar.")}</p>
          <a href={actualDetails.dadosCompletos.texto.match(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/)[2]} target="_blank"
             rel="noreferrer">
            <BotaoGame data-jogada-id={actualDetails.dadosCompletos.jogadaId} id='game-button' corPrimaria={cores.primary_color} style={{
               background: `${clientId == "16" || clientId == "32" ? 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)' : 'var(--Primary-color)'}`,
               borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
               boxShadow: `${clientId == "16" || clientId == "32" ? '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)' : '0px 0px 5px 2px rgba(0,0,0,0.1), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
            }}>
              <OpenInNewIcon/> {t("ACESSAR JOGO")}
            </BotaoGame>
          </a>
        </ContainerLinkJogo>



      </Container>
    </ContainerPage>
      :
      <>{/*DESKTOP*/}
      <Header/>
      <ContainerPage style={{paddingTop: '90px', alignItems: 'left'}}>
      <TopBar style={{paddingBottom: 0}}>
            
        <HeaderChatDetails gradient={sessionStorage.getItem('@GradientGrupo')}
                           nomeDoGrupo={sessionStorage.getItem('@NomeDoGrupo')}/>

      </TopBar>

      <Container style={{marginTop: '-20px', width: '90vw', margin: 'auto'}}>
      <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'flex-end', justifyContent: 'space-between'}}> 
        <div style={{display: 'flex', flexDirection: 'column'}}>
        <ContainerInfos style={{alignItems: 'left', width: '100%'}}>
          <div style={{alignItems: 'left', width: '100%', display: 'flex', justifyContent: 'left'}}>

            <TextoInfoJogo style={{width: '360px'}}>
            <div style={{
                display: `${(texto === 'Cancelada' || texto.includes("GREEN") || texto.includes("RED")) && 'none'}`,
                marginRight: '-10px',
                marginTop: '2px'
              
              }}>
                <Loading text={""} tipoMesa={1}/>
              </div>

              <AdjustIcon style={{fill: 'var(--Primary-color)', fontWeight: 'bold', fontSize: '28px', 
              opacity: `${texto.includes("GREEN") || texto.includes("RED") || texto.includes("Cancelada") ? '1' : '0'}`
              }}/>
              
              {texto === 'Cancelada' ?
                <StyledText>{t("Jogada")} <span>{t("Cancelada")}</span></StyledText> :
                texto.includes("GREEN") ?
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <StyledText
                  style={{color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{t("Resultado")}
                  : &nbsp;

                  <span style={{color: 'white', background: '#34C700', borderRadius: '20px', boxShadow: '0px 0px 5px 2px rgba(52, 199, 0, 0.37), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)',
                  width: '56px',
                  height: '20px',
                  fontSize: '11px',
                  fontWeight: '500'
                }}> 
                {t("GREEN")} </span>

                </StyledText></div> :
                  texto.includes("RED") ?
                  <div
                  style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                  <StyledText
                    style={{color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{t("Resultado")}
                    : &nbsp;
                    <span style={{color: 'white', background: '#DA0000', borderRadius: '20px', boxShadow: '0px 0px 5px 2px rgba(218, 0, 0, 0.49), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)',
                  width: '56px',
                  height: '20px',
                  fontSize: '11px',
                  fontWeight: '500'
                }}>  
                  {t("RED")}
                </span> </StyledText></div>  :
                    texto.includes('ANALISANDO') ?
                      <StyledText>{t("Analisando")}</StyledText> :
                      <StyledText style={{color: 'white'}}>{t("Entrada")} <span
                        style={{color: 'var(--Primary-color)', fontSize: '16px'}}>{t("Confirmada")}</span>
                      </StyledText>}


              

            </TextoInfoJogo>
          </div>

          <InfoTopbar style={{paddingTop: '4px'}}>
            <h1 style={{textAlign: 'left'}}>{nomeAmigavel}</h1>
          </InfoTopbar>
          <TextoDetalhes style={{textAlign: 'left'}}>

            <span style={{textAlign: 'left', width: '100%'}}>
              {texto.includes("GREEN") || texto.includes("RED") ? `` : 'Padrão: '} 
            </span>
            
            <span style={{width: '100%', alignItems: 'center', textAlign: 'left', fontWeight: 'normal'}}> 
              {texto.includes("GREEN") || texto.includes("RED") ? `[${resultado}]` : estrategiaDoPadrao} 
            </span>
          </TextoDetalhes>

          <TextoDetalhes style={{textAlign: 'left'}}>
            <span style={{textAlign: 'left', fontWeight: '500'}}>
              {!(texto.includes("GREEN") || texto.includes("RED")) && `[${sequencia}]`}
            </span>
          </TextoDetalhes>
        
        </ContainerInfos>
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'left'}}> 


        
        <ContainerDica style={{alignItems: 'left', margin: '10px 0', width: '316px', height: '64px', textAlign: 'left'}} corPrimaria={cores.primary_color} onClick={()=>{
          if(texto.includes("GREEN") || texto.includes("RED") || texto === 'Cancelada'){
            setExibirDetalhes(false)
          }
        }}>
          {texto.includes("GREEN") || texto.includes("RED") || texto === 'Cancelada' ?

            <h1>
              {t("Volte para a Tela de Sinais")}
            </h1>

            : texto.includes("ANALISANDO") ?
            <h1>{t("Aguardando análise")}</h1>
            :
            <>
              {t(`${estrategia}`)}
              <h1>{t(`${cobrir}`)}</h1>
            </>
          }
        </ContainerDica>
        </div>
        <ContainerLinkJogo style={{width: '400px', justifyContent: 'center', gap: '0px'}} corPrimaria={cores.primary_color}>
          <p style={{color: 'var(--Secondary-Text-color)', width: '80%'}}>{t("O jogo não abriu? Clique no botão acessar.")}</p>
          <a href={actualDetails.dadosCompletos.texto.match(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/)[2]} target="_blank"
             rel="noreferrer">
            <BotaoGame data-jogada-id={actualDetails.dadosCompletos.jogadaId} id='game-button' corPrimaria={cores.primary_color} style={{
               background: `${clientId == "16" || clientId == "32" ? 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)' : 'var(--Primary-color)'}`,
               borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
               boxShadow: `${clientId == "16" || clientId == "32" ? '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)' : '0px 0px 5px 2px rgba(0,0,0,0.1), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
            }}>
              <OpenInNewIcon/> {t("ACESSAR JOGO")}
            </BotaoGame>
          </a>
        </ContainerLinkJogo>

            </div>
            <div style={{width: '25%', height: '250px'}}>
              <Live tela="details"/>
            </div>

            

          </div>
        <IframeContainer style={{
          display: `${iframeBool != 1 && 'none'}`
        }}>
          <iframe src={linkMobile} title="Jogo"/>
        </IframeContainer>


      </Container>
    </ContainerPage>
      
      </>
      
    }

      </>
  );
};

export default MineGameComponent;
