import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next';
import './App.css'
import Router from './routing'
import {AuthProvider} from './context/auth.context'
import {FetchProvider} from './context/fetch.context'
import {ThemeProvider} from '@mui/material/styles'
import {theme} from './Theme'
import {TabsProvider} from "./context/tabs.context";
import {CustomizacaoProvider} from './Customizacao'
import {GameProvider} from "./context/game.context";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


export default function App(): JSX.Element {
  return (

    <Suspense fallback="loading">
      <FetchProvider>
        <AuthProvider>
          <TabsProvider>

            <ThemeProvider theme={theme}>
              <GameProvider>
                <CustomizacaoProvider>
                  <>
                    <Router/>
                  </>
                </CustomizacaoProvider>
              </GameProvider>


            </ThemeProvider>
          </TabsProvider>

        </AuthProvider>

      </FetchProvider>
    </Suspense>


  )
}

serviceWorkerRegistration.register();
