import axios from 'axios'

export const RetornarCores = async (clientId: any) => {
  if(window.location.href == 'http://localhost:3000' || window.location.href == 'https://appbetbooster.vercel.app/' || window.location.href == 'https://appbetbooster.online' ){
    return await axios.get(`https://a4.lineragames.com.br/GetWhiteLabel?ClientId=16`)
  }
  return await axios.get(`https://a4.lineragames.com.br/GetWhiteLabel?ClientId=${clientId}`)
}

export const RetornarCores2 = (clientId: any) => {
  let url = `https://a4.lineragames.com.br/GetWhiteLabel?ClientId=${clientId}`

  try {
    const response = axios.get(url)
    return response;

  } catch (error) {
    console.log(error)
  }
}

export const VerifCliente = async (subdomain: string) => {
  let url = `https://a4.lineragames.com.br/api/Otg/VerifySubdomain?subdomain=${subdomain}`;

  try {
    const response = await axios.get(url)
    return response;

  } catch (error) {
    console.log(error)
  }
}


export const VerifCliente2 = (subdomain: any) => {
  let url;
  if(window.location.href === 'http://localhost:3000' || subdomain === undefined || window.location.href === 'https://appbetbooster.vercel.app/' || window.location.href === 'https://appbetbooster.online'){
    url = `https://a4.lineragames.com.br/api/Otg/VerifySubdomain?subdomain=betbooster`;
  } else {
    url = `https://a4.lineragames.com.br/api/Otg/VerifySubdomain?subdomain=${subdomain}`;
  }
  

  try {
    const response = axios.get(url)
    return response;

  } catch (error) {
    console.log(error)
  }
}
