import React, {useEffect, useState} from 'react'
import {BackgroundDiv} from './styles'
import GrupoChat from '../../components/group-chat'
import {useParams} from "react-router-dom";
import {useCustomizacao} from '../../Customizacao'

const Grupo: React.FC = () => {
  const [isDesktop, setIsDesktop] = useState(true);
  const {nomeDoGrupo} = useParams();
  const [clientId, setClientId] = useState<any>()
  const {fundoPuro, setControleLoading, cores} = useCustomizacao();

  const verifAuth = () => {
    const verifToken = sessionStorage.getItem('token')
    if (verifToken == null) {
      window.location.href = './'
    }
  }


  useEffect(() => {
    setInterval(() => {
      // setControleLoading(false)
    }, 1500)
  }, [])


  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 769);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    verifAuth()

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <>
      <BackgroundDiv corPrimaria={fundoPuro != "1" && cores.primary_color} style={{
        background: `${cores.backgroundFixoBool === '1' ? 'var(--BackgroundFixo)' : cores.backgroundFixoBool === '1' ? 'var(--BackgroundFixo)' : cores.fundoPuro == "1" ? 'var(--ImagemFundo-Main)' : `linear-gradient(0deg, ${cores.primary_color}50, ${cores.primary_color}50), var(--ImagemFundo-Main)`}`,
        backgroundSize: `${clientId == '16' || cores.backgroundFixoBool === '1' && 'cover'}`,
        backgroundRepeat: `${clientId == '16' && 'no-repeat'}`,

      }}>
        {/* <Outlet /> */}
        <GrupoChat/>

      </BackgroundDiv>

    </>
  )
}

export default Grupo;
