import React, {useEffect, useState} from 'react';

import {BackgroundDiv} from './styles';
import {useAuth} from "../../context/auth.context";

import './styles.css';
import MenuLateral from '../../components/sidebar/menu';
import CardUsuario from '../../components/CardUsuario';
import {useCustomizacao} from '../../Customizacao';


const Userpage: React.FC = () => {
  const [user, setUser] = useState([]);
  const [isMobile, setIsMobile] = useState(true);
  const {name, email} = useAuth();
  const {cores, imagemDeFundo} = useCustomizacao();
  const {fundoPuro} = useCustomizacao();
  const [clientId, setClientId] = useState<any>()
  useEffect(() => {
    setClientId(sessionStorage.getItem('@ClientId'))
  }, [])

  const verifAuth = () => {
    const verifToken = sessionStorage.getItem('token')
    if (verifToken == null) {
      window.location.href = './'
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 769);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    verifAuth()

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <>
      <BackgroundDiv corPrimaria={fundoPuro != "1" && cores.primary_color} style={{
        background: `${cores.backgroundFixoBool === '1' ? 'var(--BackgroundFixo)' : fundoPuro == "1" ? 'var(--ImagemFundo-Main)' : `linear-gradient(0deg, ${cores.primary_color}50, ${cores.primary_color}50), var(--ImagemFundo-Main)`}`,
        backgroundSize: `${clientId == '16' || cores.backgroundFixoBool === '1' && 'cover'}`,
        backgroundRepeat: `${clientId == '16' && 'no-repeat'}`,
        backgroundPosition: `${clientId == '16' && 'center'}`

      }}>
        {!isMobile && <MenuLateral/>}
        <CardUsuario/>
      </BackgroundDiv>

    </>
  );
};

export default Userpage;
