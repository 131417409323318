import {createTheme} from '@mui/material/styles'

export const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    body1: {
      color: 'var(--Secondary-Text-color)',
    },
  },
  palette: {
    background: {
      paper: '#17295540',
    },
    white: {
      main: '#F6F7FD',
    },
    text: {
      primary: '#000',
    },
    primary: {
      main: '#0D2867',
      contrastText: '#FFFFFF',
      light: '#CCD2E5',
    },
    secondary: {
      main: '#E2C03C',
      light: '#EFE6C4',
      contrastText: '#000000',
    },
    action: {
      active: 'black',
      selected: '#000',
      disabled: '#6B6B6B',
      disabledBackground: 'black',
    },

    tonalOffset: 0.2,
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: 'var(--ItemColor)',
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiDrawer-paper': {
            backgroundColor: 'var(--Drawer-Background)',
            color: 'white',
            width: '100%',
          },
          '& .MuiDrawer-paper:hover': {
            color: 'black',
          },

          '&:active': {
            color: 'black',
            background: 'black'
          }
        },
        paper: {
          '& .MuiBytton-root': {
            color: 'white'
          },
          '& .MuiButton-root:hover': {
            cursor: 'pointer',
            backgroundColor: 'black',
          },
        },
      },
    },


  }
})

declare module '@mui/material/styles' {
  interface Palette {
    white: Palette['primary']
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    white?: PaletteOptions['primary']
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    white: true
  }
}


