import styled from "styled-components";

type Props = {
  backgroundImage?: string;
}

export const Card = styled.div`
    width: 331px;
    height: 250px;

`;

export const CardMobile = styled.div`
    width: 100%;
`;

export const ImageCardMobile = styled.div<Props>`
  aspect-ratio: 12/9;
  background-image: url(${props => props.backgroundImage});
  background-position: center;
  background-size: cover;
  border-radius: 8px;
`;

export const cardMobileStyle = styled.div`
  
`;


export const Titulo = styled.h1`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 550;
    font-size: 20px;
    line-height: 29px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: 32px;
    margin-left: 32px;
`;


export const TituloMobile = styled.h1`
    font-family: 'Montserrat';
    font-style: bold;
    font-weight: 550;
    font-size: 16px;
    line-height: 29px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: 20px;
    margin-left: 16px;
`;

export const Imagem = styled.img`
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-left: calc(100% - 110px);
    border-radius: 50%;
    margin-top: -20px;
`;

export const ImagemMobile = styled.img`
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-left: 90px;
    margin-top: 20px;
    border-radius: 50%;
`;
