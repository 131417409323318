import { StyledComponent } from '@emotion/styled';
import styled from 'styled-components';


export const DivBannerTop = styled.div`
  /* background: #000; */
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

`;

export const BannerTop = styled.img`
  width: 100%;
  height: 160px;
  object-fit: cover;
  /* background: #000; */
  opacity: 0.4;
  position: relative;
  
`;

export const LogoBanner = styled.img`
  top: 20px;

`;


export const PaiLogin = styled.div`

`

export const TituloPagina = styled.h1`
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    align-self: left;
    

    /* identical to box height */


    /* Black */

    color: var(--Secondary-Text-color);
    text-align: left;
    align-self: left;
    justify-content: left;
    align-content: left;
    @media screen and (max-width: 1022px){
    color: var(--Secondary-Text-color);
  }
    @media screen and (min-width: 1023px){
      color: var(--Text-color);
    }
    
`;

export const Cardlogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
    
`

export const ContentBackground = styled.div`
    background: var(--Background-color);
    width: 100%;
    height: 100vh;
    overflow: scroll;

    @media screen and (max-width: 770px){
    background-image: var(--Login-image-mobile);
    background-color: var(--Secondary-Background-color);
    background-size: cover;
    background-position: center;
    }

    @media screen and (min-width: 770px){
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
`
export const PaiInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items:center;
  margin-top: -15px;
`

export const StyledLabel = styled.label`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  /* Black */

  color: var(--Text-color);
  @media screen and (max-width: 770px){
    color: var(--Secondary-Text-color);
  }
`;

export const StyledInput = styled.input`
  background: #FFF;
  color: #000;
  height: 50px !important;
  box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding-bottom: 0px !important;
  box-sizing: border-box;
  padding-left: 25px;
  font-family: 'Montserrat';
  font-size: 16px;

  &:focus{
    outline: none;
  }

  &::placeholder{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #999999;
  }

   -webkit-appearance: none; /* Remova o estilo padrão do iOS */
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.6);

`;

export const MsgErrorLabel = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  /* status-error */

  color: #DA0000;
`;

export const StyledButtonEntrar = styled.button`
  box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 100%;
  height: 50px;
  color: white;
  border: none;
  font-family: 'montserrat', sans-serif;
  font-weight: bold;
  &:hover{
    cursor: pointer;
  }

`;

export const StyledButtonRegistrar = styled.button`
  width: 100%;
  height: 50px;
  color: white;
  border: none;
  box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-family: 'montserrat', sans-serif;
  font-weight: bold;
  &:hover{
    cursor: pointer;
  }
  @media screen and (max-width: 770px){
    background: #636363;
  }

`;

export const StyledSpan = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  /* Red */

  color: var(--LinksColor-primary);

  text-decoration: none;
  
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`