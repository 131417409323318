import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import CardNovaSenhaLogado from '../../components/CardNovaSenhaLogado';
import {ImageLoginPage} from "../Login/styles";
import {ContainerLogin} from './styles';
import {useCustomizacao} from '../../Customizacao';

const ResetPasswordPerfil: React.FC = () => {

  const {setExibirMenu} = useCustomizacao()

  useEffect(() => {
    setExibirMenu(false);
    return () => {
      setExibirMenu(true);
    }
  }, [])

  const navigate = useNavigate();
  const {nomeCliente} = useParams();

  // let subdomain = nomeCliente;

  // sessionStorage.setItem('@Subdomain', subdomain)

  // useEffect(()=>{
  //   e2(subdomain)
  //   .then((response)=>{
  //     const clientId = response?.data.clientId
  //     console.log(clientId, 'clientId')
  //     sessionStorage.setItem('@ClientId', clientId)
  //   }).catch((err)=>{
  //     console.log(err, 'err')
  //   })

  // },[])

  useEffect(() => {
    // Verificar se o token está presente na sessionStorage
    const token = sessionStorage.getItem('token');

    // Se o token não existir, redirecionar para a página inicial
    if (!token) {
      navigate(`/${nomeCliente}`);
    }
  }, [navigate]);

  return (
    <>
      <ContainerLogin>
        <ImageLoginPage/>
        <CardNovaSenhaLogado/>
      </ContainerLogin>
    </>
  )
}

export default ResetPasswordPerfil;
