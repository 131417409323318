import React, {useEffect} from 'react'

import {useCustomizacao} from '../../Customizacao'
import Login from '../../components/CardLogin'
import {ContainerLogin, ImageLoginPage} from './styles'
import './styles.css'


const LoginPage: React.FC = () => {
  const {setExibirMenu} = useCustomizacao()

  useEffect(() => {
    setExibirMenu(false);
    return () => {
      setExibirMenu(true);
    }
  }, [])

  return (
    <>
      <ContainerLogin>
        <ImageLoginPage>
          {/* <ImagemLogo
              src={Logo}
              style={{
                width: '300px',
                marginLeft: '18vw',
                marginTop: '25vh',
              }}
            /> */}
        </ImageLoginPage>
        <Login/>
        {/* <IframeButton/> */}
      </ContainerLogin>
    </>
  );
};

export default LoginPage;
