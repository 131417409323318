import React, {useEffect, useState} from 'react';

import {Button, Modal} from '@mui/material';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import {VerifCliente} from '../../services/whitelabel';
import {
  Cardlogin,
  ContentBackground,
  PaiInput,
  PaiLogin,
  StyledButtonEntrar,
  StyledInput,
  StyledLabel,
  TituloPagina
} from './styles';
import { useTranslation } from 'react-i18next';


interface CardRegisterProps {
  style?: React.CSSProperties; // permite a adição de propriedades de estilo
}

const Cardinput = () => {
  const {nomeCliente} = useParams();
  const [clientId, setClientId] = useState<any>()
  const {t, i18n} = useTranslation
  ('cardNovaSenha')
  
  useEffect(() => {
    setClientId(sessionStorage.getItem('@ClientId'))
  }, [])
  

  useEffect(() => {
   
      VerifCliente(nomeCliente || '')
      .then((response) => {
        const clientId = response?.data.clientId

        sessionStorage.setItem('@ClientId', clientId)
        localStorage.setItem('@ClientId', clientId)
      }).catch((err) => {
      console.log(err, 'err')
    })
    
    

  }, [])

  const {resetToken} = useParams();

  const [senha, setSenha] = useState<string | any>('');
  const [confirmarSenha, setConfirmarSenha] = useState<string | any>('')

  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState('');
  const emailRegex = new RegExp('@');
  const [showErrorModal, setShowErrorModal] = useState(false);

  function handleUsernameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSenha(e.target.value);
  }

  const handleRecuperar = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (senha == "" || senha.length < 6) {
      setErrorMsg('Sua senha deve ter no minimo 6 caracteres.')
      console.log(errorMsg)
      setShowErrorModal(true);
      return;
    }

    if (senha === confirmarSenha) {
      const usuario = {token: resetToken, newPassword: senha};
      let clientId = localStorage.getItem('@ClientId')
      await PostNovaSenha(clientId, usuario);
    } else {
      console.log("As senhas nao sao iguais.")
      setErrorMsg("As senhas devem ser iguais.")
      setShowErrorModal(true)
    }

  }

  interface ErrorModalProps {
    errorMessage: string;
    onClose: () => void;
  }

  const ErrorModal: React.FC<ErrorModalProps> = ({errorMessage, onClose}) => {
    return (
      <Modal open={true} onClose={onClose} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <div style={{backgroundColor: '#fff', maxWidth: 400, maxHeight: 300, padding: 20, borderRadius: 5}}>
          <h2 style={{color: 'red'}}>{t("Erro!")}</h2>
          <p>{errorMessage}</p>
          <Button onClick={onClose} variant="contained" color="primary" style={{
            background: '#801212',
            color: 'white'
          }}>{t("Fechar")}</Button>
        </div>
      </Modal>
    );
  };

  // const goToLogin = () => {
  //   navigate('/')
  // }


  const PostNovaSenha = async (clientId: any, usuario: { token: any, newPassword: string }) => {
    let url = `https://a4.lineragames.com.br/api/Otg/ResetPassword?ClientId=${clientId}`

    try {
      const response = await axios.post(url, usuario, {
        headers: {
          'Content-Type': 'application/json'
        }
// .
      })
      //window.location.href = '/'

      console.log(response)
      navigate('../');

      return response;
    } catch (error: any) {


      setErrorMsg(error)
      setShowErrorModal(true);

      console.log(error.response)

      return error
    }
  }


  return (
    <form style={{display: "flex", flexDirection: "column"}} onSubmit={handleRecuperar}>

      <StyledLabel>{t("Nova Senha")}</StyledLabel>
      <StyledInput
        type="password"
        placeholder="Senha"
        value={senha}
        onChange={(e) => setSenha(e.target.value)}
      />

      <StyledLabel>{t("Confirmar Senha")}</StyledLabel>
      <StyledInput
        type="password"
        placeholder="Confirmar Senha"
        value={confirmarSenha}
        onChange={(e) => setConfirmarSenha(e.target.value)}
      />


<StyledButtonEntrar id="submit" type="submit" className="login-button"
        style={{
          background: `${clientId == "16" || clientId == "32" ? 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)' : 'var(--ButtonBackground-primary)'}`,
          borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
          boxShadow: `${clientId == "16" || clientId == "32" && '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
        }}
      >{t("Alterar Senha")}</StyledButtonEntrar>

      {showErrorModal && (
        <ErrorModal errorMessage={t(`${errorMsg}`)} onClose={() => setShowErrorModal(false)}/>
      )}
    </form>

  );
}


const CardNovaSenha: React.FC = () => {
  const {t, i18n} = useTranslation('cardNovaSenha')
  return (
    <ContentBackground>
      <PaiLogin>

        <Cardlogin>

          <PaiInput>
            <div>

              <TituloPagina>
                {t("Recuperar Senha")}
              </TituloPagina>
              <Cardinput/>

            </div>

          </PaiInput>

        </Cardlogin>
      </PaiLogin>
    </ContentBackground>
  )
}

export default CardNovaSenha


