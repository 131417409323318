import styled from 'styled-components'
import ListItemButton from '@mui/material/ListItemButton';
import {Box} from '@mui/material';

export const ContainerBox = styled(Box)`
  display: flex;

  @media screen and (min-width: 769px){
    display: none;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
 
`
export const ImagemLogo = styled.img`
  width: 130px;
  height: 130px;
  // margin-left: 0px;
  border-radius: 50%;

 @media screen and (min-width: 769px){
     //display: none;
 }
  
`;


export const StyledListItem = styled.li`
  width: 100%;
  padding: 0 0;
`;

export const StyledListItemButton = styled(ListItemButton)`
  width: '100%';
  padding: 10px;

  &:focus{
    background: black;
  }

`;
