import {Card} from "@mui/material";
import React, {useEffect, useState} from "react";
import {CardMobile, ImageCardMobile, Imagem, Titulo} from "./styles";

interface CardGrupoProps {
  nome: string | any,
  imagem: any,
  gradient: string,
  grupo: any,
  grupoAtivo: any,
  chatId: any,
  afiliadoId: any,
  tipoMesa: any,

}

const CardGrupo: React.FC<CardGrupoProps> = ({nome, imagem, gradient, grupoAtivo, chatId, afiliadoId, tipoMesa}) => {
  const [isMobile, setIsMobile] = useState<boolean | any>(false);
  const [clientId, setClientId] = useState<any>()
  useEffect(()=>{
    setClientId(sessionStorage.getItem('@ClientId'))
  }, [])

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 769);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  }, [])


  const gradientStyle = {
    backgroundImage: `url(${imagem})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '12px',
    aspectRatio: '14/9',
    backgroundPositionY: '-1.8px',
    backgroundColor: 'var(--Primary-color)',
    

  };

  const gradientStyleMobile = {
    backgroundImage: `url(${imagem})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '12px',
    
    

  }

  let nomeFormatado = nome.slice(3, 10);

  return (
    <>


      {!isMobile ?

        <Card style={gradientStyle}>
          
        </Card>

        :

        <CardMobile>
          <ImageCardMobile backgroundImage={imagem} style={{
           
          }}/>
        </CardMobile>
      }


    </>

  )
}


export default CardGrupo;
