import styled from "styled-components"

export const ContainerLogin = styled.div`
  height: 100vh;
  

  @media only screen and (min-width: 1023px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-height: 100vh;
  }
`

export const ContentBackground = styled.div`
  width: 100%;
  background-color: var(--ContentBackground-secundary);


`
export const PaiLogin = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  
`
export const Cardlogin = styled.div`
  height: 200px;
  background: var(--Background-color);
  opacity: 0.5;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.35);
  border-radius: 12px;

`
