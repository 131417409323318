import axios from 'axios'


axios.defaults.headers.get['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';

//ip: 161.35.160.213
export const GetMensagensAoVivo = async (chatId: any) => {
  try {
    const response = await axios.get(`https://a4.lineragames.com.br/api/Otg/GetMensagensAoVivo?ChatId=${chatId}`)

    return response.data;

  } catch (error) {
    console.log(error)
  }
}

export const GetMensagensOffline = async (chatId: any) => {

  try {
    const response = await axios.get(`https://a4.lineragames.com.br/api/Otg/GetMensagensOffline?ChatId=${chatId}`)

    return response.data;

  } catch (error) {
    console.log(error)
  }

}

export const GetInfosGreenDinamico = async (chatId: any) => {
  try {
    const response = await axios.get(`https://a4.lineragames.com.br/api/Otg/GetInfosGreenDinamico?ChatId=${chatId}`)

    return response.data

  } catch (error) {
    console.log(error)
  }
}


export const GetGruposLabel = async (clientId: any) => {
  try {
    const response = await axios.get(`https://a4.lineragames.com.br/GetGruposLabel?ClientId=${clientId}`)

    return response.data;

  } catch (error) {
    console.log(error)
  }
}

export const GetUltimaEntrada = async () => {
  try {
    const response = await axios.get(`https://a4.lineragames.com.br/api/Otg/GetUltimaEntrada`)

    return response.data;
  } catch (error) {
    console.log(error)
  }
}

export const GetUltimasEntradasRoleta = async () => {
  try {
    const response = await axios.get(`https://a4.lineragames.com.br/api/Otg/GetUltimasEntradasRoleta`)

    return response.data;
  } catch (error) {
    console.log(error)
  }
}

export const GetInfosGreen = async () => {
  let url = `https://a4.lineragames.com.br/api/Otg/GetInfosGreen`;

  try {
    const response = await axios.get(url)

    return response.data;
  } catch (error) {
    console.log(error)
  }

  //green1 = roleta
  //green2 = cards
  //green3 = dados
}

export const GetMensagensRoletas = async () => {
  let url = `https://a4.lineragames.com.br/api/Otg/GetMensagensRoletas`;

  try {
    const response = await axios.get(url)
    return response.data;
  } catch (error) {
    console.log(error)
  }
}


export const GetMensagensRoletasDados = async () => {
  let url = `https://a4.lineragames.com.br/api/Otg/GetMensagensDados`;

  try {
    const response = await axios.get(url)
    return response.data;
  } catch (error) {
    console.log(error)
  }
}


export const GetMensagensCards = async () => {
  let url = `https://a4.lineragames.com.br/api/Otg/GetMensagensCards`;

  try {
    const response = await axios.get(url)
    return response.data;
  } catch (error) {
    console.log(error)
  }
}

export const GetMensagensMine = async () => {
  let url = `https://a4.lineragames.com.br/api/Otg/GetMensagensMines`;

  try {
    const response = await axios.get(url)
    return response.data;
  } catch (error) {
    console.log(error)
  }
}


export const GetCardStatus = async () => {
  let url = `https://a4.lineragames.com.br/api/Otg/GetCardStatus`;

  try {
    const response = await axios.get(url)
    return response.data;
  } catch (error) {
    console.log(error)
  }
}

export const GetMensagemMines = async () => {
  let url = `https://a4.lineragames.com.br/api/Otg/GetMensagensMines`

  try {
    const response = await axios.get(url)
    return response.data
  } catch (error) {
    console.log(error)
  }
}


//AUTH
//REGISTER esta no cardRegister


export const PostLogin = async (clientId: any, usuario: { email: any, nome: any, senha: any }) => {
  let url = `https://a4.lineragames.com.br/api/Otg/Login?ClientId=${clientId}`


  const response = await axios.post(url, usuario, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const token = response.data.token;
  sessionStorage.setItem('token', token)
  // console.log(token)
  
    window.location.href = `/${sessionStorage.getItem('@Subdomain')}/ListaGrupos`
 
  
  return response.data;

}


export const GetInfoCliente = async (token: string) => {
  let url = `https://a4.lineragames.com.br/api/Otg/GetInfoCliente`;

  try {
    const response = await axios.get(url)
    return response.data;
  } catch (error) {
    console.log(error)
  }
}


export const UpdatePopup = async (clientId: any, email: any) => {
  let url = `https://a4.lineragames.com.br/api/Otg/UpdatePopup?ClientId=${clientId}&Email=${email}`;

  try {
    const response = await axios.post(url,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};


export const GetPopupStatus = async (clientId: any, email: string) => {
  let url = `https://a4.lineragames.com.br/GetPopupStatus?ClientId=${clientId}&Email=${email}`;

  try {
    const response = await axios.get(url)
    return response;
  } catch (error) {
    console.log(error)
  }
}



