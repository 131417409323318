import React, {useEffect, useRef, useState} from 'react'
import {BackgroundDiv} from './styles'
import MenuLateral from '../../components/sidebar/menu'
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {useCustomizacao} from '../../Customizacao';
import Header from '../../components/Header';

const ListaGrupos: React.FC = () => {
  const [isMobile, setIsMobile] = useState(true);
  const {nomeCliente} = useParams();
  const navigate = useNavigate();
  const {cores, imagemDeFundo} = useCustomizacao();
  const [clientId, setClientId] = useState<any>()
  const {fundoPuro} = useCustomizacao();
  useEffect(() => {
    setClientId(sessionStorage.getItem('@ClientId'))
  }, [])

  const verifAuth = () => {
    const verifToken = sessionStorage.getItem('token')
    if (verifToken == null) {
      navigate(`/${nomeCliente}`)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 769);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    verifAuth()

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleTouch = () => {
      if(componentRef.current){
        componentRef.current.focus();
      }
      
    };

    document.addEventListener("touchstart", handleTouch);

    return () => {
      document.removeEventListener("touchstart", handleTouch);
    };
  }, []);


  //console.log(imagemDeFundo, 'imagem de fundo')
//ncbwoiwnioeff
  return (
    <>
      <BackgroundDiv corPrimaria={fundoPuro != "1" && cores.primary_color} style={{
        background: `${cores.backgroundFixoBool === '1' ? 'var(--BackgroundFixo)' : fundoPuro == "1" ? 'var(--ImagemFundo-Main)' : `linear-gradient(0deg, ${cores.primary_color}50, ${cores.primary_color}50), var(--ImagemFundo-Main)`}`,
        backgroundSize: `${clientId == '16' || cores.backgroundFixoBool === '1' && 'cover'}`,
        backgroundRepeat: `${clientId == '16' && 'no-repeat'}`,
        overflowY: `hidden`,

      }}>
      
          
        <Outlet/>
        

      </BackgroundDiv>
    </>
  )
}

export default ListaGrupos;
