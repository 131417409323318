import LogoutIcon from '@mui/icons-material/Logout';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonIcon from '@mui/icons-material/Person';
import TextsmsIcon from '@mui/icons-material/Textsms';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useNavigate} from 'react-router-dom';
import {useTabsContext} from "../../../context/tabs.context";
import {ContainerBox, ImagemLogo, StyledListItem, StyledListItemButton} from './styles';
import {useCustomizacao} from '../../../Customizacao';


const drawerWidth = 240;

export default function MenuLateral() {
  const navigate = useNavigate();
  const {activeTab, setActiveTab} = useTabsContext();
  const {hoverButtonDrawer, logoUrl, logoDrawer} = useCustomizacao();

  const signOut = () => {
    sessionStorage.getItem('token')
    sessionStorage.removeItem('token')
    navigate(`/${sessionStorage.getItem('@Subdomain')}`)
  }

  return (
    <ContainerBox>

      <CssBaseline/>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '200px',
          padding: '50px'
        }}>
          <ImagemLogo src={logoDrawer}/>
        </Box>


        <List>
          <StyledListItem key={'Salas de Sinais'}>
            <StyledListItemButton
              selected={activeTab === 0} onClick={() => {
              setActiveTab(0);
              navigate('../ListaGrupos')
            }
            }
              style={{
                background: activeTab === 0 ? `${hoverButtonDrawer}` : '',
              }}>
              <ListItemIcon>
                <TextsmsIcon/>
              </ListItemIcon>
              <ListItemText primary={'Salas de Sinais'}/>
            </StyledListItemButton>
          </StyledListItem>
          <StyledListItem key={'ebook'}>
            <StyledListItemButton selected={activeTab === 1} onClick={() => {
              setActiveTab(1);
              navigate('../ebook')
            }}
                                  style={{
                                    background: activeTab === 1 ? `${hoverButtonDrawer}` : '',
                                  }}>
              <ListItemIcon>
                <MenuBookIcon/>
              </ListItemIcon>
              <ListItemText primary={'Ebook'}/>
            </StyledListItemButton>
          </StyledListItem>
          <StyledListItem key={'Perfil'}>
            <StyledListItemButton selected={activeTab === 2} onClick={() => {
              setActiveTab(2);
              navigate('../Perfil')
            }}
                                  style={{
                                    background: activeTab === 2 ? `${hoverButtonDrawer}` : '',
                                  }}>
              <ListItemIcon>
                <PersonIcon/>
              </ListItemIcon>
              <ListItemText primary={'Perfil'}/>
            </StyledListItemButton>
          </StyledListItem>
          <StyledListItem key={'Logout'}>
            <StyledListItemButton onClick={() => signOut()}>
              <ListItemIcon>
                <LogoutIcon/>
              </ListItemIcon>
              <ListItemText primary={'Logout'}/>
            </StyledListItemButton>
          </StyledListItem>
        </List>

      </Drawer>

    </ContainerBox>
  );
}
