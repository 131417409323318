import React, {createContext, ReactElement, useContext, useEffect, useState} from 'react';
import {RetornarCores} from './services/whitelabel';

const CustomizacaoContext = createContext<any>({});
CustomizacaoContext.displayName = 'CustomizacaoContext';

export type CustomizacaoProps = {
  children: ReactElement;
}

interface CustomizacaoData {
  logoUrl: string;
  backgroundImageUrl: string;
  cores: [];
  coresRoot: string;

}


const CustomizacaoProvider: React.FC<CustomizacaoProps> = ({children}): ReactElement => {
  const [logoUrl, setLogoUrl] = useState('');
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
  const [headerImgUrl, setHeaderImgUrl] = useState('')
  const [cores, setCores] = useState([]);
  const [coresRoot, setCoresRoot] = useState('');
  const [imgBackgroundRegister, setImgBackgroundRegister] = useState('');
  const [logoUrlLogin, setLogoUrlLogin] = useState('')
  const [logoIconMobile, setLogoIconMobile] = useState('')
  const [favicon, setFavicon] = useState('')
  const [tituloPagina, setTituloPagina] = useState('')

  const [logoDrawer, setLogoDrawer] = useState('');

  const [headerMobileLogin, setHeaderMobileLogin] = useState('')
  const [hoverButtonDrawer, setHoverButtonDrawer] = useState('')

  const [linkSuporte, setLinkSuporte] = useState('');

  const [imgPopup, setImgPopup] = useState('');

  const [linkAfiliado, setLinkAfiliado] = useState('');

  const [statusModal, setStatusModal] = useState<number>();
  const [exibirMenu, setExibirMenu] = useState(true);
  const [imagemDeFundo, setImagemDeFundo] = useState('')

  const [fundoPuro, setFundoPuro] = useState<any>()

  const [iframeBool, setIframeBool] = useState<number | any>()


  const [controleLoading, setControleLoading] = useState(false)

  const [TopoFixo, setTopoFixo] = useState('')
  const [BackgroundFixo, setBackgroundFixo] = useState('')
  const [BackgroundFixoBool, setBackgroundFixoBool] = useState<any>()
  const [TopoFixoBool, setTopoFixoBool] = useState<any>()


  const [dominio, setDominio] = useState<string | null>('betbooster')

  const [idioma, setIdioma] = useState<any>()

  const [primaryColor, setPrimaryColor] = useState<any>()


  const [liveBool, setLiveBool] = useState<number | null>()
  const [linkLive, setLinkLive] = useState<string | null>()


  let clientId = sessionStorage.getItem('@ClientId')

 

  const setarUrls = (retorno: any) => {
    setLogoUrl(retorno.logoUrl);
    setBackgroundImageUrl(retorno.backgroundImageUrl);

  };

  const setarCores = async (clientId: any = 0, retorno: any = null) => {

    if(retorno === null && clientId !== 0)
      retorno = await RetornarCores(clientId);

    setCores(retorno.data);
    setLogoUrl(retorno.data.logo_url)
    setHeaderImgUrl(retorno.data.header_image)

    setHeaderMobileLogin(retorno.data.headerImageLogin_mobile)
    setImgBackgroundRegister(retorno.data.registerImage_mobile)
    setHoverButtonDrawer(retorno.data.drawerButton_HoverColor)
    setLogoUrlLogin(retorno.data.logoLogin)
    setLogoIconMobile(retorno.data.imagemIcone);
    setFavicon(retorno.data.logoFavicon);
    setTituloPagina(retorno.data.tituloPage);
    setLogoDrawer(retorno.data.logoDrawer)
    setLinkSuporte(retorno.data.linkSuporte)
    setImgPopup(retorno.data.popupImage)
    setLinkAfiliado(retorno.data.linkAfiliado)
    setStatusModal(retorno.data.aparecePopupCadastro)
    setImagemDeFundo(retorno.data.fundoMain)
    setFundoPuro(retorno.data.fundoPuro)
    setIframeBool(retorno.data.iframebool)
    setTopoFixo(retorno.data.topoFixo)
    setTopoFixoBool(retorno.data.topoFixoBool)
    setBackgroundFixo(retorno.data.backgroundFixo)
    setBackgroundFixoBool(retorno.data.backgroundFixoBool)

    setIdioma(retorno.data.idioma)
    setPrimaryColor(retorno.data.primary_color)
    setLiveBool(retorno.data.liveBool)
    setLinkLive(retorno.data.linkLive)

  
 

    var myDynamicManifest = {
      "name": `${retorno.data.tituloPage}`,
      "short_name": `${retorno.data.tituloPage}`,
      "description": `Site de tips do ${retorno.data.tituloPage}`,
      "start_url": "/",
      "scope": ".",
      "display": "standalone",
      "background_color": "#000000",
      "theme_color": "#0f4a73",
      "icons": [{
        "src": `${retorno.data.logoFavicon}`,
        "sizes": "256x256",
        "type": "image/png"
      }]
    }

    
    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], {type: 'application/json'});
    const manifestURL = URL.createObjectURL(blob);

    // @ts-ignore
    document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);

    setCoresRoot(`
      :root {
        --Background-color: ${retorno.data.background_color};
        --Secondary-Background-color: #000000;
        --Text-color: ${retorno.data.text_color};
        --Secondary-Text-color: #FFF;
        --Primary-color: ${retorno.data.primary_color};
        --Secondary-color: ${retorno.data.secondary_color};
        --Background-image: url(${retorno.data.background_image});
        --Header-image: ${retorno.data.header_image};
        --Logo-url: url(${retorno.data.logo_url});
        --Header-image-login: url(${retorno.data.headerImageLogin_mobile});
        

        --Login-image-mobile: url(${retorno.data.registerImage_mobile});
        --Register-image-mobile: url(${retorno.data.registerImage_mobile});


        --Primary-color-hover: ${retorno.data.primaryColor_hover};

        
        --HeaderBackground-primary: ${retorno.data.headerBackground_primary};
        --HeaderColor-primary: ${retorno.data.headerColor_primary};
        --ContentBackground-primary: ${retorno.data.contentBackground_primary};
        --ContentBackground-secundary: ${retorno.data.contentBackground_secundary};

        /*BUTTONS*/

        --ButtonBackground-primary: ${retorno.data.buttonBackground_primary};
        --ButtonBackground-secundary: ${retorno.data.buttonBackground_secundary};
        --ButtonBackground-grey: #636363;
        --ButtonTextColor-primary: ${retorno.data.buttonTextColor_primary};
        --LabelColor-primary: ${retorno.data.labelColor_primary};
        --LinksColor-primary: ${retorno.data.linksColor_primary};
    

        /*DRAWER*/
        --Drawer-Background: ${retorno.data.drawerBackground};
        --Drawer-Button-color: ${retorno.data.drawerButton_color};
        --Drawer-Button-HoverColor: ${retorno.data.drawerButton_HoverColor};
        --LogoDrawer: url(${retorno.data.logoDrawer});


        /* LISTA GRUPO */
        --TituloColor: ${retorno.data.tituloColor};
    
        /*MENU BOTTOM*/
        --ItemColor: ${retorno.data.itemColor};

        /*GRADIENTS*/
        --GradientDados: linear-gradient(to top, rgb(2, 44, 8) 0%, rgb(7, 139, 2) 100%);
        --GradientCards: linear-gradient(to top, rgb(0, 6, 43) 0%, rgb(81, 122, 211) 100%);
        --GradientRoleta: linear-gradient(to top, rgb(65, 0, 15) 0%, rgb(200, 0, 25) 100%);
      
        --Logo-url-login: url(${retorno.data.logo_url});


        /*LOADING*/
        --Loading-background: ${retorno.data.loadingBackground};
        --Loading-front-color: ${retorno.data.loadingFront_color};
        --Loading-back-color: ${retorno.data.loadingBack_color};
        --Loading-text-color: ${retorno.data.loadingText_color};

        /*MODAL DE CADASTRO*/
        --BackgroundImage-modal: ${retorno.data.popupImage};

        /*TITULO MENSAGENS*/
        --Titulo-Mensagens: ${retorno.data.tituloMensagens};

        /*IMAGEM DE FUNDO MAIN*/
        --ImagemFundo-Main: url(${retorno.data.fundoMain});
        --BackgroundFixo: url(${retorno.data.backgroundFixo});

        --Transparente: rgba(0,0,0,0);


        --TopoFixo: url(${retorno.data.topoFixo});


        /*PRIMARY COLOR EM RBB*/
        --Color-menuBottom: 
        
      }
    `);
  };

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;

    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = favicon;


  }, [favicon]);

  useEffect(() => {
    document.title = `${tituloPagina}`;

    // Crie as meta tags Open Graph
    const ogTitle = document.createElement('meta');
    ogTitle.setAttribute('property', 'og:title');
    ogTitle.setAttribute('content', `${tituloPagina}`);

    const ogDescription = document.createElement('meta');
    ogDescription.setAttribute('property', 'og:description');
    ogDescription.setAttribute('content', `Site de tips do ${tituloPagina}`);

    const ogImage = document.createElement('meta');
    ogImage.setAttribute('property', 'og:image');
    ogImage.setAttribute('content', `${logoIconMobile}`);

    // const ogURL = document.createElement('meta');
    // ogURL.setAttribute('property', 'og:url');
    // ogURL.setAttribute('content', 'URL da Página');

    // Adicione as meta tags ao cabeçalho
    document.head.appendChild(ogTitle);
    document.head.appendChild(ogDescription);
    document.head.appendChild(ogImage);
    // document.head.appendChild(ogURL);

    //Arrumar um jeito de carregar isso aqui so ao ler a url
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute('content', `Site de tips do ${tituloPagina}`);
    }

  }, [tituloPagina])

  //imagem icone mobile
  useEffect(() => {
    const addAppleTouchIcon = () => {
      const appleTouchIcon = document.querySelector("link[rel='apple-touch-icon']");
      if (!appleTouchIcon) {
        const newAppleTouchIcon = document.createElement('link');
        newAppleTouchIcon.rel = 'apple-touch-icon';
        newAppleTouchIcon.href = logoIconMobile; // Caminho para o novo ícone

        document.getElementsByTagName('head')[0].appendChild(newAppleTouchIcon);
      }
    };

    addAppleTouchIcon();
  }, [logoIconMobile]);


  useEffect(() => {
    const saveAppInfo = () => {
      const currentUrl = window.location.href;
      const appName = `${tituloPagina}`;

      localStorage.setItem('currentUrl', currentUrl);
      localStorage.setItem('appName', appName);
    };

    // Salva a URL atual e o nome do aplicativo ao adicionar o ícone no app mobile
    const appleTouchIcon = document.querySelector("link[rel='apple-touch-icon']");
    if (appleTouchIcon) {
      appleTouchIcon.addEventListener('load', saveAppInfo);
    }

    return () => {
      if (appleTouchIcon) {
        appleTouchIcon.removeEventListener('load', saveAppInfo);
      }
    };
  }, []);

 

  useEffect(() => {
    if(window.location.href == 'http://localhost:3000' || window.location.href == 'https://appbetbooster.vercel.app/' || window.location.href == dominio){
      setarCores("16");

    } else {
      setarCores(sessionStorage.getItem('@ClientId'));
    }
    
    // RetornarUrlLogo(setarUrls)2
  }, []);


  return (
    <CustomizacaoContext.Provider
      value={{
        logoUrl,
        setLogoUrl,
        backgroundImageUrl,
        setBackgroundImageUrl,
        cores,
        setCores,
        coresRoot,
        setCoresRoot,
        headerImgUrl,
        headerMobileLogin,
        RetornarCores,
        setarCores,
        imgBackgroundRegister,
        hoverButtonDrawer,
        logoUrlLogin,
        logoIconMobile,
        favicon,
        tituloPagina,
        logoDrawer,
        linkSuporte,
        imgPopup,
        linkAfiliado,
        statusModal,
        exibirMenu,
        setExibirMenu,
        imagemDeFundo,
        fundoPuro,
        iframeBool,
        setControleLoading,
        controleLoading,
        TopoFixo,
        TopoFixoBool,
        BackgroundFixo,
        BackgroundFixoBool,
        dominio,
        idioma,
        primaryColor,
        liveBool,
        linkLive

      }}
    >
      {children}
    </CustomizacaoContext.Provider>
  );
};

export const useCustomizacao = () => {
  const context = useContext(CustomizacaoContext);

  if (!context) {
    throw new Error("useCustomizacao must be used within a AutenticacaoProvider");
  }

  return context;
}

export {CustomizacaoContext, CustomizacaoProvider};

