import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface IProps {
    active?: boolean;
}

export const ContainerMenu = styled.div`
    display: flex;
    z-index: 1005;

`;

export const Button = styled(NavLink)<IProps>`
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 40px;
    gap: 4px;
    padding: 16px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    z-index: 1008;
    ${props => props.active && `
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.2) 100%), var(--Primary-color);
        box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.25), inset 0px 1px 1px rgba(255, 255, 255, 0.25);
        border-radius: 30px;

        
    `}
`;