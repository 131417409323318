import React, { useEffect, useState } from 'react';
import {
  BotaoGame,
  ContainerInfos,
  ContainerLinkJogo,
  ContainerPage,
  ContainerTip,
  DicaContainer,
  MineBlock,
  MineGame,
  PaiInfos
} from "./styles";
import AdjustIcon from '@mui/icons-material/Adjust';
import { useGameContext } from "../../context/game.context";
import { useParams } from 'react-router-dom';
import { useCustomizacao } from '../../Customizacao';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IframeContainer, TopBar } from '../group-chat/detailsPage/styles';
import { useTranslation } from 'react-i18next';
import { LoadingContainer } from '../group-chat/styles';
import { HexToFilter } from '../../utils/hextofilter';
import * as animationData from '../group-chat/Loading.json';
import HeaderChatDetails from '../group-chat/headerChatDetails';
import { TopBarGames } from './TopBarGames';

const MineGameComponent: React.FC = () => {
  const { activeGame, getUltimosDados, transformString } = useGameContext();
  const { cores, iframeBool } = useCustomizacao();
  const { chatId } = useParams();
  const [analizandoArtificial, setAnalizandoArtificial] = useState(true);
  const [isMobile, setIsMobile] = useState(true);
  const [clientId, setClientId] = useState<any>()
  const { setControleLoading } = useCustomizacao();
  const { nomeGrupo } = useParams();
  const { t, i18n } = useTranslation('mineGame')





  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 769);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setClientId(sessionStorage.getItem('@ClientId'))

  }, [])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setAnalizandoArtificial(false)
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const createMarkup = () => {
    let texto = activeGame.texto

    if (nomeGrupo == "Mines" || nomeGrupo == "Chicken") {
      // Extrai a primeira palavra do primeiro h1
      const primeiroH1 = texto.match(/<h1>(.*?)<\/h1>/i)[1];

      // Remove a primeira palavra do primeiro h1 e adiciona a cor preta
      const textoFormatado = texto.replace(/(<h1>)(.*?)(<\/h1>)/i, '');


      // Adicionar estilo de largura à <div> com o valor da hora após o parágrafo "Validade"
      const divComValidadeIndex = texto.indexOf('<p>🕜 Validade</p>');
      const divComValidadeCloseIndex = texto.indexOf('</div>', divComValidadeIndex);
      const divComValidade = texto.substring(divComValidadeIndex, divComValidadeCloseIndex + 6);

      const horaIndex = divComValidade.indexOf('<div>');
      const horaCloseIndex = divComValidade.indexOf('</div>', horaIndex);
      const divHora = divComValidade.substring(horaIndex, horaCloseIndex + 6);

      const divHoraComEstilo = divHora.replace('<div>', '<div style="width: 70px;">');
      const divComValidadeComEstilo = divComValidade.replace(divHora, divHoraComEstilo);
      const textoFormatadoComEstilo = textoFormatado.replace(divComValidade, divComValidadeComEstilo);



      return {
        __html: textoFormatadoComEstilo
          .split('\\r\\n\\r\\n')[0]
          .split('\\r\\n\\r\\n')[0].split(`<p>🖥️</p>`)[0].replace(/[\uD800-\uDFFF]./g, '')
      };
    }

    else {

      // Extrai a primeira palavra do primeiro h1
      const primeiroH1 = texto.match(/<h1>(.*?)<\/h1>/i)[1];

      // Remove a primeira palavra do primeiro h1 e adiciona a cor preta
      const textoFormatado = texto.replace(/(<h1>)(.*?)(<\/h1>)/i, '');


      // Adicionar estilo de largura à <div> com o valor da hora após o parágrafo "Validade"
      const divComValidadeIndex = texto.indexOf('<p>⏰ Validade:</p>');
      const divComValidadeCloseIndex = texto.indexOf('</div>', divComValidadeIndex);
      const divComValidade = texto.substring(divComValidadeIndex, divComValidadeCloseIndex + 6);

      const horaIndex = divComValidade.indexOf('<div>');
      const horaCloseIndex = divComValidade.indexOf('</div>', horaIndex);
      const divHora = divComValidade.substring(horaIndex, horaCloseIndex + 6);

      const divHoraComEstilo = divHora.replace('<div>', '<div style="width: 70px;">');
      const divComValidadeComEstilo = divComValidade.replace(divHora, divHoraComEstilo);
      const textoFormatadoComEstilo = textoFormatado.replace(divComValidade, divComValidadeComEstilo);



      return {
        __html: textoFormatadoComEstilo
          .split('\\r\\n\\r\\n')[0]
          .split('\\r\\n\\r\\n')[0].split(`<p>🖥️</p>`)[0].replace(/[\uD800-\uDFFF]./g, '').replace('⏰', '').replace(':', '')
      }
    }



  };

  useEffect(() => {
    getUltimosDados(chatId);
    const interval = setInterval(() => {
      getUltimosDados(chatId);
    }, 5000);
    return () => clearInterval(interval);
  }, [])

  return (
    <>{isMobile ?

      <ContainerPage corPrimaria={cores.primary_color} >
        {
          activeGame == "" || activeGame == undefined || activeGame == null || analizandoArtificial ? <div style={{
            paddingRight: '10px',
            textAlign: 'center',
            width: '100%',
            alignSelf: 'center',
          }}>
            <h2 style={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '20px',
              lineHeight: '20px',

              marginBottom: '-50px',
              textAlign: 'center',

              color: 'var(--Primary-color)',
            }}>{t("IA ANALISANDO MESAS")}</h2>
            <LoadingContainer filter={HexToFilter(cores.primary_color)} options={defaultOptions} />
          </div>
            :
            <>
              <ContainerTip>
                <DicaContainer>
                  <MineGame>
                    {[...activeGame.texto.split('\\r\\n\\r\\n')[1]].filter(char => char === '⭐' || char === '🟦' || char === '🦴' || char === '🍗' || char === '_' || char === '|' || char === '🟢' || char === '⚽' || char === '🧍').map((block, index) => {

                      if (block === '_' || block === '|') {
                        return <MineBlock style={{ background: '#000' }}>
                          &nbsp;
                        </MineBlock>
                      }

                      if (block !== '🟦') {
                        return <div key={index} style={{ width: `${(block == '⭐' && '15px')}` }}>
                          {block}
                        </div>
                      }

                      return <div key={index} style={{ width: `${(block == '🟦') && '15px'}` }}>
                        <MineBlock>&nbsp;</MineBlock>
                      </div>
                    })}
                  </MineGame>
                </DicaContainer>

                <PaiInfos corPrimaria={cores.primary_color} nomeGrupo={nomeGrupo}>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    margin: '0',
                    padding: '0',
                    marginLeft: '0px',
                    marginTop: '0px',
                    marginBottom: '4px'

                  }}>
                    <AdjustIcon style={{
                      fill: 'var(--Primary-color)',
                      fontWeight: 'bold',
                      fontSize: '24px',
                      opacity: '1',
                      marginTop: '0px',
                      marginRight: '2px',
                      paddingLeft: '0px'
                    }} />
                    <h1 style={{ color: 'white', fontSize: '16px', fontWeight: 'bold', fontFamily: 'Montserrat', lineHeight: '20px' }}>Entrada <span style={{ color: 'var(--Primary-color)' }}> Confirmada</span></h1>

                  </div>

                  <ContainerInfos style={{ marginLeft: `${nomeGrupo !== "Penalty" && '0px'}` }} id="container-infos-game" dangerouslySetInnerHTML={createMarkup()} />
                </PaiInfos>
              </ContainerTip>
              </>
        }


              <div>
                <IframeContainer style={{
                  display: `${iframeBool != 1 && 'none'}`,
                  margin: 'auto',
                  width: '95vw'
                }}>
                  <iframe src={activeGame.texto.match(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/)[2]} title="Jogo" />
                </IframeContainer>
              </div>
              <ContainerLinkJogo corPrimaria={cores.primary_color}>
                <p style={{ color: 'var(--Secondary-Text-color)' }}>O jogo não abriu? Clique no botão acessar.</p>
                <a href={activeGame.texto.match(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/)[2]} target="_blank"
                  rel="noreferrer"><BotaoGame data-jogada-id={activeGame.jogadaId} id='game-button' corPrimaria={cores.primary_color} style={{
                    background: `${clientId == "16" || clientId == "32" ? 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)' : 'var(--Primary-color)'}`,
                    borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
                    boxShadow: `${clientId == "16" || clientId == "32" ? '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)' : '0px 0px 5px 2px rgba(0,0,0,0.1), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
                  }}>
                    <OpenInNewIcon /> {t("ACESSAR JOGO")}
                  </BotaoGame></a>
              </ContainerLinkJogo>
      </ContainerPage>

      :
      <>
        {/*DESKTOP*/}
  
     <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <div style={{display: 'flex', width: '90%'}}>
          <TopBarGames/>
          </div>
      </div>
      <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <div style={{display: 'flex', width: '90%'}}>

                  <ContainerPage corPrimaria={cores.primary_color} style={{width: '90%'}}>
     
                    <ContainerTip>
                      <DicaContainer>
                        <MineGame>
                          {[...activeGame.texto.split('\\r\\n\\r\\n')[1]].filter(char => char === '⭐' || char === '🟦' || char === '🦴' || char === '🍗' || char === '_' || char === '|' || char === '🟢' || char === '⚽' || char === '🧍').map((block, index) => {

                            if (block === '_' || block === '|') {
                              return <MineBlock style={{ background: '#000' }}>
                                &nbsp;
                              </MineBlock>
                            }

                            if (block !== '🟦') {
                              return <div key={index}>
                                {block}
                              </div>
                            }

                            return <div key={index}>
                              <MineBlock>&nbsp;</MineBlock>
                            </div>
                          })}
                        </MineGame>
                      </DicaContainer>
                      <PaiInfos corPrimaria={cores.primary_color} nomeGrupo={nomeGrupo}>
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          margin: '0',
                          padding: '0',
                          marginLeft: '-10px',
                          marginTop: '-10px'

                        }}>
                          <AdjustIcon style={{
                            fill: 'var(--Primary-color)',
                            fontWeight: 'bold',
                            fontSize: '26px',
                            opacity: '1',
                            marginTop: '0px',
                            marginRight: '0px',
                            paddingLeft: '0px'
                          }} />
                          <h1 style={{ color: 'white', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Montserrat', lineHeight: '20px' }}>Entrada <span style={{ color: 'var(--Primary-color)' }}> Confirmada</span></h1>

                        </div>

                        <ContainerInfos id="container-infos-game" dangerouslySetInnerHTML={createMarkup()} />
                      </PaiInfos>
                    </ContainerTip>
                    <ContainerLinkJogo corPrimaria={cores.primary_color} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>

                      <p style={{ color: '#BDA192', width: '100%', textAlign: 'left' }}>{t("O jogo não abriu? Clique no botão acessar.")}</p>

                      <a href={activeGame.texto.match(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/)[2]} target="_blank"
                        rel="noreferrer">
                        <BotaoGame data-jogada-id={activeGame.jogadaId} id='game-button' corPrimaria={cores.primary_color} style={{
                          background: `${clientId == "16" || clientId == "32" ? 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)' : 'var(--Primary-color)'}`,
                          borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
                          boxShadow: `${clientId == "16" || clientId == "32" ? '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)' : '0px 0px 5px 2px rgba(0,0,0,0.1), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
                        }}>
                          <OpenInNewIcon /> {t("ACESSAR JOGO")}
                        </BotaoGame>
                      </a>
                    </ContainerLinkJogo>

                    <div>
                      <IframeContainer style={{
                        display: `${iframeBool != 1 && 'none'}`,
                        margin: 'auto',

                      }}>
                        <iframe src={activeGame.texto.match(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/)[2]} title="Jogo" />
                      </IframeContainer>
                    </div>

                  </ContainerPage>
                  </div>
      </div>
      </>
    }

    </>
  );
};

export default MineGameComponent;
