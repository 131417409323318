import React, {useEffect, useState} from 'react';
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {VerifCliente2} from "../../services/whitelabel";
import {useCustomizacao} from "../../Customizacao";
import {DivLoading} from "../Login/styles";
import Loading from "../../components/Loading";
import {Checkbox, Modal} from "@mui/material";
import {useGameContext} from "../../context/game.context";
import {DivContainer, Htwo, ImagemModal, StyledBox, StyledButton, StyledLittleSpan} from './styles';
import {GetPopupStatus, UpdatePopup} from '../../services/Roleta';
import MenuBottom from '../../components/MenuBottom';
import Carregando from '../../components/group-chat/Carregando'
import { useTranslation } from 'react-i18next';

export const ContainerPages = () => {
  const {
    RetornarCores,
    setarCores,
    logoUrl,
    logoUrlLogin,
    logoIconMobile,
    favicon,
    tituloPagina,
    imgPopup,
    linkAfiliado,
    statusModal,
    dominio,
    idioma
  } = useCustomizacao()

  const [verifPath, setVerifPath] = useState<boolean | any>(false)
  const [clientId, setClientId] = useState<any>();
  const [faviconState, setFavicon] = useState();
  const [tituloState, setTitulo] = useState();
  const {nomeCliente} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const {modalCadastro, setModalCadastro} = useGameContext();
  const [aux, setAux] = useState<any>()
  const [isDesktop, setIsDesktop] = useState(true);
  const {cores, controleLoading} = useCustomizacao();
  const [statusModalCliente, setStatusModalCliente] = useState<number>()
  const [checked, setChecked] = useState<boolean>(false)
  const {t, i18n} = useTranslation('containerPages')

  //...
  useEffect(()=>{
    // if(idioma !== "" || idioma != "" || idioma != null){
    //   i18n.changeLanguage(idioma)
      
    // }
  }, [])

  const navigate = useNavigate();

  const subdomain = nomeCliente

  let user = localStorage.getItem('@App:user')


  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 769);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    
  }, []);


  useEffect(() => {
    if (!(window.location.href.endsWith(`/`) || window.location.href.endsWith('/Registro') || window.location.href.endsWith('/esquecisenha') || window.location.href.endsWith('/resetpassword'))) {
      setVerifPath(true)
    }

    if(window.location.href == 'http://localhost:3000' || window.location.href == 'https://appbetbooster.vercel.app' || window.location.href == dominio){
      localStorage.removeItem('@Subdomain')
      sessionStorage.removeItem('@Subdomain')
    }
  }, [])


  //console.log(subdomain, 'subdomain');
  useEffect(() => {
    if (subdomain != null) {
      if (localStorage.getItem('@Subdomain')) {
        if (subdomain != localStorage.getItem('@Subdomain') || localStorage.getItem('@Subdomain') != nomeCliente) {
          sessionStorage.removeItem('token')
          localStorage.removeItem('@App:user')
          
        }
      }
      if (subdomain != "ListaGrupos" && subdomain != "Grupo" && subdomain != "ebook" && subdomain != "Perfil" && subdomain != "resetpassword" && subdomain != null) {
        sessionStorage.setItem('@Subdomain', subdomain);
        localStorage.setItem('@Subdomain', subdomain)
      }
    } else {
      localStorage.setItem('@Subdomain', 'betbooster')
      sessionStorage.setItem('@Subdomain', 'betbooster')
    }
    //....
  }, [])


  useEffect(() => {
    console.log(window.location.href, ': urlAtual')
    const fetchData = async () => {
      
        try {
         
            const response = await VerifCliente2(window.location.href === 'http://localhost:3000' || window.location.href === 'https://appbetbooster.vercel.app/' || window.location.href == dominio ? 'betbooster' : subdomain);
            const clientid = response?.data.clientId;
            // console.log(clientid);
            setClientId(clientid);
            setarCores(clientid)
            setFavicon(favicon)
            setTitulo(tituloPagina)
          
          

        } catch (error) {
          console.log(error);
        
      }
    };

    const isClientIdValid = (clientId: number) => {
      return typeof clientId === 'number' && !isNaN(clientId);
    };

    if (!isClientIdValid(clientId)) {
      fetchData();
    } else {
      sessionStorage.setItem('@ClientId', clientId);
      localStorage.setItem('@ClientId', clientId);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);

    }

    setAux(true)
  }, [clientId]); // Adicionando clientId como dependência do useEffect

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;

    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = favicon;


  }, [favicon]);

  useEffect(() => {
    document.title = `${tituloPagina}`;

  }, [tituloPagina])

  //imagem icone mobile
  useEffect(() => {
    const addAppleTouchIcon = () => {
      const appleTouchIcon = document.querySelector("link[rel='apple-touch-icon']");
      if (!appleTouchIcon) {
        const newAppleTouchIcon = document.createElement('link');
        newAppleTouchIcon.rel = 'apple-touch-icon';
        newAppleTouchIcon.href = logoIconMobile; // Caminho para o novo ícone

        document.getElementsByTagName('head')[0].appendChild(newAppleTouchIcon);
      }
    };

    addAppleTouchIcon();
  }, [logoIconMobile]);

  useEffect(() => {


    if (user) {
      const parsedUserInfo = JSON.parse(user)

      const email = parsedUserInfo.email;

      const fetchData = async (clientId: any, email: any) => {
        const response = await GetPopupStatus(clientId, email)
        // console.log(response?.data.grupos[0]["popupCadastro"], 'response2')
        setStatusModalCliente(response?.data.grupos[0]["popupCadastro"])
        return response?.data.grupos.popupCadastro

      }

      fetchData(sessionStorage.getItem('@ClientId'), email)
    }

  }, [])


  function handleClick() {
    // let clientId = sessionStorage.getItem('@ClientId')
    if (user) {
      const parsedUserInfo = JSON.parse(user);
      const email = parsedUserInfo.email;

      const fetchData = async () => {

        const response = await UpdatePopup(sessionStorage.getItem('@ClientId'), email)
        // console.log(clientId, ' : clientid ', email, ' : email')
        setStatusModalCliente(1)
        return response
      }

      if (checked) {
        fetchData();
      }

    }

    setModalCadastro(false)

  }

  function cadastrar() {
    window.open(linkAfiliado, '_blank');

  }


  const handleChange = () => {
    if (checked) {
      setChecked(false)
    } else {
      setChecked(true)
    }

  }

  const closeModal = () => {
    setModalCadastro(false)
    if (checked) {

    }
  }

  const path = window.location.pathname
  // console.log('pathname: ', path, path.includes('Grupo'))

  return (
    <>
      <Modal
        sx={{width: '100%'}}
        open={((statusModal == 1 && statusModalCliente == 0) && modalCadastro)}
        onClose={() => {
          if (checked) {
            setStatusModalCliente(1)
          }
          setModalCadastro(false)
        }
        }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledBox>
          <DivContainer>
            {/* <StyledImg src={imgPopup}/> */}
            <ImagemModal src={logoUrl}/>

            <Htwo>
              {t("Para uma melhor experiencia, cadastre-se na ")}{cores.casaAposta}
              
            </Htwo>


            <StyledButton
              style={{
                cursor: 'pointer'
              }}
              onClick={cadastrar}
            >
              {t("Cadastre-se")}
            </StyledButton>

            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '20px'
            }}>
              <Checkbox
                color="primary"
                checked={checked}
                onChange={handleChange}

                inputProps={{'aria-label': 'controlled'}}
              />
              <StyledLittleSpan style={{color: 'var(--Secondary-Text-color)'}}>
                {t("Não mostrar mais")}
              </StyledLittleSpan>
            </div>


            <span style={{
              position: 'absolute',
              color: 'var(--Secondary-Text-color)',
              top: 15,
              right: 15,
              cursor: 'pointer',

            }} onClick={handleClick}>X</span>

          </DivContainer>

        </StyledBox>
      </Modal>

      {isLoading ? (
        <DivLoading>
          <div
            style={{
              marginRight: '80px',
            }}
          >
            <Loading text={t("Carregando...")} tipoMesa={null}/>
          </div>
        </DivLoading>
      ) : (
        <Outlet/>
      )}


      {(!isDesktop && verifPath && !isLoading && !controleLoading && !(path.includes('/Grupo'))) && <MenuBottom/>}
    </>
  )
}
