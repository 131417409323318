import React, {useEffect, useState} from 'react'
import {GetGruposLabel} from '../../../services/Roleta';
import {NavLink, useNavigate} from 'react-router-dom';
import '../styles.css'
import moment from "moment";
import "moment/locale/pt-br";
import CardGrupo from '../../CardGrupos';
import {useGameContext} from "../../../context/game.context";
import {useCustomizacao} from '../../../Customizacao';
import { useTranslation } from 'react-i18next';



moment.locale("pt-br");

const TableRowMobile: React.FC = () => {
  const {setGrupoAtivo} = useGameContext();

  const [grupos, setGrupos] = useState([]);

  const {modalCadastro, setModalCadastro} = useGameContext()
  const {setExibirMenu, setControleLoading} = useCustomizacao();
  const [controleDoControle, setControleDoControle] = useState(true);
  const [controle2, setControle2] = useState(false)
  const navigate = useNavigate()
  const {t, i18n} = useTranslation('listaGrupos')

  let listaDeGrupos: Array<any> = [];

  let numeroGrupoGreen;
  let numeroGrupoRed;
  let numeroUltimaEntrada;
  let inicioGradient;
  let fimGradient;

  const horaFormatada = (hora: moment.MomentInput) => {
    const dataHora = moment(hora, "M/D/YYYY h:mm:ss A");
    return dataHora.format("HH:mm");
  };


  useEffect(() => {

    let clientId = sessionStorage.getItem('@ClientId')
    const fetchGrupos = async () => {
      const data = await GetGruposLabel(clientId);
      setGrupos(data.grupos);


      for (let i in data.grupos) {
        listaDeGrupos.push([data.grupos[i].chatId, data.grupos[i].afiliadoId])
      }
      setControle2(true)
    }
    fetchGrupos()


  }, [])


  // useEffect(() => {
  //   if (controle2 && grupos.length > 0) {
  //     setControleDoControle(true);
  //     setControleLoading(false);
  //   }
  // }, [controle2, grupos]);

  return (
    <>
      {grupos
        .filter((i: any) => i.grupoAtivo === "1")
        .map((grupo: any, index: number) => {
          const isLastGroup = index === grupos.length - 2;

          return (
            <NavLink
              to={`../../Grupo/${grupo.chatId}/${grupo.afiliadoId}/${grupo.tipoMesa}/${grupo.nomeGrupo}`}
              style={{
                maxWidth: '100%',
                width: '100%'
              }}
              onClick={() => {
                setControleLoading(true)
                //setModalCadastro(true);
            

                setGrupoAtivo(grupo);
                sessionStorage.setItem('@GradientGrupo', `${grupo.gradientColorHex}`);
                sessionStorage.setItem('@NomeDoGrupo', grupo.nomeGrupo);
                setExibirMenu(false);
              }}
            >
              <CardGrupo
                imagem={grupo.iconUrl}
                nome={grupo.nomeGrupo}
                gradient={grupo.gradientColorHex}
                grupo={grupo.nomeGrupo}
                grupoAtivo={grupo.grupoAtivo}
                chatId={grupo.chatId}
                afiliadoId={grupo.afiliadoId}
                tipoMesa={grupo.tipoMesa}
              />
              {isLastGroup == true && setControleLoading(false)}
            </NavLink>
          );
        })}
    </>
  );
};
export default TableRowMobile
