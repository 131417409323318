import React, {useEffect} from 'react';
import {DivFilho, PaiLogin, TituloPagina} from './styles';
import {useCustomizacao} from '../../Customizacao';
import { useTranslation } from 'react-i18next';

interface CardRegisterProps {
  style?: React.CSSProperties; // permite a adição de propriedades de estilo
}
//.........

const CardEbook: React.FC = () => {
  const {logoUrl} = useCustomizacao();
  const {t, i18n} = useTranslation('ebook')

  const verifAuth = () => {
    const verifToken = sessionStorage.getItem('token')
    if (verifToken == null) {
      window.location.href = './'
    }
  }

  useEffect(() => {
    verifAuth()
  }, [])
  return (
    <>
      <PaiLogin>
        <DivFilho>
          <img src={logoUrl} style={{
            width: '200px',

          }}/>

          <TituloPagina>
            {t("Em Breve Novidades...")}
          </TituloPagina>

        </DivFilho>

      </PaiLogin>
    </>
  );
}

export default CardEbook;
