import React, {useEffect, useState} from 'react'
import {BolaGreen, BolaRed, DivGreenRed, DivLeftHeader, HeaderDiv, Image, NumResults, TituloGrupo} from './styles';
import {useNavigate, useParams} from "react-router-dom";
import './styles.css'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {GetInfosGreenDinamico} from '../../../services/Roleta';
import {useGameContext} from '../../../context/game.context';
import {useCustomizacao} from '../../../Customizacao';
import { useTranslation } from 'react-i18next';

interface HeaderChatProps {
  gradient: string | any;
  nomeDoGrupo: string | any;

  // numGreens: any;
  // numReds: any;
}

let auxiliar = "";
let auxiliarRed = "";


const HeaderChat: React.FC<HeaderChatProps> = ({gradient, nomeDoGrupo}) => {
  const {chatId, afiliadoId, tipoMesa} = useParams();
  const [green, setGreen] = useState();
  const [red, setRed] = useState();

  const [isMobile, setIsMobile] = useState<boolean | any>(false);

  const [grupos, setGrupos] = useState([]);
  const {grupoAtivo} = useGameContext();
  const {logoUrl, setExibirMenu, TopoFixoBool} = useCustomizacao();
  const {t, i18n} = useTranslation('header')

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 769);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  }, [])


  const GradientStyle = {
    marginTop: !isMobile ? '60px' : '0px',
    height: '68px',
    boxShadow: `${!isMobile && 'none'}`,
    width: `${!isMobile ? '100%' : tipoMesa == '0' ? '100vw' : '100vw'}`,
    background: `${isMobile && TopoFixoBool == "1" ? 'var(--TopoFixo)' : !isMobile ? '' : 'linear-gradient(0deg, var(--Primary-color) 0%, rgba(220, 79, 0, 0) 100%)'}`,
    borderBottom: `${isMobile && TopoFixoBool != "1" ? '2px solid var(--Primary-color)' : !isMobile ? 'unset' : ''}`,
    padding: `${!isMobile && tipoMesa == '1' ? '0px' : ''}`,
    justifyContent: `${tipoMesa == '0' ? 'space-around' : 'center'}`,
    gap: `${tipoMesa == '0' ? '50%' : '0'}`,
    paddingLeft: `${tipoMesa == "1" && '0'}`,
    margin: `${tipoMesa == '1' && !isMobile && '70px 0 0px 0'}`,
    // maxWidth: `${tipoMesa == '1' && '1130px'}`
 
  }
  

  const navigate = useNavigate();

  const handleClick = () => {
    setExibirMenu(true);
    sessionStorage.removeItem('@GradientGrupo')
    sessionStorage.removeItem('@NomeDoGrupo')
    navigate(`../../../../${sessionStorage.getItem('@Subdomain')}/ListaGrupos`)
  }


  useEffect(() => {
    const fetchData = async () => {
      const data = await GetInfosGreenDinamico(chatId);

      setGreen(data.roletas[0].green1)
      setRed(data.roletas[0].red1)

    }
    fetchData()

  }, [])


  return (
    <>
      <HeaderDiv style={GradientStyle}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '90%'}}>
          <DivLeftHeader>
            <ArrowBackIosNewIcon
              onClick={handleClick}
              fontSize='medium'
              style={{
                cursor: 'pointer',
              }}/>
            <TituloGrupo>{t(`${nomeDoGrupo}`)}</TituloGrupo>
            <DivGreenRed>
              {
                !isMobile && tipoMesa == "1" &&
                <>
                  <BolaGreen/><NumResults>
                  {green}</NumResults>
                  <BolaRed/><NumResults>{red}</NumResults>
                </>
              }
            </DivGreenRed>
            
          </DivLeftHeader>


          <Image imagem={grupoAtivo.iconUrl}/>

        </div>
      </HeaderDiv>


    </>
  )
}

export default HeaderChat;
