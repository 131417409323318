import styled from 'styled-components';


export const DivBannerTop = styled.div`
  background: #000;
  width: 100%;
  height: 160px;

`;

export const BannerTop = styled.img`
  width: 100%;
  height: 160px;
  object-fit: cover;
  background: #000;
  opacity: 0.4;
  position: relative;
  
`;

export const LogoBanner = styled.img`
  position: absolute;
  top: 30px;
  left: calc(50% - 109px/2);
  z-index: 1;
`;


export const PaiLogin = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
`

export const TituloPagina = styled.h1`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 39px;
    /* identical to box height */


    /* Black */

    color: var(--Text-color);
    text-align: left;
    align-self: left;
    justify-content: left;
    align-content: left;
    
`;

export const Cardlogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
    
`

export const ContentBackground = styled.div`
    background: var(--Background-color);
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
`
export const PaiInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items:center;
`

export const StyledLabel = styled.label`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  /* Black */

  color: #000000;
  margin-left: 0px;
  margin-bottom: 5px;
  margin-top: 15px;
`;

export const StyledInput = styled.input`
  background: #FFF;
  color: var(--Text-color);
  height: 50px !important;
  box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: none !important;
  padding-bottom: 0px !important;
  box-sizing: border-box;
  padding-left: 25px;
  font-family: 'Montserrat';
  font-size: 18px;

  &:focus{
    outline: none;
  }

  &::placeholder{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;

    color: #999999;
  }

  -webkit-appearance: none; /* Remova o estilo padrão do iOS */
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.6);

`;

export const StyledButtonEntrar = styled.button`
  background: var(--Primary-color);
  box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 350px;
  height: 50px;
  color: white;
  border: none;
  margin-top: 30px;
  font-family: 'montserrat', sans-serif;
  font-weight: bold;
  &:hover{
    cursor: pointer;
  }

`;

export const StyledButtonRegistrar = styled.button`
  width: 350px;
  height: 50px;
  color: white;
  border: none;
  background: var(--Secondary-color);
  box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-top: 20px;
  font-family: 'montserrat', sans-serif;
  font-weight: bold;
  &:hover{
    cursor: pointer;
  }

`;

export const StyledSpan = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  /* Red */

  color: var(--LinksColor-primary);

  text-decoration: none;
  
`;
