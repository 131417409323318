import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {CSSTransition} from "react-transition-group";
import {GetMensagensAoVivo, GetMensagensOffline} from "../../services/Roleta";
import HeaderChat from "./headerChat";
import Mensagem from "./mensagem";
import {
  BtPrimary,
  ContainerMensagens,
  ContainerMensagensAux,
  ContainerSemAcesso,
  DivPai,
  DivSemAcesso,
  LoadingContainer,
  LogoSemAcesso,
  MensagensLimitador,
  TextSemAcesso,
  TituloSemAcesso
} from "./styles";
import './styles.css';
import MineGameComponent from "../MineGame";
import {useGameContext} from "../../context/game.context";
import {useCustomizacao} from "../../Customizacao";
import * as animationData from './Loading.json'
import {HexToFilter} from "../../utils/hextofilter";
import HeaderChat2 from "./headerChat copy";
import DetailsPage from "./detailsPage";
import Header from "../Header";
import Carregando from "./Carregando";
import { useTranslation } from 'react-i18next';
import AviatorPage from "./aviatorPage";
import HeaderChatDetails from "./headerChatDetails";


let varAuxiliar: boolean;
let varAuxiliarCarregamento = false;

//...
const GrupoChat: React.FC = () => {
  const [isDesktop, setIsDesktop] = useState<boolean | null>(false);
  const [data, setData] = useState<any[]>([]);
  const [dataChanged, setDataChanged] = useState(false);

  const {chatId, afiliadoId, tipoMesa} = useParams();
  const containerRef = useRef<HTMLDivElement>(null);
  const prevDataRef = useRef<any[]>([]);
  const [acesso, setAcesso] = useState(true)

  const [otherMessages, setOtherMessages] = useState<string[]>([]);
  const [gradient, setGradient] = useState<string>();
  const [analisando, setAnalisando] = useState<boolean>(false);
  const {cores} = useCustomizacao();
  const [filter, setFilter] = useState<string>();
  const [pageDetails, setPageDetails] = useState<boolean>(false);

  const {controleLoading, setControleLoading} = useCustomizacao()
  const {modalCadastro, setModalCadastro} = useGameContext()

  const {getUltimosDados, exibirDetalhes, setNomeRoletaAmigavel, nomeRoletaAmigavel, grupoAtivo} = useGameContext();
  const {fundoPuro} = useCustomizacao();
  const [controle3, setControle3] = useState(false)
  const [auxiliar, setAuxiliar] = useState(false)
  let newData;
  const intervalRef = useRef<number>()
  const [clientId, setClientId] = useState<any>()
  const [roletaId, setRoletaId] = useState<any>()
  const [jogadaId, setJogadaId] = useState<any>()
  const [nomeAmigavel, setNomeAmigavel] = useState<any>()
  const [texto, setTexto] = useState<any>()
  const {nomeGrupo} = useParams();
  const {t, i18n} = useTranslation('groupChat')

  //......
  
  useEffect(()=>{
    setClientId(sessionStorage.getItem('@ClientId'))
  }, [])

  const fetchData = async () => {
    let response: any[] = [];
    if (tipoMesa == "1" && nomeGrupo != "Aviator" && nomeGrupo != "Dino") {
      response = await GetMensagensAoVivo(chatId);

    } else if (tipoMesa == "0" && nomeGrupo != "Aviator" && nomeGrupo != "Dino") {
      //chamar metodo ofline
      response = await GetMensagensOffline(chatId);
    }

    const updatedResponse = response ? response.map((m) => {
      if (m.atualizacao === "true") {
        const foundItem = response.find(
          (i) => i.atualizacao !== "true" && i.jogadaId === m.jogadaId
        );
        if (foundItem) {
          foundItem.atualizacao = m.texto;
        }
      }

      return m;
    }) : [];


    setData(updatedResponse);
    setRoletaId("")
    setJogadaId("")
    setNomeAmigavel("")
    setTexto("")
  };


  useEffect(() => {
    setTimeout(() => {
      fetchData()
      setControleLoading(false)
      setModalCadastro(true)

    }, 500)
    
  }, [])


  const containerCardsref = useRef<HTMLDivElement>(null);


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };


  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 769);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

  }, [])


  const gradientStyle = {
    background: `var(${gradient})`,
  }

  useEffect(() => {

    const interval = setInterval(() => {
      fetchData()
    }, 3000)

    //fetchData();

    return () => {

      clearInterval(interval);

    }
  }, []);


  useEffect(() => {
    const prevData = prevDataRef.current;
    if (
      data.length > prevData.length ||
      data.some(
        (msg, index) => msg.texto !== prevData[index]?.texto || msg.data !== prevData[index]?.data
      )
    ) {
      if(tipoMesa == '1'){
        containerRef.current?.scrollTo({
          top: containerRef.current?.scrollHeight,
          behavior: "smooth",
  
        });
      }
      //....
    }
    prevDataRef.current = data;


  }, [data]);


  const handleAddOtherMessage = (message: string) => {
    setOtherMessages([...otherMessages, message])
    alert(data)

  }
  useEffect(() => {
    varAuxiliarCarregamento = false;
  }, [])


  useEffect(() => {
    if (varAuxiliar) {
      setAnalisando(true)
    }

  }, [data])


  function goToCheckout() {
    let url = 'https://app.monetizze.com.br/checkout/KKD301494'
    window.open(url, '_blank');
  }


  

  return (
  
    <>
    {nomeGrupo == "Aviator" || nomeGrupo == "Dino" ?
    
      <AviatorPage/> 
      :
      
      exibirDetalhes ? <DetailsPage/> :        
      <>
      <style>
        {
          `
              div[aria-label="animation"] {
                ${cores?.corGif || 'filter: invert(0%) sepia(13%) saturate(975%) hue-rotate(34deg) brightness(81%) contrast(99%);'}
              }
            `
        }
      </style>

      {acesso ?
        <DivPai corPrimaria={cores.primary_color} style={{
          background: `${cores.backgroundFixoBool === '1' ? 'var(--BackgroundFixo)' : fundoPuro === "1" ? 'var(--ImagemFundo-Main)' : `linear-gradient(0deg, ${cores.primary_color}50, ${cores.primary_color}50), var(--ImagemFundo-Main)`}`,
          backgroundSize: `${clientId == '16' || clientId == "32" && 'cover'}`,
          backgroundRepeat: `${clientId == '16' || clientId == "32" && 'no-repeat'}`,

        }}>

          <ContainerMensagens id="container-mensagens" style={{
            maxHeight: '100vh',
            borderRadius: '10px',
          }}>
            {isDesktop && <Header />}
            <div style={{
              width: '100%',
            
            }} tabIndex={-1}
                 onClick={() => {
                   containerRef.current?.focus()
                 }}>
               {tipoMesa == "1" ? <HeaderChat gradient={sessionStorage.getItem('@GradientGrupo')}
                          nomeDoGrupo={sessionStorage.getItem('@NomeDoGrupo')}/> : !isDesktop ? <HeaderChatDetails gradient={sessionStorage.getItem('@GradientGrupo')}
                          nomeDoGrupo={sessionStorage.getItem('@NomeDoGrupo')}/> : <></>}   
              
              {!isDesktop && <HeaderChat2 gradient={sessionStorage.getItem('@GradientGrupo')}
                           nomeDoGrupo={sessionStorage.getItem('@NomeDoGrupo')}/>}
            </div>


            <ContainerMensagensAux tabIndex={0} ref={containerRef}
                                   style={{
                                     width: `${tipoMesa == "0" && '100%'}`,
                                     marginTop: `${isDesktop ? "" : tipoMesa == '0' ? "20px" : "120px"}`,
                                     paddingBottom: `${tipoMesa == "0" ? '0px' : '20px'}`,
                                   }}>
              <MensagensLimitador style={{
                width: `${(tipoMesa == '0' && isDesktop) && '100vw'}`,
                maxWidth: `${tipoMesa == '0'&& isDesktop && '100%'}`
              }}>

                {/*JOGOS OFLINE*/}
                {data && tipoMesa == "0" && !controleLoading ?
                  data.map((obj: any) => {
                      let ultimoObjeto = data[data.length - 1];

                      if (!(ultimoObjeto["texto"].includes("ANALISANDO") || ultimoObjeto["texto"].includes("CONFIRMADA"))) {
                        varAuxiliar = true;
                        varAuxiliarCarregamento = true

                      } else {
                        varAuxiliar = false;
                      }

                      if (obj.atualizacao != "true" && acesso) {

                        return <>
                          <MineGameComponent/>
                        </>
                      }
                    }
                  ) :
                  <div style={{display: `${!controleLoading && 'none'}`}}>
                    <Carregando tipoMesa={null} text={"Carregando..."} />
                  </div>
                  }


                {/*JOGOS ONLINE*/}


                {data && tipoMesa == "1" && !controleLoading ?
                  data.map((obj: any) => {
                      let ultimoObjeto = data[data.length - 1];

                      // if(ultimoObjeto){
                      //   setControleLoading(false)
                      // }

                      if (!(ultimoObjeto["texto"].includes("ANALISANDO") || ultimoObjeto["texto"].includes("CONFIRMADA"))) {
                        varAuxiliar = true;
                        varAuxiliarCarregamento = true

                      } else {
                        varAuxiliar = false;
                      }

                      if (obj.atualizacao != "true" && acesso) {

                        return <>
                          <CSSTransition key={obj.id} timeout={100} classNames="fade">
                            <Mensagem
                              key={obj[0]}
                              conteudo={obj["texto"]}
                              status={obj["atualizacao"] != "true" ? obj["atualizacao"] : ""}
                              hora={obj["data"]}
                              jogadaId={obj[3]}
                              setOtherMessages={setOtherMessages}
                              ultimaAtualizacao={false}
                              onRender={() => {
                                !obj["texto"].includes("green") &&
                                !obj["texto"].includes("red") &&
                                handleAddOtherMessage(obj)
                              }}
                              chatid={chatId}
                              dadosCompletos={obj}
                              nomeAmigavel={obj["nomeRoletaAmigavel"]}

                            />
                          </CSSTransition>

                        </>
                      }


                    }
                  ) : 
                    <div style={{
                      display: `${!controleLoading && 'none'}`
                    }}>

                      <Carregando tipoMesa={"1"} text={"Carregando..."} />
                    </div>
                  }
                {/* {  ?<><Loading text={"IA Analisando os Sinais..."} tipoMesa={tipoMesa}/><br/></>  : <><br/></>  } */}
                {varAuxiliarCarregamento && varAuxiliar && tipoMesa === '1' &&
                  <div style={{
                    paddingRight: '10px',
                    textAlign: 'center'
                  }}>
                    <h2 style={{
                      fontFamily: 'Montserrat',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      fontSize: '20px',
                      lineHeight: '20px',

                      paddingTop: '50px',
                      marginBottom: '-50px',
                      textAlign: 'center',

                      color: 'var(--Primary-color)',
                    }}>{t("IA ANALISANDO MESAS")}</h2>
                    <LoadingContainer filter={HexToFilter(cores.primary_color)} options={defaultOptions}/>
                  </div>
                }
              </MensagensLimitador>
            </ContainerMensagensAux>

          </ContainerMensagens>

        </DivPai>

        : <DivPai corPrimaria={cores.primary_color}> {/*SEM ACESSO*/}

          {isDesktop && <Header />}
          <ContainerSemAcesso style={gradientStyle}>
            <HeaderChat gradient={sessionStorage.getItem('@Gradient')}
                        nomeDoGrupo={sessionStorage.getItem('@nomeDoGrupo')}/>

            <DivSemAcesso>
              <LogoSemAcesso src={isDesktop && grupoAtivo.iconUrl}/>

              <TituloSemAcesso>{t("Grupo Privado")}</TituloSemAcesso>

              <TextSemAcesso>{t("Você ainda não tem acesso a este grupo!")}</TextSemAcesso>
              <BtPrimary onClick={goToCheckout}>
                <a href="https://app.monetizze.com.br/checkout/KKD301494" target="_blank" style={{
                  color: 'white',
                  textDecoration: 'none'
                }}>{t("Comprar acesso ao grupo")}</a>

              </BtPrimary>


            </DivSemAcesso>
          </ContainerSemAcesso>


        </DivPai>

      }
      </>
    }
    </>
  );
};
export default GrupoChat
