// import styled from 'styled-components'
import styled from 'styled-components';

interface Props {
  fadeout?: boolean
}

export const DivPai = styled.div<Props>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: right;
  z-index: 999;


  padding-bottom: 2px;
  //animacoes

  @media screen and (min-height: 691px) {
    margin-top: 2%;
  }

  ${props => props.fadeout && `&.fade-out {
    animation-name: fadeIn;
    animation-duration: 0.5s;`
  } @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  } @keyframes pop-up {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  } // adicione um padding na DivPai
  padding-left: 10px;
  padding-right: 10px;
`;

export const DivMensagemInteira = styled.div`
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  opacity: 1;
  transition: opacity 1s ease-in-out;
  overflow-y: none;

`;

export const BoxMensagem = styled.div`
  width: 330px;
  position: relative;

  max-height: 265px;

  overflow-y: hidden;
  border-radius: 25px;

  display: flex;
  flex-direction: column;
  justify-content: right;

  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.7);
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;


`;


export const MensagemTexto = styled.p`
  font-size: 14px;
  text-align: left;
  margin-left: 20px;
  margin-top: 0px;
  overflow-y: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
`;

export const HoraMensagem = styled.p`
  opacity: 1;
  font-size: 12px;
  font-weight: 100;
  font-style: normal;
  text-align: right;
  align-self: flex-end;
  position: absolute; // adicionado para definir posição absoluta
  bottom: 5px; // adicionado para definir a distância do fundo
  right: 15px; // adicionado para definir a distância da direita

`;


export const TextoFormatado = styled.div`
  text-align: left;
  margin-right: 10px;
  align-self: center;
  justify-content: center;

  overflow-y: hidden;
  font-family: 'Montserrat', sans-serif;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;

`;
