import styled from 'styled-components'

interface BackgroundDivProps {
  corPrimaria: string;
}


export const BackgroundDiv = styled.div`
    /* background: linear-gradient(to bottom right, #ff0000 5%, #E0E0E0 100%); */
    background: #ffffff;
    height: 120vh;
    overflow-x: hidden;

    position: relative;
    color: black;

    @media screen and (max-width: 769px){
        height: 100vh;
        overflow-x: unset;

        max-width: 100vw;
        background: linear-gradient(0deg, ${(props: BackgroundDivProps) => props.corPrimaria}50, ${(props: BackgroundDivProps) => props.corPrimaria}50), var(--ImagemFundo-Main); 
        background-size: cover;
        
      /*...*/
    }
`;

