import axios from "axios";

const instance = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? "https://a4.lineragames.com.br" : "https://a4.lineragames.com.br",
  headers: {
    Authorization: "",
  },
});

export default instance;


//https://a4.lineragames.com.br/api