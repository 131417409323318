import React, {useEffect} from 'react'
import {ContainerLogin} from './styles'
import {ImageLoginPage} from "../Login/styles";
import CardEsqueciSenha from '../../components/CardEsqueciSenha';
import {useCustomizacao} from '../../Customizacao';
import { useTranslation } from 'react-i18next';


const EsqueciSenhaPage: React.FC = () => {

  const {setExibirMenu} = useCustomizacao()

  useEffect(() => {
    setExibirMenu(false);
    return () => {
      setExibirMenu(true);
    }
  }, [])

  return (
    <>
      <ContainerLogin>
        <ImageLoginPage/>
        <CardEsqueciSenha/>
      </ContainerLogin>
    </>
  )
}

export default EsqueciSenhaPage
