import styled from 'styled-components';

interface IProps {
  corPrimaria: string;

}

interface PaiInfosProps {
  corPrimaria: string;
  nomeGrupo: any;
}
export const MineGame = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 4px;
  width: 120px;
  height: 120px;

  @media screen and (max-width: 770px){
    width: 80px;
    height: 80px;
    font-size: 12px;
  }
`;

export const MineBlock = styled.div`
  background: radial-gradient(100% 100% at 50% 0%, #006EE7 0%, #0063C7 100%);
`;

export const IframeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  
  iframe {
    width: 100%;
    height: 400px;
    display: block;
  }
`;

export const ContainerPage = styled.div<IProps>`
  max-height: calc(fit-content(100vh) - 80px);
  overflow: hidden;


  `;

export const DicaContainer = styled.div`
  @media screen and (min-width: 770px){
    
  }

`;



export const PaiInfos = styled.div<PaiInfosProps>`
display: flex;
flex-direction: column;
  #container-infos-game>div>div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    margin-left: 1px;
    
    ${({nomeGrupo}) => nomeGrupo === 'Penalty' && `
      margin-left: -2px;  
    `}
    
    @media screen and (min-width: 770px){
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr 1fr;
      margin-left: 0px;
      
    }
    
  }


  #container-infos-game>div>div h1{
    grid-column: 1/4;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FFF;
    
    

    @media screen and (min-width: 770px){
      grid-column: 1/4;
      margin-left: 10px;
    }
    
  }
  h1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FFF;
    

    @media screen and (min-width: 770px){
   
      margin-left: 10px;
    }
    
  }
  #container-infos-game>div>div>p{
    color: #FFF;
    opacity: 0.5;
    font-size: 8px;
    
  }
  
  #container-infos-game>div>div>div{
    display: flex;
    gap: 6px;
  }

  #container-infos-game>div>div>div>div{
    border: 1px solid ${(props: IProps) => props.corPrimaria};
    background: ${(props: IProps) => props.corPrimaria}40;
    box-shadow: 0px 0px 3px 1px ${(props: IProps) => props.corPrimaria};
    min-width: 100%;
    padding: 4px 8px;
    border-radius: 20px;
    color: #FFF;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    
   

    /* identical to box height */

    text-align: center; 

    @media screen and (min-width: 770px){
      padding: 6px;
      width: 100px;
    }
  }
  
  #container-infos-game>div>div>div>p{
    white-space: nowrap;
    border: unset;
    box-shadow: unset;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    
  }
  #container-infos-game>div>div>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    
  }
  #container-infos-game>div {
    display: flex;
    flex-direction: column;

    margin-top: 0px;
    
  }
  #container-infos-game>div>div>a>button {
    background: #006EE7;
    border: unset;
    padding: 4px;
    color: #FFF;
    border-radius: 4px;
    cursor: pointer;
  }
`

export const ContainerInfos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  
  
  * {
    color: #000;
    margin: 0;
  }
`;

export const Botao = styled.button`
  height: 48px;
  font-weight: bold;
  background: #006EE7;
  border: unset;
  padding: 8px;
  color: #FFF;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 50px;
  font-size: 24px;
  gap: 8px;
  @media (max-width: 769px) {
    height: 48px;
    font-size: 14px;
  
  }
`;

export const ContainerTip = styled.div`
  display: flex;
  justify-content: flex-start;
  border-radius: 8px;
  padding-bottom: 20px;
  @media screen and (max-width: 770px){
    gap: 30px;
    margin-left: 15px;
  }
  
  p {
    color: #000;
    margin: 0;
  }
  h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000;
  }
  @media screen and (min-width: 770px){
    width: 80vw;
    gap: 30px;
    align-items: left;
    justify-content: left;
    padding: 16px 0 16px 0;
  }
`;

export const ContainerLinkJogo = styled.div<IProps>`
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  p {
    width: 50%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #FFF;
    text-align: left;
  }

  @media screen and (min-width: 770px){
    width: 450px;
    margin-top: -20px;
    gap: 0px;
    justify-content: space-around;
    margin: auto;
  }
`;

export const BotaoGame = styled.button<IProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props: IProps) => props.corPrimaria};
  border-radius: 50px;
  border: 1px solid ${(props: IProps) => props.corPrimaria};
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.5);
  padding: 0px;
  gap: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  height: 36px;
  width: 145px;
  

  text-align: right;

  color: #FFFFFF;
`


