import styled from 'styled-components'

interface IProps {
  corPrimaria?: string;
  imagem?: string;
}

export const TopBar = styled.div`
  height: 80px;
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: 0px;
 


`

export const InfoTopbar = styled.div`
  margin-top: 3px;
  h1 {
    color: #FFF;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 5px rgba(0, 0, 0, 0.2);
  }
`

export const ContainerPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: auto;
  max-height: 180vh;
  gap: 16px;
  overflow-y: hidden;
  overflow-x: hidden;
  background-image: var(--BackgroundFixo);
  padding: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  padding: 10px;
  gap: 16px;
  overflow-y: hidden;
  overflow-x: hidden;
`;

export const Image = styled.div<IProps>`
  width: 68px;
  height: 68px;
  border-radius: 4px;
  background-image: url(${props => props.imagem});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  align-self: right;
`;

export const ContainerInfos = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const ContainerInfosOffline = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const ContainerDica = styled.div<IProps>`
    background: ${props => props.corPrimaria}80;
    /* Orange */
    color: #FFF;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center !important; 
    align-items: center !important;
    
    border: 1px solid ${props => props.corPrimaria};
    box-shadow: 0px 0px 5px 2px ${props => props.corPrimaria}50;
    border-radius: 25px;
    border: 1px solid var(--Primary-color);
    box-shadow: 0px 0px 5px 1px var(--Primary-color);
    border-radius: 50px;
    height: 64px;
    gap: 6px;
    padding: 10px;
    font-size: 18px;
    

    span{
      font-family: 'Montserrat';
      font-style: normal;
     
      text-align: center;
      color: #FFFFFF;
     
    }

    h1{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      color: #FFFFFF;
      margin: 0;
    }
`

export const ContainerLinkJogo = styled.div<IProps>`
  display: flex;
  flex-direction: row;
  gap: 0px;
  justify-content: space-between;
  align-items: center;

  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;

    color: #BDA192;
    width: 50%;

  }
`;

export const BotaoGame = styled.button<IProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props: IProps) => props.corPrimaria};
  border-radius: 50px;
  border: 1px solid ${(props: IProps) => props.corPrimaria};
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.5);
  padding: 0px;
  gap: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  height: 36px;
  width: 145px;
  

  text-align: right;

  color: #FFFFFF;
`


export const TextoInfoJogo = styled.p`
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;

gap: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    

    text-align: left;
    color: #FFF;
    span {
        color: var(--Primary-color);
        font-weight: 700;
        font-size: 20px;
        @media screen and (max-width: 361px){
          font-size: 19px;
          margin-right: 0px;
        }
    }
`

export const TextoDetalhes = styled.p`
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #FFF;

    span{
      font-weight: bold;
     
    }
`

export const StyledText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height */
    margin-left: -10px;
  text-align: center;

  color: #FFFFFF;
`;

export const IframeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
    
  background: #000000;
  border: 1px solid var(--Primary-color);
  border-radius: 8px;
  width: 100%;
  height: auto;

  iframe {
    width: 100%;
    height: 450px;
    object-fit: fill;
    display: block;
    border-radius: 8px;
  }
`;

export const MineGame = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 4px;
  width: 120px;
  height: 120px;

  @media screen and (max-width: 770px){
    width: 80px;
    height: 80px;
    font-size: 12px;
  }
`;

export const MineBlock = styled.div`
  background: radial-gradient(100% 100% at 50% 0%, #006EE7 0%, #0063C7 100%);
`;

export const DicaContainer = styled.div`
  @media screen and (min-width: 770px){
    
  }

`;

export const PaiInfos = styled.div<IProps>`
display: flex;
flex-direction: column;
  #container-infos-game>div>div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 1px;
    
    
    @media screen and (min-width: 770px){
      gap: 20px;
      grid-template-columns: 1fr 1fr 1fr;
      margin-left: 0px;
      
    }
    
  }

  #container-infos-game>div>div:nth-child(2) {
      /*grid do validade*/
  }

  #container-infos-game>div>div h1{
    grid-column: 1/4;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FFF;
    
    

    @media screen and (min-width: 770px){
      grid-column: 1/4;
      margin-left: 10px;
    }
    
  }
  h1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FFF;
    

    @media screen and (min-width: 770px){
   
      margin-left: 10px;
    }
    
  }
  #container-infos-game>div>div>p{
    color: #FFF;
    opacity: 0.5;
    font-size: 8px;
    
  }
  
  #container-infos-game>div>div>div{
   gap: 6px; 
  }

  #container-infos-game>div>div>div>div{
    border: 1px solid ${(props: IProps) => props.corPrimaria};
    background: ${(props: IProps) => props.corPrimaria}40;
    box-shadow: 0px 0px 3px 1px ${(props: IProps) => props.corPrimaria};
    border-radius: 20px;
    padding: 2px;
    width: 50px;
    color: #FFF;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    
    
    
    /* identical to box height */

    text-align: center; 

    @media screen and (min-width: 770px){
      padding: 6px;
      width: 100px;
    }
  }
  
  #container-infos-game>div>div>div>p{
    white-space: nowrap;
    border: unset;
    box-shadow: unset;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    
  }
  #container-infos-game>div>div>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
  }
  #container-infos-game>div {
    display: flex;
    flex-direction: column;

    margin-top: 0px;
    margin-left: -12px;
    
  }
  #container-infos-game>div>div>a>button {
    background: #006EE7;
    border: unset;
    padding: 4px;
    color: #FFF;
    border-radius: 4px;
    cursor: pointer;
  }
`

export const PaiInfosOffline = styled.div<IProps>`
display: flex;
flex-direction: column;
  #container-infos-games>div>div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 1px;
    
    
    @media screen and (min-width: 770px){
      gap: 20px;
      grid-template-columns: 1fr 1fr;
      margin-left: 0px;
      
    }
    
  }

  #container-infos-games>div>div:nth-child(2) {
      /*grid do validade*/
  }

  #container-infos-games>div>div h1{
    grid-column: 1/3;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FFF;
    
    

    @media screen and (min-width: 770px){
      grid-column: 1/3;
      margin-left: 10px;
    }
    
  }
  h1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FFF;
    

    @media screen and (min-width: 770px){
   
      margin-left: 10px;
    }
    
  }
  #container-infos-games>div>div>p{
    color: #FFF;
    opacity: 0.5;
    font-size: 8px;
    
  }
  
  #container-infos-games>div>div>div{
   gap: 6px; 
  }

  #container-infos-games>div>div>div>div{
    border: 1px solid var(--Primary-color) !important;
    background: var(--Primary-color)40 !important;
    box-shadow: 0px 0px 3px 1px var(--Primary-color) !important;
    border-radius: 20px;
    padding: 2px;
    width: 50px;
    color: #FFF;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    
    
    
    /* identical to box height */

    text-align: center; 

    @media screen and (min-width: 770px){
      padding: 6px;
      width: 100px;
    }
  }
  
  #container-infos-games>div>div>div>p{
    white-space: nowrap;
    border: unset;
    box-shadow: unset;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF90;
    
  }
  #container-infos-games>div>div>div>p{
    white-space: nowrap;
    border: unset;
    box-shadow: unset;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
  }
  #container-infos-games>div>div>div>p:nth-child(2){
    border: 1px solid var(--Primary-color);
    background: rgba(var(--Primary-color), 0.2);
    box-shadow: var(--Primary-color) 0px 0px 5px 2px;
    border-radius: 20px;
    padding: 6px;
    width: 100%;
    color: rgb(255, 255, 255);
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
  #container-infos-games>div>div>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
  }
  #container-infos-games>div {
    display: flex;
    flex-direction: column;

    margin-top: 0px;
    margin-left: -12px;
    
  }
  #container-infos-games>div>div>a>button {
    background: #006EE7;
    border: unset;
    padding: 4px;
    color: #FFF;
    border-radius: 4px;
    cursor: pointer;
  }
`

export const Botao = styled.button`
  height: 48px;
  font-weight: bold;
  background: #006EE7;
  border: unset;
  padding: 8px;
  color: #FFF;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 50px;
  font-size: 24px;
  gap: 8px;
  @media (max-width: 769px) {
    height: 48px;
    font-size: 14px;
  
  }
`;

export const ContainerTip = styled.div`
  display: flex;
  justify-content: flex-start;
  border-radius: 8px;
  padding-bottom: 20px;
  align-items: center;
  @media screen and (max-width: 770px){
    gap: 30px;
    margin-left: 15px;
  }
  
  p {
    color: #000;
    margin: 0;
  }
  h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000;
  }
  @media screen and (min-width: 770px){
    width: 80vw;
    gap: 30px;
    align-items: left;
    justify-content: left;
    padding: 16px 0 16px 0;
  }
`;

