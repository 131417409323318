import React, {useEffect, useState} from 'react'
import {BolaGreen, BolaRed, BtCadastrar, DivGreenRed, DivLeftHeader, HeaderDiv, NumResults} from './styles';
import {useNavigate, useParams} from "react-router-dom";
import './styles.css'
import {GetInfosGreenDinamico} from '../../../services/Roleta';
import {useGameContext} from '../../../context/game.context';
import LaunchIcon from '@mui/icons-material/Launch';
import {useCustomizacao} from '../../../Customizacao';
import { useTranslation } from 'react-i18next';

interface HeaderChatProps {
  gradient: string | any;
  nomeDoGrupo: string | any;

  // numGreens: any;
  // numReds: any;
}

let auxiliar = "";
let auxiliarRed = "";


const HeaderChat2: React.FC<HeaderChatProps> = ({gradient, nomeDoGrupo}) => {
  const {chatId, afiliadoId, tipoMesa} = useParams();
  const [green, setGreen] = useState();
  const [red, setRed] = useState();

  const [isMobile, setIsMobile] = useState<boolean | any>(false);

  const [grupos, setGrupos] = useState([]);
  const {grupoAtivo} = useGameContext();

  const {linkAfiliado} = useCustomizacao();
  const {t, i18n} = useTranslation('header')

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 769);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  }, [])

  const GradientStyle = {
    marginTop: !isMobile ? '120px' : '',
    height: `${tipoMesa == '0' ? '0px' : '40px'}`,
    boxShadow: `${!isMobile && 'none'}`,
    width: '100vw',
  }

  const navigate = useNavigate();

  const handleClick = () => {
    sessionStorage.removeItem('@GradientGrupo')
    sessionStorage.removeItem('@NomeDoGrupo')
    navigate(`../../../../${sessionStorage.getItem('@Subdomain')}/ListaGrupos`)
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = await GetInfosGreenDinamico(chatId);

      setGreen(data.roletas[0].green1)
      setRed(data.roletas[0].red1)

    }
    fetchData()

  }, [])

  const cadastrarClick = () => {
    window.open(linkAfiliado, '_blank')
  }

  return (
    <>
      <HeaderDiv style={GradientStyle}>

        <DivLeftHeader>

          <DivGreenRed>

            {tipoMesa == "1" ? <>
              <BolaGreen/><NumResults>
              {green}</NumResults>
              <BolaRed/><NumResults>{red}</NumResults>
            </> : <div></div>}


          </DivGreenRed>
        </DivLeftHeader>

        <BtCadastrar onClick={cadastrarClick}><LaunchIcon/>{t("Cadastre-se")}</BtCadastrar>

        {/* <Image imagem={grupoAtivo.iconUrl}/> */}


      </HeaderDiv>


    </>
  )
}

export default HeaderChat2;
