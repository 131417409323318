import styled, {keyframes} from "styled-components"

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;


const fadeOutAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const DivLoading = styled.div`

  background: black;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

`;

export const ContainerLogin = styled.div`
  display: flex;
  animation: ${fadeOutAnimation} 0.15s ease-out;
  animation: ${fadeInAnimation} 0.30s ease-in;
  @media only screen and (min-width: 770px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`


export const ContentBackground = styled.div`
  width: 100%;
  background-color: black;

`
export const PaiLogin = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  
`
export const Cardlogin = styled.div`
  height: 200px;
  background: white;
  opacity: 0.5;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.35);
  border-radius: 12px;

`

export const ImageLoginPage = styled.div`
  background-size: cover;
  background-image: var(--Background-image);
  background-position: center;
`
export const ImagemLogo = styled.img`
  @media screen and (max-width: 1023px){
    display: none;
  }
`;
