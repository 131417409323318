import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Cardlogin,
  ContadorLabel,
  ContentBackground,
  MsgErrorLabel,
  PaiInput,
  PaiLogin,
  StyledButtonEntrar,
  StyledButtonRegistrar,
  StyledInput,
  StyledLabel,
  TituloPagina
} from './styles';

interface CardRegisterProps {
  style?: React.CSSProperties; // permite a adição de propriedades de estilo
}

const Cardinput = () => {

  const [email, setEmail] = useState<string | any>('');

  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState('');
  const [btDisabled, setBtDisabled] = useState(false)
  const [errorAuth, setErrorAuth] = useState(false)

  const [contador, setContador] = useState(false)

  const [segundosRestantes, setSegundosRestantes] = useState(60);
  const [auxiliar, setAuxiliar] = useState(false);
  const {nomeCliente} = useParams();

  const [clientId, setClientId] = useState<any>()

  const emailRegex = new RegExp('@');
  const [isMobile, setMobile] = useState<boolean | null>(false);

  const {t, i18n} = useTranslation('esquecisenha')

  useEffect(() => {
    setClientId(sessionStorage.getItem('@ClientId'))
  }, [])

  const handleResize = () => {
    setMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    console.log(i18n.language)
    handleResize()
    window.addEventListener("resize", handleResize)
  }, [])



  function handleUsernameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  const handleRecuperar = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const usuario = {email};
    let clientId = sessionStorage.getItem('@ClientId')
    let Subdomain = nomeCliente


    await PostRecuperarSenha(clientId, usuario, `${Subdomain}`)
      .then((response) => {

        setBtDisabled(true)
        setContador(true)
        return true
      })
      .catch((error) => {
        setErrorMsg(error.response.data.message)


        setErrorAuth(true)

      });

  }

  interface ErrorModalProps {
    errorMessage: string;
    onClose: () => void;
  }

   
  

  
  const goToLogin = () => {
    navigate('../')
  }


  const PostRecuperarSenha = async (clientId: any, usuario: { email: any }, subdomain: any) => {
    let url = `https:///a4.lineragames.com.br/api/Otg/ForgotPassword?ClientId=${clientId}&Subdomain=${subdomain}`

    localStorage.setItem('@ClientId', clientId)
    sessionStorage.setItem('@ClientId', clientId)

    try {
      const response = await axios.post(url, usuario, {
        headers: {
          'Content-Type': 'application/json'
        }

      })
      //window.location.href = '/'
      setErrorAuth(false)

      return response.data;
    } catch (error: any) {
      setErrorAuth(true)
      console.log(error.response.data.message)
      setErrorMsg(error.response.data.message)

      return error.response.data.message
    }
  }


  useEffect(() => {
    if (btDisabled) {
      const interval = setInterval(() => {
        setBtDisabled((prevState) => !prevState);
        setContador(false);
      }, 60000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [btDisabled]);

  useEffect(() => {
    if (!errorAuth) {
      if (segundosRestantes > 0 && btDisabled) {
        const interval = setInterval(() => {
          setSegundosRestantes((prevSegundos) => prevSegundos - 1);
        }, 1000);

        return () => {
          clearInterval(interval);
          setSegundosRestantes(60);
        };
      }
    } else {
      setBtDisabled(false); // Define btDisabled como false caso ocorra um erro de autenticação
      setContador(false); // Define contador como false caso ocorra um erro de autenticação
    }
  }, [btDisabled, errorAuth]);


  return (
    <form style={{display: "flex", flexDirection: "column"}} onSubmit={handleRecuperar}>

      <StyledLabel>{t("Email da Conta")}</StyledLabel>
      <StyledInput
        type="text"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={{
          border: `${errorAuth ? "1px solid red" : 'none'}`,
        }}
      />
      {errorAuth && <MsgErrorLabel>{errorMsg}</MsgErrorLabel>}
      {(contador && !errorAuth) &&
        <ContadorLabel>{t("Email enviado com sucesso.")} <br/>{t("O email poderá ser reenviado em:")} {segundosRestantes}
        </ContadorLabel>}

      <StyledButtonEntrar id="submit" type="submit"
                          style={{
                            background: `${(btDisabled && !errorAuth) ? '#ff3c3c5b' : clientId == "16" || clientId == "32" ? 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)' : 'var(--ButtonBackground-primary)'}`,
                            borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
                            boxShadow: `${clientId == "16" || clientId == "32" && '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
                          }}
                          disabled={(btDisabled && !errorAuth)}
      >
        {t("Mandar Email de Recuperação")}
      </StyledButtonEntrar>

      <StyledButtonRegistrar style={{
          background: `${clientId == "16" || clientId == "32" ? 'black' : !isMobile ? 'var(--ButtonBackground-secundary)' : 'var(--ButtonBackground-grey)'}`,
          borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
          boxShadow: `${clientId == "16" || clientId == "32" && '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
        }} id="submit" onClick={goToLogin}>
        {t("Voltar")}
      </StyledButtonRegistrar>


    </form>

  );
}


const CardEsqueciSenha: React.FC = () => {
  const {t, i18n} = useTranslation('esquecisenha')

  return (
    <ContentBackground>
      <PaiLogin>

        <Cardlogin>

          <PaiInput>
            <div>

              <TituloPagina>
                {t("Recuperar Senha")}
              </TituloPagina>
              <Cardinput/>

            </div>

          </PaiInput>

        </Cardlogin>
      </PaiLogin>
    </ContentBackground>
  )
}

export default CardEsqueciSenha


