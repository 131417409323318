import styled from 'styled-components';

interface BackgroundDivProps {
  corPrimaria: string;
}

// export const BackgroundDiv = styled.div`
//     /* background: linear-gradient(to bottom right, #ff0000 5%, #E0E0E0 100%); */
//     // background: #E0E0E0;
//     height: 100vh;
//     max-height: 100vh;
//     overflow-x: none;
//     overflow-y: hidden;
//     max-width: 100vw;
//     background: linear-gradient(0deg, ${(props: BackgroundDivProps) => props.corPrimaria}50, ${(props: BackgroundDivProps) => props.corPrimaria}50), var(--ImagemFundo-Main);
//     background-size: cover;
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
// `;

export const BackgroundDiv = styled.div`
    /* background: linear-gradient(to bottom right, #ff0000 5%, #E0E0E0 100%); */
    background: #ffffff;
    height: 100vh;
    overflow-x: hidden;

    position: relative;
    color: black;

    @media screen and (max-width: 769px){
        overflow-x: unset;

        max-width: 100vw;
        background: linear-gradient(0deg, ${(props: BackgroundDivProps) => props.corPrimaria}50, ${(props: BackgroundDivProps) => props.corPrimaria}50), var(--ImagemFundo-Main); 
        background-size: cover;

    }
`;
