import react, { useEffect, useState } from 'react';
import { Button, ContainerMenu } from './styles';
import {Icon as Iconfy} from '@iconify/react';
import gambling from '../../../assets/gambling.svg'
import { useTabsContext } from '../../../context/tabs.context';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Menu = () => {
    const {activeTab, setActiveTab} = useTabsContext();
    const {nomeCliente} = useParams();
    const [clientId, setClientId] = useState<any>()
    const {t, i18n} = useTranslation('header')

    useEffect(()=>{
        setClientId(sessionStorage.getItem('@ClientId'))
    }, [])

    return (
        <>
            <ContainerMenu>
                <Button to={`../../../${nomeCliente}/ListaGrupos`} onClick={() => setActiveTab(0)} active={activeTab === 0} style={{
                    background: `${clientId == "16" || clientId == "32" && activeTab === 0 && 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)'}`,
                    borderRadius: `${clientId == "16" || clientId == "32" && activeTab === 0 &&'30px'}`,
                    boxShadow: `${clientId == "16" || clientId == "32" && activeTab === 0 && '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`,
                    
                  }}>
                    <img src={gambling} style={{width: '18px', height: '18px'}}/>
                    {t("Sala de Sinais")}
                </Button>
                <Button  to={`../../../${nomeCliente}/Ebook`} onClick={() => setActiveTab(1)}  active={activeTab === 1} style={{
                    background: `${clientId == "16" || clientId == "32" && activeTab === 1 && 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)'}`,
                    borderRadius: `${clientId == "16" || clientId == "32" && activeTab === 1 &&'30px'}`,
                    boxShadow: `${clientId == "16" || clientId == "32" && activeTab === 1 && '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
                  }}> 
                    <Iconfy icon="dashicons:book" color='#FFF'/>
                    E-Book
                </Button>
                <Button  to={`../../../${nomeCliente}/Perfil`} onClick={() => setActiveTab(2)}  active={activeTab === 2} style={{
                    background: `${clientId == "16" || clientId == "32" && activeTab === 2 && 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)'}`,
                    borderRadius: `${clientId == "16" || clientId == "32" && activeTab === 2 && '30px'}`,
                    boxShadow: `${clientId == "16" || clientId == "32" && activeTab === 2 && '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
                  }}>
                    <Iconfy icon="mingcute:user-3-fill" color='#FFF'/>
                    {t("Perfil")}
                </Button>
            </ContainerMenu>
        </>
    )
}

export default Menu;