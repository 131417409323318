import {Button, Modal} from '@mui/material';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {StyledButtonRegistrar} from '../CardEsqueciSenha/styles';
import {
  Cardlogin,
  ContentBackground,
  PaiInput,
  PaiLogin,
  StyledButtonEntrar,
  StyledInput,
  StyledLabel,
  TituloPagina
} from './styles';
import { useTranslation } from 'react-i18next';

interface CardRegisterProps {
  style?: React.CSSProperties; // permite a adição de propriedades de estilo
}

const Cardinput = () => {
  const {resetToken} = useParams();

  const [senha, setSenha] = useState<string>('');
  const [confirmarSenha, setConfirmarSenha] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const {t, i18n} = useTranslation('cardNovaSenha')
  const navigate = useNavigate();

  const [clientId, setClientId] = useState<any>()

  const [isMobile, setMobile] = useState<boolean | null>(false);

  useEffect(() => {
    setClientId(sessionStorage.getItem('@ClientId'))
  }, [])

  const handleResize = () => {
    setMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    console.log(i18n.language)
    handleResize()
    window.addEventListener("resize", handleResize)
  }, [])

  function handleUsernameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSenha(e.target.value);
  }

  const handleRecuperar = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (senha === '' || senha.length < 6) {
      setErrorMsg('Sua senha deve ter no mínimo 6 caracteres.');
      setShowErrorModal(true);
      return;
    }

    if (senha === confirmarSenha) {
      const usuario = {token: resetToken, newPassword: senha};
      let clientId = localStorage.getItem('@ClientId')
      await postNovaSenha(clientId, usuario);
    } else {
      setErrorMsg('As senhas devem ser iguais.');
      setShowErrorModal(true);
    }
  };

  interface ErrorModalProps {
    errorMessage: string;
    onClose: () => void;
  }

  const ErrorModal: React.FC<ErrorModalProps> = ({errorMessage, onClose}) => {
    return (
      <Modal open={true} onClose={onClose} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <div style={{backgroundColor: '#fff', maxWidth: 400, maxHeight: 300, padding: 20, borderRadius: 5}}>
          <h2 style={{color: 'red'}}>{t("Erro!")}</h2>
          <p>{errorMessage}</p>
          <Button
            onClick={onClose}
            variant="contained"
            color="primary"
            style={{
              background: '#801212',
              color: 'white',
            }}
          >
            {t("Fechar")}
          </Button>
        </div>
      </Modal>
    );
  };


  const postNovaSenha = async (clientId: any, usuario: { token: any; newPassword: string }) => {
    const url = `https://a4.lineragames.com.br/api/Otg/ResetPasswordPerfil?ClientId=${clientId}`;

    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        setErrorMsg('Token Nao encontrado');
        setShowErrorModal(true)
        return;
      }

      const response = await axios.post(url, {...usuario, token}, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log(response);

      navigate('../perfil');
      // Redirecionar para a página de login ou realizar alguma ação adicional após o sucesso

      return response;
    } catch (error: any) {

      // console.log(error);

      setErrorMsg(error);
      setShowErrorModal(true);

      console.log(error.response);

      return error;
    }
  };
  const goToLogin = () => {
    navigate('../Perfil')
  }
  return (

    <form style={{display: 'flex', flexDirection: 'column'}} onSubmit={handleRecuperar}>
      <StyledLabel>{t("Nova Senha")}</StyledLabel>
      <StyledInput
        type="password"
        placeholder="Senha"
        value={senha}
        onChange={(e) => setSenha(e.target.value)}
      />

      <StyledLabel>{t("Confirmar Senha")}</StyledLabel>
      <StyledInput
        type="password"
        placeholder="Confirmar Senha"
        value={confirmarSenha}
        onChange={(e) => setConfirmarSenha(e.target.value)}
      />

      <StyledButtonEntrar style={{
          background: `${clientId == "16" || clientId == "32" ? 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)' : 'var(--ButtonBackground-primary)'}`,
          borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
          boxShadow: `${clientId == "16" || clientId == "32" && '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
        }} id="submit" type="submit">
        {t("Alterar Senha")}
      </StyledButtonEntrar>

      {showErrorModal && <ErrorModal errorMessage={errorMsg} onClose={() => setShowErrorModal(false)}/>}


      <StyledButtonRegistrar style={{
          background: `${clientId == "16" || clientId == "32" ? 'black' : !isMobile ? 'var(--ButtonBackground-secundary)' : 'var(--ButtonBackground-grey)'}`,
          borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
          boxShadow: `${clientId == "16" || clientId == "32" && '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
        }} id="submit" onClick={goToLogin}>
        {t("Voltar")}
      </StyledButtonRegistrar>
    </form>
  );
};

const CardNovaSenhaLogado: React.FC = () => {
  const {t, i18n} = useTranslation('cardNovaSenha')
  
  return (
    <ContentBackground>
      <PaiLogin>
        <Cardlogin>
          <PaiInput>
            <div>
              <TituloPagina>{t("Alterar Senha")}</TituloPagina>
              <Cardinput/>
            </div>
          </PaiInput>
        </Cardlogin>
      </PaiLogin>
    </ContentBackground>
  );
};

export default CardNovaSenhaLogado;
