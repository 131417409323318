import React, { useEffect, useState } from 'react';
import {
    BotaoGame,
    Container,
    ContainerDica,
    ContainerInfos,
    ContainerInfosOffline,
    ContainerLinkJogo,
    ContainerPage,
    ContainerTip,
    DicaContainer,
    IframeContainer,
    InfoTopbar,
    MineBlock,
    MineGame,
    PaiInfos,
    PaiInfosOffline,
    StyledText,
    TextoDetalhes,
    TextoInfoJogo,
    TopBar
} from "./styles";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import axios from 'axios';
import AdjustIcon from '@mui/icons-material/Adjust';
import { animateScroll as scroll } from 'react-scroll';
import { useCustomizacao } from '../../Customizacao';
import { useGameContext } from '../../context/game.context';
import { useParams } from 'react-router-dom';

//...
const ExtensionPage: React.FC = () => {
    const { cores, logoUrl, iframeBool } = useCustomizacao();
    const { setExibirDetalhes } = useGameContext();
    const { activeGame } = useGameContext();
    const { jogadaId, subdominio, tipoMesa } = useParams<any>();

    const [actualDetails, setActualDetails] = useState<any>(null);
    const [nomeRoleta, setFinalNomeRoleta] = React.useState<string | null>(null);
    const [estrategiaDoPadrao, setEstrategiaDoPadrao] = React.useState<string | null>(null);
    const [estrategia, setEstrategia] = React.useState<string | null>(null);
    const [sequencia, setSequencia] = React.useState<string | null>(null);
    const [linkMobile, setLinkMobile] = useState<any>('')

    const [cobrir, setCobrir] = useState<any>()
    const [nomeAmigavel, setNomeAmigavel] = useState<any>()

    const [texto, setTexto] = React.useState<string | null>(null);
    const [resultado, setResultado] = useState<any>();

    const [controleTexto, setControleTexto] = useState<any>(false);
    const [clientId, setClientId] = useState<any>()
    const [isDesktop, setIsDesktop] = useState<any>()

    const handleResize = () => {
        setIsDesktop(window.innerWidth >= 769);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

    }, [])

    useEffect(() => {
        setClientId(sessionStorage.getItem('@ClientId'))
    }, [])

    useEffect(() => {

    }, [])


    useEffect(() => {
        scroll.scrollToTop();
    }, []);

    useEffect(() => {

        if (texto?.includes("GREEN") || texto?.includes("RED")) {

            const regexResultado = /\[(.*?)\]/;
            const matchResultado = regexResultado.exec(texto)
            const resultadoFinal = matchResultado ? matchResultado[1] : null
            // console.log(matchResultado, 'matchResultado')
            setResultado(resultadoFinal)

        }

    }, [texto])

    const { setarCores } = useCustomizacao();

    const fisrtFetch = async () => {
        await axios.get(`https://a4.lineragames.com.br/GetDadosCompletos?jogadaId=${jogadaId}&subDominio=${subdominio}`)
            .then(r => {
                console.log({ data: r.data.whiteLabel })
                setarCores(0, { data: r.data.whiteLabel });
                const nomeRoleta = /<b>Roleta<\/b>: ([^\n]+)/;
                const matchNomeRoleta = nomeRoleta.exec(r.data.jogada[0].texto);
                const finalNomeRoleta = matchNomeRoleta ? matchNomeRoleta[1] : null;

                const regexDoPadrao = /<b>Padrão<\/b>: ([^\n]+)/;
                const matchDoPadrao = regexDoPadrao.exec(r.data.jogada[0].texto);
                const finalestrategiaDoPadrao = matchDoPadrao ? matchDoPadrao[1] : null;

                const regexEstrategia = /<b>Estratégia<\/b>: ([^\n]+)/;
                const matchEstrategia = regexEstrategia.exec(r.data.jogada[0].texto);
                const finalestrategia = matchEstrategia ? matchEstrategia[1] : null;

                const regexPadrao = /\[(.*?)\]<\/b>/;
                const matchPadrao = regexPadrao.exec(r.data.jogada[0].texto)
                const finalSequencia = matchPadrao ? matchPadrao[1] : null;

                const regexCobrir = /🟢\s(.+?)\s🟢/;
                const matchCobrir = regexCobrir.exec(r.data.jogada[0].texto)
                const valorCobrir = matchCobrir ? matchCobrir[1] : null

                //...


                setFinalNomeRoleta(finalNomeRoleta);
                setEstrategiaDoPadrao(finalestrategiaDoPadrao);
                setEstrategia(finalestrategia);
                setSequencia(finalSequencia);
                setTexto(r.data.jogada[0].texto);
                setNomeAmigavel(r.data.jogada[0].nomeRoletaAmigavel)

                if (r.data.jogada[0].texto.includes("GREEN") || r.data.jogada[0].texto.includes("RED")) {
                    setControleTexto(true)
                    setTexto(r.data.jogada[0].texto)
                }

                // console.log(texto)





                if (valorCobrir) {
                    setCobrir(valorCobrir.replace(/<b>|<\/b>/g, ""))
                }
                if (!r.data.jogada.length)
                    setTexto("Cancelada");
                else {
                    //console.log(r.data[0].texto);
                    setTexto(r.data.jogada[0].texto);
                }
            })
    }

    useEffect(() => {
        fisrtFetch()
    }, []);

    const fetchData = async () => {
        await axios.get(`https://a4.lineragames.com.br/GetDadosCompletos?jogadaId=${jogadaId}&subDominio=${subdominio}`)
        .then(r => {
            if (!r.data.jogada.length)
                setTexto("Cancelada");
            else {
                //console.log(r.data[0].texto);
                setTexto(r.data.jogada[0].texto);
            }
        })
    }

    useEffect(() => {
        fetchData()
        const interval = setInterval(() => {
            fetchData();

        }, 3000);

        return () => clearInterval(interval);
    }, [])

    //lalalal
    const createMarkup = () => {
        return {
            __html: texto || ""
                .split('\\r\\n\\r\\n')[0].split(`<p>🖥️</p>`)[0]
        };
    };

    const createMarkup2 = () => {
        const textoFinal: string = texto || "";

        // Remove a primeira palavra do primeiro h1 e adiciona a cor preta
        const textoFormatado = textoFinal.replace(/(<h1>)(.*?)(<\/h1>)/i, '');


        // Adicionar estilo de largura à <div> com o valor da hora após o parágrafo "Validade"
        const divComValidadeIndex = textoFinal.indexOf('<p>🕜 Validade</p>');
        const divComValidadeCloseIndex = textoFinal.indexOf('</div>', divComValidadeIndex);
        const divComValidade = textoFinal.substring(divComValidadeIndex, divComValidadeCloseIndex + 6);

        const horaIndex = divComValidade.indexOf('<div>');
        const horaCloseIndex = divComValidade.indexOf('</div>', horaIndex);
        const divHora = divComValidade.substring(horaIndex, horaCloseIndex + 6);

        const divHoraComEstilo = divHora.replace('<div>', '<div style="width: 70px;">');
        const divComValidadeComEstilo = divComValidade.replace(divHora, divHoraComEstilo);
        const textoFormatadoComEstilo = textoFormatado.replace(divComValidade, divComValidadeComEstilo);



        return {
            __html: textoFormatadoComEstilo
                .split('\\r\\n\\r\\n')[0]
                .split('\\r\\n\\r\\n')[0].split(`<p>🖥️</p>`)[0].replace(/[\uD800-\uDFFF]./g, '')
        };
    }

    if (!texto)
        return <>CARREGANDO...</>

    if (tipoMesa === '1')
        return (
            <>
                <ContainerPage>
                    <Container style={{ marginTop: '0px' }}>
                        <ContainerInfos>
                            <div style={{}}>

                                <TextoInfoJogo>
                                    <div style={{
                                        position: 'absolute', top: '115px', left: '12px',
                                        display: `${(texto === 'Cancelada' || texto.includes("GREEN") || texto.includes("RED")) && 'none'}`
                                    }}>
                                    </div>

                                    <AdjustIcon style={{
                                        fill: 'var(--Primary-color)',
                                        fontWeight: 'bold',
                                        fontSize: '28px',
                                        opacity: `${texto.includes("GREEN") || texto.includes("RED") || texto.includes("Cancelada") ? '1' : '0'}`
                                    }} />

                                    {texto === 'Cancelada' ?
                                        <StyledText>Jogada <span>Cancelada</span></StyledText> :
                                        texto.includes("GREEN") ?
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                <StyledText
                                                    style={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Resultado
                                                    : &nbsp;
                                                    <span style={{ color: 'green' }}> GREEN </span>
                                                </StyledText></div> :
                                            texto.includes("RED") ?
                                                <div
                                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    <StyledText
                                                        style={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Resultado
                                                        : &nbsp;
                                                        <span style={{ color: 'red' }}>  RED</span> </StyledText></div> :
                                                texto.includes('ANALISANDO') ?
                                                    <StyledText>Analisando</StyledText> :
                                                    <StyledText style={{ color: 'white' }}>Entrada <span
                                                        style={{ color: 'var(--Primary-color)' }}>Confirmada</span>
                                                    </StyledText>}




                                </TextoInfoJogo>
                            </div>

                            <InfoTopbar>
                                <h1 style={{ marginLeft: '38px' }}>{nomeAmigavel}</h1>
                            </InfoTopbar>
                            <TextoDetalhes style={{ marginLeft: '38px' }}>

                                <span>{texto.includes("GREEN") || texto.includes("RED") ? `` : 'Padrão: '} </span> {texto.includes("GREEN") || texto.includes("RED") ? `[${resultado}]` : estrategiaDoPadrao}
                            </TextoDetalhes>

                            <TextoDetalhes><span
                                style={{ marginLeft: '38px' }}>{!(texto.includes("GREEN") || texto.includes("RED")) && `[${sequencia}]`}</span></TextoDetalhes>
                        </ContainerInfos>
                        <ContainerDica corPrimaria={cores.primary_color} onClick={() => {
                            if (texto.includes("GREEN") || texto.includes("RED") || texto === 'Cancelada') {
                                setExibirDetalhes(false)
                            }
                        }}>
                            {texto.includes("GREEN") || texto.includes("RED") || texto === 'Cancelada' ?

                                <h1>
                                    Volte para a Tela de Sinais
                                </h1>

                                : texto.includes("ANALISANDO") ?
                                    <h1>Aguardando análise</h1>
                                    :
                                    <>
                                        {estrategia}
                                        <h1>{cobrir}</h1>
                                    </>
                            }
                        </ContainerDica>


                    </Container>
                </ContainerPage>

            </>
        );

    if (tipoMesa === '0') {
        return (
            <ContainerPage>
                <ContainerTip>
                    <DicaContainer>
                        <MineGame>
                            {[...texto.split('\\r\\n\\r\\n')[1]].filter(char => char === '⭐' || char === '🟦' || char === '🦴' || char === '🍗' || char === '_' || char === '|' || char === '🟢' || char === '⚽' || char === '🧍').map((block, index) => {

                                if (block === '_' || block === '|') {
                                    return <MineBlock style={{ background: '#000' }}>
                                        &nbsp;
                                    </MineBlock>
                                }

                                if (block !== '🟦') {
                                    return <div key={index} style={{ width: `${(block == '⭐' && '15px')}` }}>
                                        {block}
                                    </div>
                                }

                                return <div key={index} style={{ width: `${(block == '🟦') && '15px'}` }}>
                                    <MineBlock>&nbsp;</MineBlock>
                                </div>
                            })}
                        </MineGame>
                    </DicaContainer>

                    <PaiInfosOffline>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            margin: '0',
                            padding: '0',
                            marginLeft: '-10px',
                            marginTop: '0px',
                            marginBottom: '4px'

                        }}>
                            <AdjustIcon style={{
                                fill: 'var(--Primary-color)',
                                fontWeight: 'bold',
                                fontSize: '24px',
                                opacity: '1',
                                marginTop: '0px',
                                marginRight: '2px',
                                paddingLeft: '0px'
                            }} />
                            <h1 style={{ color: 'white', fontSize: '16px', fontWeight: 'bold', fontFamily: 'Montserrat', lineHeight: '20px' }}>Entrada <span style={{ color: 'var(--Primary-color)' }}> Confirmada</span></h1>

                        </div>

                        <ContainerInfosOffline id="container-infos-games" dangerouslySetInnerHTML={createMarkup2()} />
                    </PaiInfosOffline>
                </ContainerTip>

            </ContainerPage>
        )
    }

    return (
        <>

        </>
    )
};

export default ExtensionPage;
