import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Header from '../../components/Header';
import {useAuth} from '../../context/auth.context';
import {
  BodyMobile,
  BtPrimary,
  ContainerInput,
  ContainerUsuario,
  DivBts,
  DivColumns,
  DivNecessaria,
  DivPai,
  HeaderLocal,
  ImgPerfil,
  SpanAlterarSenha,
  StyledInput,
  StyledLabel,
  SubTitulos,
  TituloPagina,
} from './styles';
import {useCustomizacao} from '../../Customizacao'
import { useTranslation } from 'react-i18next';;

const CardUsuario: React.FC = () => {
  const navigate = useNavigate();
  const [isDesktop, setIsDesktop] = useState(false);
  const {name, email, celular} = useAuth();
  const {linkSuporte} = useCustomizacao();
  const {cores} = useCustomizacao();
  const {t, i18n} = useTranslation('perfil')

  const [clientId, setClientId] = useState<any>()
  useEffect(()=>{
    setClientId(sessionStorage.getItem('@ClientId'))
  }, []) 

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(true);

  }
  const handleClickNotShowPassword = () => {
    setShowPassword(false);

  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };


  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 769);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const signOut = () => {
    sessionStorage.getItem('token');
    sessionStorage.removeItem('token');
    navigate(`/${sessionStorage.getItem('@Subdomain')}`)
  };

  const handleAjudaClick = () => {
    window.location.href = `${linkSuporte}`
  };

  return (
    <>
      <Header/>

      {isDesktop ? (
        <DivPai style={{width: '100%'}}>
          <ContainerUsuario style={{width: '90%', padding: '0 0px'}}>


            <HeaderLocal>
              <TituloPagina style={{
                color: 'var(--Secondary-Text-color)'
              }}>
                
                {t("Meu")} <span style={{color: 'var(--Primary-color)'}}>{t("Perfil")}</span> 
              </TituloPagina>

              <DivBts>
                <BtPrimary onClick={handleAjudaClick} style={{
                background: `${clientId == "16" || clientId == "32" ? 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)' : 'var(--ButtonBackground-primary)'}`,
                borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
                boxShadow: `${clientId == "16" || clientId == "32" && '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`,
                width: '112px'
              }}>
                  <HelpOutlineIcon/> {t("Ajuda")}
                </BtPrimary>
                <BtPrimary onClick={signOut} style={{
                background: `${clientId == "16" || clientId == "32" ? 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)' : 'var(--ButtonBackground-primary)'}`,
                borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
                boxShadow: `${clientId == "16" || clientId == "32" && '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`,
                width: '112px'
              }}>
                  <LogoutIcon/> {t("Sair")}
                </BtPrimary>
              </DivBts>
            </HeaderLocal>

            <DivNecessaria>
              <DivColumns style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <SubTitulos>{t("Foto do Perfil")}</SubTitulos>
                <ImgPerfil style={{background: 'grey'}}>{name.slice(0, 1)}</ImgPerfil>
              </DivColumns>

              <DivColumns
                style={{
                  width: '80%',
                  paddingLeft: '20px',
                }}
              >
                <SubTitulos>{t("Informação geral")}</SubTitulos>
                <StyledLabel>{t("Nome")}</StyledLabel>
                <StyledInput
                  value={name}
                  readOnly
                  style={{}}
                />
                <StyledLabel>{t("Telefone")}</StyledLabel>
                <StyledInput
                  value={celular}
                  readOnly
                  style={{}}
                />

              </DivColumns>

              <DivColumns
                style={{
                  width: '80%',
                  marginLeft: '20px',
                }}
              >
                <SubTitulos style={{opacity: 0}}>{t("Informação geral")}</SubTitulos>
                <StyledLabel>Email</StyledLabel>
                <StyledInput
                  value={email}
                  readOnly
                  style={{
                    border: '5px solid rgba(0, 0, 0, 0.2);',

                  }}
                />

                <Link
                  to="../resetpassword"
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <SpanAlterarSenha style={{color: "#BDA192"}}>{t("Altere sua senha")}</SpanAlterarSenha>
                </Link>
              </DivColumns>
            </DivNecessaria>
          </ContainerUsuario>
        </DivPai>
      ) : (
        <>
          {/* MOBILE */}
          <BodyMobile>
            <div style={{height: '67px', position: 'fixed', zIndex: '1000', background: '#000', top: '0', left: '0', width: '100%'}} />
            <TituloPagina
              style={{
                width: '100%',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                color: 'var(--Secondary-Text-color)',
                marginBottom: '16px'
              }}
            >
              {t("Meu")} <span style={{
              color: `var(--Primary-color)`,
              textTransform: 'uppercase',
              fontSize: '28px',
              fontWeight: 'bold'
            }}>{t("Perfil")}</span>
            </TituloPagina>

            <ImgPerfil style={{
              background: 'grey',
            }}>{name.slice(0, 1)}</ImgPerfil>

            <SubTitulos
              style={{
                width: '100%',
                textAlign: 'left',
                marginTop: '20px',
                marginBottom: '10px'
              }}
            >
              {t("Informação Geral")}
            </SubTitulos>

            <div style={{display: 'flex', flexDirection: 'column', gap: '20px', width: '100%'}}>
              <ContainerInput>
                <StyledLabel>{t("Nome")}</StyledLabel>
                <StyledInput
                  value={name}
                  readOnly
                  style={{}}
                />
              </ContainerInput>
              <ContainerInput>
                <StyledLabel>Email</StyledLabel>
                <StyledInput
                  value={email}
                  readOnly
                  style={{}}
                />
              </ContainerInput>
              <ContainerInput>
                <StyledLabel>{t("Telefone")}</StyledLabel>
                <StyledInput
                  value={celular}
                  readOnly
                  style={{}}
                />
              </ContainerInput>

            </div>


            <br/>


            <Link
              to="../resetpassword"
              style={{
                width: '100%',
              }}
            >
              <SpanAlterarSenha style={{
                color: '#b9b9b9',
                textDecoration: 'underline',
                textAlign: 'left',
                alignSelf: 'left',
                justifyContent: 'left'
              }}>{t("Altere sua senha")}</SpanAlterarSenha>
            </Link>


            <div style={{width: '100%', paddingTop: '20px', gap: '10px', display: 'flex', flexDirection: 'column',}}>
              <BtPrimary onClick={handleAjudaClick} style={{
                background: `${clientId == "16" || clientId == "32" ? 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)' : 'var(--ButtonBackground-primary)'}`,
                borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
                boxShadow: `${clientId == "16" || clientId == "32" && '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
              }}>
                <HelpOutlineIcon/>
                {t("Ajuda")}
              </BtPrimary>

              <BtPrimary onClick={signOut} style={{
                background: `${clientId == "16" || clientId == "32" ? 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)' : 'var(--ButtonBackground-primary)'}`,
                borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
                boxShadow: `${clientId == "16" || clientId == "32" && '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
              }}>
                <LogoutIcon/>
                {t("Sair")}
              </BtPrimary>
              <div style={{
                height: '50px'
              }}></div>

            </div>

          </BodyMobile>
        </>
      )}
    </>
  );
};

export default CardUsuario;
