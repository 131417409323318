import React, { useEffect, useState } from 'react';
import { Cardlogin, ContainerInput, ContentBackground, MsgErrorLabel, PaiInput, PaiLogin, StyledButtonEntrar, StyledButtonRegistrar, StyledForm, StyledInput, StyledLabel, TelInput } from './styles';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from '@mui/material';
import axios from 'axios';
import { TituloPagina } from '../CardLogin/styles';
import { useCustomizacao } from '../../Customizacao';
import { useTranslation } from 'react-i18next';

interface CardRegisterProps {
  style?: React.CSSProperties; // permite a adição de propriedades de estilo
}

const Cardinput = () => {
  const [formData, setFormData] = useState({
    email: '',
    confirmarEmail: '',
    senha: '',
    confirmarSenha: '',
    telefone: '',
    nome: '',
  });
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [errorAuth, setErrorAuth] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | any>('');
  const [clientId, setClientId] = useState<any>();

  const { t, i18n } = useTranslation('register');

  useEffect(() => {
    setClientId(sessionStorage.getItem('@ClientId'));
  }, []);

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  const handleRegistro = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, confirmarEmail, senha, confirmarSenha, telefone, nome } = formData;
    if (email === confirmarEmail) {
      if (senha === confirmarSenha) {
        if (!(telefone.length < 15)) {
          let clientId = localStorage.getItem('@ClientId');
          await PostRegister(clientId, { email, nome, senha, telefone }).then(() => {
            // Sucesso
          }).catch((error) => {
            setErrorMsg("Atualize a página e tente novamente.");
            setErrorAuth(true);
          });
        } else {
          setErrorMsg("Número de telefone incompleto.");
          setErrorAuth(true);
        }
      } else {
        setErrorMsg("As senhas devem ser iguais.");
        setErrorAuth(true);
      }
    } else {
      setErrorMsg("Os e-mails devem ser iguais.");
      setErrorAuth(true);
    }
  };

  const PostRegister = async (clientId: any, usuario: { email: any, nome: any, senha: any, telefone: any }) => {
    let url = `https://a4.lineragames.com.br/api/Otg/RegistrarUsuário?ClientId=${clientId}`;

    try {
      const response = await axios.post(url, usuario, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      window.location.href = './';
      return response.data;
    } catch (error: any) {
      setErrorAuth(true);
      setErrorMsg(error.response.data.message);
      return error.response.data.message;
    }
  };

  interface ErrorModalProps {
    errorMessage: string;
    onClose: () => void;
  }

  const ErrorModal: React.FC<ErrorModalProps> = ({ errorMessage, onClose }) => {
    return (
      <Modal open={true} onClose={onClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ backgroundColor: '#fff', maxWidth: 400, maxHeight: 300, padding: 20, borderRadius: 5 }}>
          <h2 style={{ color: 'red' }}>Erro!</h2>
          <p>{t(errorMessage)}</p>
          <Button onClick={onClose} variant="contained" color="primary" style={{ background: '#801212', color: 'white' }}>Fechar</Button>
        </div>
      </Modal>
    );
  };

  const goToLogin = (form: any) => {
    form.preventDefault();
    navigate('../');
  };

  //de off para new-password

  return (
    <StyledForm style={{ display: "flex", flexDirection: "column", height: '100%', padding: '0 20px 20px 20px', gap: '20px' }} onSubmit={handleRegistro}>
      <TituloPagina>
        {t("Criar Conta")}
      </TituloPagina>
      <ContainerInput>
        <StyledLabel>{t("Nome")}</StyledLabel>
        <StyledInput
          type="text"
          placeholder={t("Nome")}
          value={formData.nome}
          onChange={handleInputChange}
          name="nome"
          autoComplete='off'
          style={{
            border: `${errorAuth ? "1px solid red" : 'none'}`,
          }}
        />
      </ContainerInput>

      <ContainerInput>
        <StyledLabel>E-mail</StyledLabel>
        <StyledInput
          type="text"
          placeholder={t("Seu melhor e-mail")}
          value={formData.email}
          onChange={handleInputChange}
          name="email"
          autoComplete='off'
          style={{
            border: `${errorAuth ? "1px solid red" : 'none'}`,
          }}
        />
      </ContainerInput>


      <ContainerInput>
        <StyledLabel>{t("Confirmar E-mail")}</StyledLabel>
        <StyledInput
          type="text"
          placeholder={t("Confirmar e-mail")}
          value={formData.confirmarEmail}
          onChange={handleInputChange}
          name="confirmarEmail"
          autoComplete='off'
          style={{
            border: `${errorAuth ? "1px solid red" : 'none'}`,
          }}
        />
      </ContainerInput>


      <ContainerInput>
        <StyledLabel>{t("Celular")}</StyledLabel>
        <TelInput
          mask="(99) 99999-9999"
          type="text"
          placeholder={t("Celular")}
          autoComplete='off'
          value={formData.telefone}
          onChange={handleInputChange}
          name="telefone"
          style={{
            border: `${errorAuth ? "1px solid red" : "none"}`,
          }}
        />
      </ContainerInput>


      <ContainerInput>
        <StyledLabel>{t("Senha")}</StyledLabel>
        <StyledInput
          type="password"
          placeholder={t("Senha")}
          value={formData.senha}
          onChange={handleInputChange}
          name="senha"
          style={{
            border: `${errorAuth ? "1px solid red" : 'none'}`,
          }}
        />
      </ContainerInput>

      <ContainerInput>
        <StyledLabel>{t("Confirmar Senha")}</StyledLabel>
        <StyledInput
          type="password"
          placeholder={t("Confirmar Senha")}
          value={formData.confirmarSenha}
          onChange={handleInputChange}
          name="confirmarSenha"
          autoComplete='off'
          style={{
            border: `${errorAuth ? "1px solid red" : 'none'}`,
          }}
        />
      </ContainerInput>


      {errorAuth && <MsgErrorLabel>{t(errorMsg)}</MsgErrorLabel>}

      <StyledButtonEntrar id="submit" type="submit" className="login-button" style={{
        background: `${clientId == "16" || clientId == "32" ? 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)' : 'var(--ButtonBackground-primary)'}`,
        borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
        boxShadow: `${clientId == "16" || clientId == "32" && '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
      }}>
        {t("Cadastrar")}
      </StyledButtonEntrar>

      <StyledButtonRegistrar onClick={goToLogin}
        style={{
          background: `${clientId == "16" || clientId == "32" && 'black'}`,
          borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
          boxShadow: `${clientId == "16" || clientId == "32" && '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
        }}>
        {t("Já tenho conta")}
      </StyledButtonRegistrar>
    </StyledForm>
  );
}

const Register: React.FC = () => {
  const [isDesktop, setIsDesktop] = useState(true);
  const { t, i18n } = useTranslation('register');

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1023);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
  }, []);

  const { logoUrlLogin, imgBackgroundRegister } = useCustomizacao();

  return (
    <ContentBackground style={{
      backgroundImage: `${!isDesktop && imgBackgroundRegister}`,
    }}>
      <PaiLogin>
        <Cardlogin>
          <PaiInput style={{
            marginTop: `${isDesktop ? '100px' : '-70px'}`,
          }}>
            {!isDesktop && (
              <>
                <img src={logoUrlLogin} style={{
                  width: '70%',
                  maxWidth: '300px',
                  marginTop: `${!isDesktop && '80px'}`,
                }} />
              </>
            )}
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Cardinput />
            </div>
          </PaiInput>
        </Cardlogin>
      </PaiLogin>
    </ContentBackground>
  )
}

export default Register;