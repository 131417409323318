import React, {useEffect, useState} from 'react';
import CardEbook from '../../components/CardEbook';
import SideBar from '../../components/sidebar/menu';
import {BackgroundDiv} from './styles';
import {useCustomizacao} from '../../Customizacao';
import Header from '../../components/Header';


const Ebookpage: React.FC = () => {
  const [isMobile, setIsMobile] = useState(true);
  const {cores} = useCustomizacao();
  const [clientId, setClientId] = useState<any>()
  const {fundoPuro} = useCustomizacao();
  useEffect(() => {
    setClientId(sessionStorage.getItem('@ClientId'))
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 769);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <>
      <BackgroundDiv corPrimaria={fundoPuro != "1" && cores.primary_color} style={{
        background: `${cores.backgroundFixoBool === '1' ? 'var(--BackgroundFixo)' : fundoPuro == "1" ? 'var(--ImagemFundo-Main)' : `linear-gradient(0deg, ${cores.primary_color}50, ${cores.primary_color}50), var(--ImagemFundo-Main)`}`,
        backgroundSize: `${clientId == '16' || cores.backgroundFixoBool === '1' && 'cover'}`,
        backgroundRepeat: `${clientId == '16' && 'no-repeat'}`,

      }}>

        {!isMobile && <Header/>}
        <CardEbook/>

      </BackgroundDiv>


    </>
  )
}

export default Ebookpage
