import React, { useEffect, useState } from 'react';
import {
  BotaoGame,
  Container,
  ContainerDica,
  ContainerInfos,
  ContainerLinkJogo,
  ContainerPage,
  IframeContainer,
  InfoTopbar,
  StyledText,
  TextoDetalhes,
  TextoInfoJogo,
  TopBar
} from "./styles";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useCustomizacao } from '../../../Customizacao';
import { useGameContext } from '../../../context/game.context';
import axios from 'axios';
import HeaderChatDetails from '../headerChatDetails';
import AdjustIcon from '@mui/icons-material/Adjust';
import Loading from '../../Loading';
import { animateScroll as scroll } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { GetMensagensOffline } from '../../../services/Roleta';
import { useParams } from 'react-router-dom';
import { LoadingContainer } from '../styles';
import { HexToFilter } from '../../../utils/hextofilter';
import * as animationData from '../Loading.json'
import Header from '../../Header';
//.......
const AviatorPage: React.FC = () => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const { cores, logoUrl, iframeBool } = useCustomizacao();
  const { setExibirDetalhes, actualDetails } = useGameContext();
  const { activeGame, getUltimosDados } = useGameContext();
  //.....
  const [nomeRoleta, setFinalNomeRoleta] = React.useState<string | null>(null);
  const [estrategiaDoPadrao, setEstrategiaDoPadrao] = React.useState<string | null>(null);
  const [estrategia, setEstrategia] = React.useState<string | null>(null);
  const [sequencia, setSequencia] = React.useState<string | null>(null);
  const [linkMobile, setLinkMobile] = useState<any>('')

  const [cobrir, setCobrir] = useState<any>()
  const [nomeAmigavel, setNomeAmigavel] = useState<any>()

  const [texto, setTexto] = React.useState<string>("");
  const [resultado, setResultado] = useState<any>();

  const [controleTexto, setControleTexto] = useState<any>(false);
  const [clientId, setClientId] = useState<any>()
  const [isDesktop, setIsDesktop] = useState<any>()
  const { chatId, nomeGrupo } = useParams();

  const [analisandoArtificial, setAnalisandoArtificial] = useState<any>(false)

  const [updatedResponse, setUpdatedResponse] = useState<any>()

  const { t, i18n } = useTranslation('detailsPage')

  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 769);
  };

  useEffect(() => {
    let response: any[] = [];
    const fetchMensagens = async () => {
      response = await GetMensagensOffline(chatId);
      console.log(response[response.length - 1])
      setUpdatedResponse(response[response.length - 1])

      return response[0]
    }

    fetchMensagens()
  }, [])



  useEffect(() => {
    let response: any[] = [];

    const interval = setInterval(() => {


      const fetchMensagens = async () => {
        response = await GetMensagensOffline(chatId);
        console.log(chatId)
        console.log(response)


        setUpdatedResponse(response[response.length - 1])


        return response[0]
      }

      fetchMensagens()

    }, 5000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (updatedResponse) {
      if (updatedResponse["texto"].includes("GREEN") || updatedResponse["texto"].includes("RED")) {
        const interval = setInterval(() => {
          setAnalisandoArtificial(true)
        }, 5000)

        return () => {
          clearInterval(interval)
        }
      } else {
        setAnalisandoArtificial(false)
      }
    }


  }, [updatedResponse])


  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

  }, [])

  useEffect(() => {
    setClientId(sessionStorage.getItem('@ClientId'))
  }, [])


  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  useEffect(() => {
    console.log(updatedResponse)
    if (updatedResponse) {
      const nomeRoleta = /<b>Roleta<\/b>: ([^\n]+)/;
      const matchNomeRoleta = nomeRoleta.exec(updatedResponse["texto"]);
      const finalNomeRoleta = matchNomeRoleta ? matchNomeRoleta[1] : null;

      const regexDoPadrao = /<b>Padrão<\/b>: ([^\n]+)/;
      const matchDoPadrao = regexDoPadrao.exec(updatedResponse["texto"]);
      const finalestrategiaDoPadrao = matchDoPadrao ? matchDoPadrao[1] : null;

      const regexEstrategia = /<b>Estratégia<\/b>: ([^\n]+)/;
      const matchEstrategia = regexEstrategia.exec(updatedResponse["texto"]);
      const finalestrategia = matchEstrategia ? matchEstrategia[1] : null;

      const regexPadrao = /\[(.*?)\]<\/b>/;
      const matchPadrao = regexPadrao.exec(updatedResponse["texto"])
      const finalSequencia = matchPadrao ? matchPadrao[1] : null;

      const regexCobrir = /🟢\s(.+?)\s🟢/;
      const matchCobrir = regexCobrir.exec(updatedResponse["texto"])
      const valorCobrir = matchCobrir ? matchCobrir[1] : null

      //...


      setFinalNomeRoleta(finalNomeRoleta);
      setEstrategiaDoPadrao(finalestrategiaDoPadrao);
      setEstrategia(finalestrategia);
      setSequencia(finalSequencia);
      setTexto(updatedResponse["texto"]);
      setNomeAmigavel(nomeGrupo)

      if (texto.includes("GREEN") || texto.includes("RED")) {
        setControleTexto(true)
      }

      // console.log(texto)





      if (valorCobrir) {
        setCobrir(valorCobrir.replace(/<b>|<\/b>/g, ""))
      }

      const regexLinkMobile = /<a href="([^"]+)">Celular<\/a>/;
      const matchLinkMobile = regexLinkMobile.exec(updatedResponse["texto"])
      const linkFinal = matchLinkMobile ? matchLinkMobile[1] : null
      setLinkMobile(linkFinal)
    }



  }, [updatedResponse])

  useEffect(() => {

    if (texto.includes("GREEN") || texto.includes("RED")) {

      const regexResultado = /\[(.*?)\]/;
      const matchResultado = regexResultado.exec(texto)
      const resultadoFinal = matchResultado ? matchResultado[1] : null
      // console.log(matchResultado, 'matchResultado')
      setResultado(resultadoFinal)

    }

  }, [texto])





  //lalalal
  const createMarkup = () => {
    return {
      __html: activeGame.texto
        .split('\\r\\n\\r\\n')[0].split(`<p>🖥️</p>`)[0]
    };
  };


  const fetchData = async () => {

    await axios.get(`https://a4.lineragames.com.br/api/Otg/GetJogadaId?jogadaId=${updatedResponse["jogadaId"]}`)
      .then(response => {
        if (!response.data.length)
          setTexto("Cancelada");
        else {
          console.log(updatedResponse["jogadaId"])
          console.log(response.data[0])
          //console.log(r.data[0].texto);
          //setTexto(r.data[0].texto);
        }
      })
  }

  useEffect(() => {
    fetchData()
    const interval = setInterval(() => {
      fetchData();

    }, 4000);

    return () => clearInterval(interval);
  }, [])

  return (
    <>
      {!isDesktop ?

        <ContainerPage>
          <TopBar>
            <HeaderChatDetails gradient={sessionStorage.getItem('@GradientGrupo')}
              nomeDoGrupo={sessionStorage.getItem('@NomeDoGrupo')} />

          </TopBar>

          <Container style={{ marginTop: '10px' }}>
            {
              updatedResponse == "" || updatedResponse == undefined || updatedResponse == null || analisandoArtificial ? <div style={{
                paddingRight: '10px',
                textAlign: 'center',
                marginTop: '-00px',
                width: '90%',
                alignSelf: 'center',
              }}>
                <h2 style={{
                  fontFamily: 'Montserrat',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: '20px',
                  lineHeight: '20px',

                  marginBottom: '-50px',
                  textAlign: 'center',

                  color: 'var(--Primary-color)',
                }}>{t("IA ANALISANDO MESAS")}</h2>
                <LoadingContainer filter={HexToFilter(cores.primary_color)} options={defaultOptions} />
              </div>
                :

                <div style={{ height: 'auto' }}>
                  <ContainerInfos>
                    <div style={{}}>

                      <TextoInfoJogo analisando={ updatedResponse["texto"].includes("ANALISANDO") || updatedResponse["texto"].includes("CONFIRMADA") }>
                        <div style={{
                          position: 'absolute',
                          display: `${updatedResponse["texto"].includes("ANALISANDO") || updatedResponse["texto"].includes("CONFIRMADA") ? 'flex' : 'none'}`,
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          left: '4px',
                          top: '4px', 
                        }}>
                          <Loading text={""} tipoMesa={1} />
                        </div>

                        <AdjustIcon style={{
                          fill: 'var(--Primary-color)',
                          fontWeight: 'bold',
                          fontSize: '28px',
                          display: `${texto.includes("GREEN") || texto.includes("RED") || texto.includes("Cancelada") ? 'flex' : 'none'}`
                        }} />

                        {texto === 'Cancelada' ?
                          <StyledText>{t("Jogada")} <span>{t("Cancelada")}</span></StyledText> :
                          texto.includes("GREEN") ?
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                              <StyledText
                                style={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{t("Resultado")}
                                : &nbsp;

                                <span style={{
                                  color: 'white', background: '#34C700', borderRadius: '20px', boxShadow: '0px 0px 5px 2px rgba(52, 199, 0, 0.37), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)',
                                  width: '56px',
                                  height: '20px',
                                  fontSize: '11px',
                                  fontWeight: '500'
                                }}>
                                  {t("GREEN")} </span>

                              </StyledText></div> :
                            texto.includes("RED") ?
                              <div
                                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <StyledText
                                  style={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{t("Resultado")}
                                  : &nbsp;
                                  <span style={{
                                    color: 'white', background: '#DA0000', borderRadius: '20px', boxShadow: '0px 0px 5px 2px rgba(218, 0, 0, 0.49), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)',
                                    width: '56px',
                                    height: '20px',
                                    fontSize: '11px',
                                    fontWeight: '500'
                                  }}>
                                    {t("RED")}
                                  </span> </StyledText></div> :
                              texto.includes('ANALISANDO') ?
                                <StyledText>{t("Analisando")}</StyledText> :
                                <StyledText style={{ color: 'white' }}>{t("Entrada")} <span
                                  style={{ color: 'var(--Primary-color)', fontSize: `16px`, fontWeight: '700' }}>{t("Confirmada")}</span>
                                </StyledText>}

                        {texto.includes("ANALISANDO") &&
                          <ContainerDica
                            style={{
                              right: '135px',
                              width: '120px',
                              height: '22px',
                              alignItems: 'center',
                              justifyContent: 'center',
                              textAlign: 'center',
                              fontSize: '8px',
                              borderRadius: '25px',
                              border: '1px',
                              boxShadow: '0px 0px 1px 1px var(--Primary-color)'

                            }}>

                            <h1 style={{ fontWeight: 'normal', fontFamily: 'Montserrat', fontSize: '10px', color: 'var(--Primary-color)' }}>
                              Aguarde a entrada
                            </h1>

                          </ContainerDica>
                        }

                      </TextoInfoJogo>
                    </div>
                    <div style={{height: '4px'}} />
                    <InfoTopbar style={{ marginTop: '0px' }}>
                      <h1 style={{ marginLeft: '4px' }}>{nomeAmigavel}</h1>
                    </InfoTopbar>

                    <TextoDetalhes style={{ marginLeft: '4px', marginTop: '-1px' }} >

                      <span>{texto.includes("GREEN") || texto.includes("RED") ? `` : 'Padrão: '} </span>
                      <span style={{ fontWeight: 'normal' }}>
                        {texto.includes("GREEN") || texto.includes("RED") ? `[${resultado}]` : estrategiaDoPadrao}

                      </span>
                    </TextoDetalhes>

                    <TextoDetalhes><span
                      style={{ marginLeft: '4px' }}>{!(texto.includes("GREEN") || texto.includes("RED")) && `[${sequencia}]`}</span></TextoDetalhes>

                  </ContainerInfos>
                </div>


            }


            {!(updatedResponse == "" || updatedResponse == undefined || updatedResponse == null || analisandoArtificial) &&
              <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between', gap: '8px' }}>
                <ContainerDica style={{ width: `100%` }}
                  corPrimaria={cores.primary_color} onClick={() => {
                    if (texto.includes("GREEN") || texto.includes("RED") || texto === 'Cancelada') {
                      setExibirDetalhes(false)
                    }
                  }}>
                  {texto.includes("GREEN") || texto.includes("RED") || texto === 'Cancelada' ?

                    <h1>
                      {t("Volte para a Tela de Sinais")}
                    </h1>

                    : texto.includes("ANALISANDO") ?
                      <>
                        <h1 style={{ fontWeight: 'normal' }}>{estrategia}</h1>
                        <h1>{cobrir}</h1>
                      </>
                      :
                      <>
                        <h1 style={{ fontWeight: 'normal' }}>{estrategia}</h1>
                        <h1>{cobrir}</h1>
                      </>
                  }
                </ContainerDica>
                {!(texto.includes("GREEN") || texto.includes("RED")) &&

                  <ContainerDica corPrimaria={cores.primary_color} style={{ width: 'fit-content', minWidth: '78px' }}>
                    <h1 style={{ fontWeight: 'normal' }}>Gale</h1>
                    <h1>2X</h1>

                  </ContainerDica>}

              </div>}


            <IframeContainer style={{
              display: `${iframeBool != 1 && 'none'}`,
              marginTop: `${analisandoArtificial || updatedResponse == "0px" || updatedResponse == null || updatedResponse == undefined ? '0px' : '10px'}`,
              marginBottom: '7px'
            }}>
              <iframe src={linkMobile} title="Jogo" />
            </IframeContainer>

            <ContainerLinkJogo corPrimaria={cores.primary_color}>
              <p style={{ color: 'var(--Secondary-Text-color)', textAlign: 'left' }}>{t("O jogo não abriu? Clique no botão acessar.")}</p>
              <a href={""} target="_blank"
                rel="noreferrer">
                <BotaoGame data-jogada-id={ updatedResponse == "" || updatedResponse == undefined || updatedResponse == null ? "" : updatedResponse["jogadaId"]} id='game-button' corPrimaria={cores.primary_color} style={{
                  background: `${clientId == "16" || clientId == "32" ? 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)' : 'var(--Primary-color)'}`,
                  borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
                  boxShadow: `${clientId == "16" || clientId == "32" ? '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)' : '0px 0px 5px 2px rgba(0,0,0,0.1), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
                }}>
                  <OpenInNewIcon /> {t("ACESSAR JOGO")}
                </BotaoGame>
              </a>
            </ContainerLinkJogo>


          </Container>
        </ContainerPage>
        :
        <>
          <Header/>

          <ContainerPage style={{paddingTop: '90px', height: '100vh'}}>{/*DESKTOPP*/}
           
          <TopBar>
     
              <HeaderChatDetails gradient={sessionStorage.getItem('@GradientGrupo')}
                           nomeDoGrupo={sessionStorage.getItem('@NomeDoGrupo')}/>

          </TopBar>


            <Container style={{ paddingTop: '30px', width: '90vw', margin: 'auto' }}>
              {
                updatedResponse == "" || updatedResponse == undefined || updatedResponse == null || analisandoArtificial ? <div style={{
                  paddingRight: '10px',
                  textAlign: 'center',
                  marginTop: '-00px',
                  width: '50%',
                  alignSelf: 'center',
                }}>
                  <h2 style={{
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: '20px',
                    lineHeight: '20px',
  
                    marginBottom: '-50px',
                    textAlign: 'center',
  
                    color: 'var(--Primary-color)',
                  }}>{t("IA ANALISANDO MESAS")}</h2>
                  <LoadingContainer filter={HexToFilter(cores.primary_color)} options={defaultOptions} />
                </div>
                :
                <div style={{ height: 'auto', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                  <ContainerInfos style={{alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                    <div style={{}}>

                      <TextoInfoJogo analisando={ updatedResponse["texto"].includes("ANALISANDO") || updatedResponse["texto"].includes("CONFIRMADA") }>
                        <div style={{
                          position: 'absolute',
                          display: `${updatedResponse["texto"].includes("ANALISANDO") || updatedResponse["texto"].includes("CONFIRMADA") ? 'flex' : 'none'}`,
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          left: '4px',
                          top: '4px', 
                        }}>
                          <Loading text={""} tipoMesa={1} />
                        </div>

                        <AdjustIcon style={{
                          fill: 'var(--Primary-color)',
                          fontWeight: 'bold',
                          fontSize: '28px',
                          display: `${texto.includes("GREEN") || texto.includes("RED") || texto.includes("Cancelada") ? 'flex' : 'none'}`
                        }} />

                        {texto === 'Cancelada' ?
                          <StyledText>{t("Jogada")} <span>{t("Cancelada")}</span></StyledText> :
                          texto.includes("GREEN") ?
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                              <StyledText
                                style={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{t("Resultado")}
                                : &nbsp;

                                <span style={{
                                  color: 'white', background: '#34C700', borderRadius: '20px', boxShadow: '0px 0px 5px 2px rgba(52, 199, 0, 0.37), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)',
                                  width: '56px',
                                  height: '20px',
                                  fontSize: '11px',
                                  fontWeight: '500'
                                }}>
                                  {t("GREEN")} </span>

                              </StyledText></div> :
                            texto.includes("RED") ?
                              <div
                                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <StyledText
                                  style={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{t("Resultado")}
                                  : &nbsp;
                                  <span style={{
                                    color: 'white', background: '#DA0000', borderRadius: '20px', boxShadow: '0px 0px 5px 2px rgba(218, 0, 0, 0.49), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)',
                                    width: '56px',
                                    height: '20px',
                                    fontSize: '11px',
                                    fontWeight: '500'
                                  }}>
                                    {t("RED")}
                                  </span> </StyledText></div> :
                              texto.includes('ANALISANDO') ?
                                <StyledText>{t("Analisando")}</StyledText> :
                                <StyledText style={{ color: 'white' }}>{t("Entrada")} <span
                                  style={{ color: 'var(--Primary-color)', fontSize: `16px`, fontWeight: '700' }}>{t("Confirmada")}</span>
                                </StyledText>}

                        {texto.includes("ANALISANDO") &&
                          <ContainerDica
                            style={{
                              right: '135px',
                              width: '120px',
                              height: '22px',
                              alignItems: 'center',
                              justifyContent: 'center',
                              textAlign: 'center',
                              fontSize: '8px',
                              borderRadius: '25px',
                              border: '1px',
                              boxShadow: '0px 0px 1px 1px var(--Primary-color)'

                            }}>

                            <h1 style={{ fontWeight: 'normal', fontFamily: 'Montserrat', fontSize: '10px', color: 'var(--Primary-color)' }}>
                              Aguarde a entrada
                            </h1>

                          </ContainerDica>
                        }

                      </TextoInfoJogo>
                    </div>
                    <div style={{height: '4px'}} />
                    <InfoTopbar style={{ marginTop: '0px' }}>
                      <h1 style={{ marginLeft: '0px' }}>{nomeAmigavel}</h1>
                    </InfoTopbar>

                    <TextoDetalhes style={{ marginLeft: '0px', marginTop: '-1px' }} >

                      <span>{texto.includes("GREEN") || texto.includes("RED") ? `` : 'Padrão: '} </span>
                      <span style={{ fontWeight: 'normal' }}>
                        {texto.includes("GREEN") || texto.includes("RED") ? `[${resultado}]` : estrategiaDoPadrao}

                      </span>
                    </TextoDetalhes>

                    <TextoDetalhes><span
                      style={{ marginLeft: '0px' }}>{!(texto.includes("GREEN") || texto.includes("RED")) && `[${sequencia}]`}</span></TextoDetalhes>

                  </ContainerInfos>
                </div>

              }
              
              {!(updatedResponse == "" || updatedResponse == undefined || updatedResponse == null || analisandoArtificial) &&

              <div style={{width: '100%', margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

          
              <div style={{ display: 'flex', maxWidth: '500px', width: '100%',alignItems: 'center', justifyContent: 'space-between', gap: '8px' }}>
                <ContainerDica style={{ width: `100%` }}
                  corPrimaria={cores.primary_color} onClick={() => {
                    if (texto.includes("GREEN") || texto.includes("RED") || texto === 'Cancelada') {
                      setExibirDetalhes(false)
                    }
                  }}>
                  {texto.includes("GREEN") || texto.includes("RED") || texto === 'Cancelada' ?

                    <h1>
                      {t("Volte para a Tela de Sinais")}
                    </h1>

                    : texto.includes("ANALISANDO") ?
                      <>
                        <h1 style={{ fontWeight: 'normal' }}>{estrategia}</h1>
                        <h1>{cobrir}</h1>
                      </>
                      :
                      <>
                        <h1 style={{ fontWeight: 'normal' }}>{estrategia}</h1>
                        <h1>{cobrir}</h1>
                      </>
                  }
                </ContainerDica>
                {!(texto.includes("GREEN") || texto.includes("RED")) &&

                  <ContainerDica corPrimaria={cores.primary_color} style={{ width: 'fit-content', minWidth: '78px' }}>
                    <h1 style={{ fontWeight: 'normal' }}>Gale</h1>
                    <h1>2X</h1>

                  </ContainerDica>}

              </div>
              </div>}

              

                  <div style={{width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>

             
              <IframeContainer style={{
                display: `${iframeBool != 1 && 'none'}`,
                width: '90%',
              }}>
                <iframe src={linkMobile} title="Jogo" style={{height: '100%'}}/>
              </IframeContainer>
              </div>

              <ContainerLinkJogo style={{ width: '400px', margin: 'auto', justifyContent: 'center', gap: '0px' }} corPrimaria={cores.primary_color}>
                <p style={{ color: 'var(--Secondary-Text-color)', width: '80%' }}>{t("O jogo não abriu? Clique no botão acessar.")}</p>
                <a href={""} target="_blank"
                  rel="noreferrer">
                  <BotaoGame data-jogada-id={updatedResponse == "" || updatedResponse == undefined || updatedResponse == null ? "" : updatedResponse["jogadaId"]} id='game-button' corPrimaria={cores.primary_color} style={{
                    background: `${clientId == "16" || clientId == "32" ? 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)' : 'var(--Primary-color)'}`,
                    borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
                    boxShadow: `${clientId == "16" || clientId == "32" ? '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)' : '0px 0px 5px 2px rgba(0,0,0,0.1), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
                  }}>
                    <OpenInNewIcon /> {t("ACESSAR JOGO")}
                  </BotaoGame>
                </a>
              </ContainerLinkJogo>

            </Container>
          </ContainerPage>

        </>

      }

    </>
  );
};

export default AviatorPage;
