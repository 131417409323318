import styled from 'styled-components'

export const DivPai = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 90%;
  overflow-y: hidden;
  justify-content: flex-start;
  margin-top: 80px;
`;

export const HeaderLocal = styled.header`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 20px;

`;

export const ImagemLogo = styled.img`
  border-radius: 50%;
  width: 100px;
  margin: 10px;
`;

export const TituloPagina = styled.h1`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-transform: uppercase;

  color: var(--Secondary-Text-color);
  padding: 10px 0;

  span {
    color: var(--Primary-color);
    /*...*/
  }

  @media screen and (max-width: 769px){
    padding: unset;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    line-height: 34px;
    padding: 0px;
    width: 100%;
    font-size: 28px;
    align-self: center;
    justify-content: center;
  }
`;

export const BodyMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  justify-content: center;
  overflow: auto;
  justify-content: flex-start;
  height: 100vh;
`;


export const Card = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  //bottom: 150px;
  //margin-bottom: 160px;
  margin-top: 20vh;
  width: 100%; 
  overflow-y: hidden;
`;


//DESKTOP

export const DivPaiDesktop = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow-y: hidden;
  justify-content: flex-start;
  margin-top: 70px;
`;


export const BodyDesktop = styled.div`
  display: flex;
  flex-direction: column;
  align-self: right;
  justify-content: right;
  overflow-y: hidden;
  overflow: auto;
  
  width: 100%;
  margin: auto;
  height: 100vh;
  
`;

export const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;

  @media screen and (min-width: 770px){
    padding: 0px;
    
  }

`;


export const DivCards = styled.div`
  width: 100%;
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
  margin: auto;
  align-items: center;
  
  overflow: auto;
  padding-bottom: 85px !important;

  @media screen and (max-width: 769px){
    display: grid;
    height: 100vh;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    margin: auto;
    align-items: center;
  
    overflow: auto;

    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: max-content;
    gap: 12px;
    margin-top: 0px;
    padding: 10px;
    justify-content: center;
    padding-bottom: 85px !important;
    
   
  }

`;
