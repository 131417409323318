import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useCustomizacao } from '../../Customizacao';
import { useAuth } from "../../context/auth.context";
import { PostLogin } from '../../services/Roleta';
import {
  Cardlogin,
  ContainerInput,
  ContentBackground,
  DivBannerTop,
  LogoBanner,
  MsgErrorLabel,
  PaiInput,
  PaiLogin,
  StyledButtonEntrar,
  StyledButtonRegistrar,
  StyledInput,
  StyledLabel,
  StyledSpan
} from './styles';
import { useTranslation } from 'react-i18next';

//...
interface CardloginProps {
  style?: React.CSSProperties; // permite a adição de propriedades de estilo
}


function Cardinput() {

  const [email, setUsername] = useState('');
  const [senha, setPassword] = useState('');
  const [registro, setRegistro] = useState('');
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorMsgEmail, setErrorMsgEmail] = useState('');
  const [errorAuth, setErrorAuth] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | any>('');
  const [clientId, setClientId] = useState<any>()
  const { t, i18n } = useTranslation('login');

  useEffect(() => {
    setClientId(sessionStorage.getItem('@ClientId'))
  }, [])

  const navigate = useNavigate();
  const { Login, errorMessage } = useAuth();

  const [isMobile, setMobile] = useState<boolean | null>(false);

  const handleResize = () => {
    setMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    console.log(i18n.language)
    handleResize()
    window.addEventListener("resize", handleResize)
  }, [])


  function handleUsernameChange(e: { target: { value: React.SetStateAction<string>; }; }) {
    setUsername(e.target.value);
  }

  function handlePasswordChange(e: { target: { value: React.SetStateAction<string>; }; }) {
    setPassword(e.target.value);
  }

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();


    const nome = ''
    const usuario = { email, nome, senha }
    let clientId = localStorage.getItem('@ClientId')
    await Login(clientId, usuario)
    await PostLogin(clientId, usuario)
      .then(() => {
        setErrorAuth(false)

      })
      .catch(() => {
        // setErrorAuth(true)
        handleError()
      });

  }

  useEffect(() => {

    const token = sessionStorage.getItem('token');
    if (token) {

      navigate('./ListaGrupos')

    }

  })


  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const goToRegister = () => {
    navigate('./Registro')
  }


  const handleError = () => {
    if (errorMessage == "") {
      setErrorMsg("E-mail ou senha inválidos.")
      setErrorAuth(true)
    } else {
      setErrorMsg(errorMessage)
      setErrorAuth(true)
    }
  }

  useEffect(() => {


    if (errorAuth) {

      setErrorAuth(true)

    }

  }, [errorAuth])


  return (
    <form style={{ display: "flex", flexDirection: "column", width: '100%', padding: '20px', gap: '16px', minWidth: '400px' }} onSubmit={handleLogin}>

      

      <ContainerInput>
        <StyledLabel>E-mail</StyledLabel>
        <StyledInput
          type="text"
          placeholder="E-mail"
          value={email}
          onChange={handleUsernameChange}
          style={{
            border: `${errorAuth ? "1px solid red" : 'none'}`,
          }}
        />
        {/* {errorEmail && <MsgErrorLabel>{errorMsgEmail}</MsgErrorLabel>} */}
      </ContainerInput>

      <ContainerInput>
      <StyledLabel>{t("Senha")}</StyledLabel>
        <StyledInput
          type="password"
          placeholder="Senha"
          value={senha}
          onChange={handlePasswordChange}
          style={{
            border: `${errorAuth ? "1px solid red" : 'none'}`,
          }}
        />
        {errorAuth && <MsgErrorLabel>{errorMsg}</MsgErrorLabel>}
      </ContainerInput>

      <StyledButtonEntrar id="submit" type="submit" className="login-button"
        style={{
          background: `${clientId == "16" || clientId == "32" ? 'linear-gradient(90deg, #F42332 0%, #FF8700 100%)' : 'var(--ButtonBackground-primary)'}`,
          borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
          boxShadow: `${clientId == "16" || clientId == "32" && '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
        }}
      >{t("ENTRAR")}</StyledButtonEntrar>

      <StyledButtonRegistrar id="submit" type="button" className="login-button"
        style={{
          background: `${clientId == "16" || clientId == "32" ? 'black' : !isMobile ? 'var(--ButtonBackground-secundary)' : 'var(--ButtonBackground-grey)'}`,
          borderRadius: `${clientId == "16" || clientId == "32" && '30px'}`,
          boxShadow: `${clientId == "16" || clientId == "32" && '0px 0px 5px 2px rgba(101, 101, 101, 0.3), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3)'}`
        }}
        onClick={goToRegister}
      >
        {t("CRIAR CONTA")}
      </StyledButtonRegistrar>


      <NavLink to="./esquecisenha" style={{ textDecoration: 'none', textAlign: 'center', marginTop: '25px' }}>
        <StyledSpan style={{

          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '17px',
          /* identical to box height */

          textAlign: 'center',

          color: `${clientId == "16" || clientId == "32" && isMobile && '#FFFFFF'}`,
        }}>
          {t("Esqueci minha senha")}
        </StyledSpan>
      </NavLink>


    </form>
  );
}


const Login: React.FC = () => {
  const { coresRoot, logoUrl, headerMobileLogin, logoUrlLogin } = useCustomizacao();

  const [isMobile, setMobile] = useState<boolean | null>(false);

  const handleResize = () => {
    setMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
  }, [])

  const [clientId, setClientId] = useState<any>()

  useEffect(() => {
    setClientId(sessionStorage.getItem('@ClientId'))
  }, [])

  return (
    <>
      <ContentBackground>
        {isMobile &&
          <DivBannerTop>
            {/* <BannerTop src={}/>  */}
            <LogoBanner src={logoUrlLogin}
              style={{
                width: '100%',
                maxWidth: '300px'
              }} />

          </DivBannerTop>

        }
        <PaiLogin>
          <Cardlogin>
            <PaiInput>
              <Cardinput />
            </PaiInput>
            <style dangerouslySetInnerHTML={{ __html: coresRoot }} />
          </Cardlogin>
        </PaiLogin>
      </ContentBackground>
    </>

  )
}

export default Login
