import React, {useEffect, useState} from 'react'
import {BolaGreen, BolaRed, DivGreenRed, DivLeftHeader, HeaderDiv, Image, NumResults, TituloGrupo} from './styles';
import {useNavigate, useParams} from "react-router-dom";
import './styles.css'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {GetInfosGreenDinamico} from '../../../services/Roleta';
import {useGameContext} from '../../../context/game.context';
import {useCustomizacao} from '../../../Customizacao';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

interface HeaderChatProps {
  gradient: string | any;
  nomeDoGrupo: string | any;

  // numGreens: any;
  // numReds: any;
}

let auxiliar = "";
let auxiliarRed = "";


const HeaderChatDetails: React.FC<HeaderChatProps> = ({gradient, nomeDoGrupo}) => {
  const {chatId, afiliadoId, tipoMesa} = useParams();
  const [green, setGreen] = useState();
  const [red, setRed] = useState();

  const [isMobile, setIsMobile] = useState<boolean | any>(false);

  const [grupos, setGrupos] = useState([]);
  const {grupoAtivo} = useGameContext();
  const {logoUrl, setExibirMenu, TopoFixoBool} = useCustomizacao();
  const {setExibirDetalhes, actualDetails} = useGameContext();
  const {nomeGrupo} = useParams();
  const {t, i18n} = useTranslation('header')

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 769);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  }, [])


  const GradientStyle = {
    height: '68px',
    boxShadow: `${!isMobile && 'none'}`,
    width: '100vw',
    background: `${TopoFixoBool == "1" ? 'var(--TopoFixo)' : 'linear-gradient(0deg, var(--Primary-color) 0%, rgba(220, 79, 0, 0) 100%)'}`,
    borderBottom: `${TopoFixoBool != "1" && '2px solid var(--Primary-color)'}`

  }
  const navigate = useNavigate();

  const handleClick = () => {
    if(nomeGrupo == "Aviator" || nomeGrupo == "Dino" || tipoMesa == "0"){
      navigate('../')
    } else {
      setExibirDetalhes(false)
    }
    
  }


  useEffect(() => {
    const fetchData = async () => {
      const data = await GetInfosGreenDinamico(chatId);

      setGreen(data.roletas[0].green1)
      setRed(data.roletas[0].red1)

    }
    fetchData()

  }, [])


  return (
    <>



      <HeaderDiv style={{
        width: `${isMobile ? '100vw' : '100%'}`, 
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: `${isMobile ? 'right' : 'center'}`, 
        justifyContent: `${isMobile ? 'right' : 'center'}`, 
        marginLeft: `${isMobile ? '0px' : ''}`, 
        marginTop: `${tipoMesa == "0" && isMobile ? '0px' : '0px'}`, 
        padding: `${tipoMesa == "1" && '0 0px'}`
  

       }}>


      {!isMobile ? 
        <>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '90%'}}>
            <DivLeftHeader>
                  <ArrowBackIosNewIcon
                    onClick={handleClick}
                    fontSize='medium'
                    style={{
                      cursor: 'pointer',
                    }}/>

                  <TituloGrupo>{t(`${nomeDoGrupo}`)}</TituloGrupo>

                  <DivGreenRed>
                    <BolaGreen/><NumResults>{green}</NumResults>
                    <BolaRed/><NumResults>{red}</NumResults>
                  </DivGreenRed>

            </DivLeftHeader>


                <Image imagem={grupoAtivo.iconUrl}/>
            </div>
        </> 
        
        : 
        
        <>
        
          <span style={{color: 'white', fontSize: '24px', cursor: 'pointer', paddingTop: `${tipoMesa == "1" ? '40px' : '0px'}`}} onClick={handleClick}>

            <CloseIcon/>
          
          </span>

        </>}
        



      </HeaderDiv>



    </>
  )
}

export default HeaderChatDetails;
