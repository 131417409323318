import {createContext, ReactNode, useContext, useState} from 'react';
import {GetMensagensOffline} from "../services/Roleta";

interface GameContextType {
  activeGame: any;
  setActiveGame: (game: any) => void;
  grupoAtivo: any;
  setGrupoAtivo: (grupo: any) => void;
  getUltimosDados: (chatId: any) => void;
  modalCadastro: boolean;
  setModalCadastro: (modal: boolean) => void;
  exibirDetalhes: boolean;
  setExibirDetalhes: (exibir: boolean) => void;
  actualDetails: any;
  setActualDetails: (details: any) => void;
  nomeRoletaAmigavel: any;
  setNomeRoletaAmigavel: (nomeAmigavel: any) => void;
  transformString: (string: any) => void;
}

interface GameProviderProps {
  children: ReactNode;
}

export const GameContext = createContext<GameContextType>({} as GameContextType);
GameContext.displayName = 'GameContext';

export const GameProvider = ({children}: GameProviderProps) => {
  const [activeGame, setActiveGame] = useState({
    "roletaId": "2023",
    "texto": `"<div id=\"container-infos-game\">\r\n    <div>\r\n        <h1>OPORTUNIDADE IDENTIFICADA</h1>\r\n        <div>\r\n            <p>🥅</p>\r\n            <p>Penalty Shoot-Out</p>\r\n        </div>\r\n        <div>\r\n            <p>🤔 Estratégia</p>\r\n            <p>Repetição</p>\r\n        </div>\r\n        <div>\r\n            <p>🔥 Nº de tentativas</p>\r\n            <p>3</p>\r\n        </div>\r\n        <div>\r\n            <p>⏰ Valido até:</p>\r\n            <p>14:57</p>\r\n        </div>\r\n        <div>\r\n            <p>🖥️</p>\r\n            <a href=\"https://betfast.io/br/casino/gamepage?gameid=20985\">Computador</a>\r\n        </div>\r\n    </div>\r\n</div>\r\n\\r\\n\\r\\n___\\r\\n|🟢🟢🟢|\\r\\n|⚽🧍🏻🟢|"`,
    "ativo": 1,
    "jogadaId": "92609",
    "data": "5/30/2023 10:40:00 AM",
    "atualizacao": "aguardando status"
  });

  const [actualDetails, setActualDetails] = useState<any>();

  const [roletaIdAtual, setRoletaIdAtual] = useState(0);
  const [grupoAtivo, setGrupoAtivo] = useState({})
  const [modalCadastro, setModalCadastro] = useState(false);
  const [exibirDetalhes, setExibirDetalhes] = useState(false);
  const [nomeRoletaAmigavel, setNomeRoletaAmigavel] = useState<any>()

  function transformString(inputString: any) {
          const h1Regex = /<b>(.*?)<\/b>/;
          const regexRoleta = /<b>Roleta<\/b>: (.*?)\n/;
          const regexPadrao = /<b>Padrão<\/b>: (.*?)\n/;
          const regexSequencia = /\[(.*?)\]/;
          const regexEstrategia = /<b>Estratégia<\/b>: (.*?)\n/;
          const regexLink = /<a href="(.*?)">Computador<\/a>/;
      
          const h1 = inputString.match(h1Regex)[1];
          const padrao = inputString.match(regexPadrao)[1];
          const sequencia = inputString.match(regexSequencia)[1];
          const roleta = inputString.match(regexRoleta)[1];
          const estrategia = inputString.match(regexEstrategia)[1];
          const link = inputString.match(regexLink)[1];
      
          return `<div id="container-infos-game">
          <div>
            <h1>${h1}</h1>
            <div>
              <p>🎰 ${roleta}</p>
              <div>${estrategia}</div>
            </div>
            <div>
              <p>🎰 Padrão</p>
              <div>${padrao}</div>
            </div>
            <div>
              <p>🎰 Sequencia</p>
              <div>[${sequencia}]</div>
            </div>
            <div>
              <p>🖥️</p>
              <a href="${link}"><button>Acessar Jogo</button></a>
            </div>
          </div>`;
      }

  const getUltimosDados = async (chatId: any) => {
    
    await GetMensagensOffline(chatId)
      .then((response) => {
        setActiveGame(response[0]);
      })
  }

  const value: GameContextType = {
    activeGame,
    setActiveGame,
    grupoAtivo,
    setGrupoAtivo,
    getUltimosDados,
    modalCadastro,
    setModalCadastro,
    exibirDetalhes,
    setExibirDetalhes,
    setActualDetails,
    actualDetails,
    nomeRoletaAmigavel,
    setNomeRoletaAmigavel,
    transformString
  };

  return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
};

export function useGameContext() {
  const context = useContext(GameContext);

  return context;
}
