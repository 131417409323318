import React, {useEffect, useState} from "react";
import {HeaderLocal, ProfileImg, StyledSpan} from "./styles";
import {useAuth} from "../../context/auth.context";
import {useCustomizacao} from "../../Customizacao";
import {useParams} from "react-router-dom";
import Menu from "./Menu";
import { useTranslation } from 'react-i18next';


const Header: React.FC = () => {
  const [isDesktop, setIsDesktop] = useState<boolean | any>(true);
  const {name, email} = useAuth();
  const {logoUrl, headerImgUrl, logoDrawer, TopoFixo, TopoFixoBool} = useCustomizacao();
  const {tipoMesa} = useParams();
  const {t, i18n} = useTranslation('header')

  // console.log(TopoFixo, 'topoFixo')


  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 769);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {isDesktop ?
        <HeaderLocal style={{
          justifyContent: 'center',
          padding: '0 0px',
          width: '100%',
          background: `${TopoFixoBool == "1" ? 'var(--TopoFixo)' : 'linear-gradient(0deg, var(--Primary-color) 0%, rgba(220, 79, 0, 0) 100%)'}`,
          borderBottom: `${TopoFixoBool != "1" && '2px solid var(--Primary-color)'}`,
          overflowY: 'hidden',
          backgroundColor: '#000000',
          zIndex: '1002',
          
       
          
        }}>
          
          <div style={{width: '90vw', display: 'flex', alignItems: 'center', justifyContent: 'space-between', alignSelf: 'center'}}>

          
          <img src={logoDrawer} style={{
            width: '70px',
            padding: '8px 20px 8px 0px',
           
          }}/>

          <Menu />

          <div style={{
            display: 'flex',

          }}>
            <StyledSpan style={{
              marginRight: '10px'
            }}>{t("Seja bem vindo,")} <br/>{name}</StyledSpan>


            <ProfileImg>{name.slice(0, 1)}</ProfileImg>
          </div>

          </div>
      
        </HeaderLocal>

        :
        <>
       

            
          <HeaderLocal style={{
            justifyContent: 'space-between',
            background: `${TopoFixoBool == "1" ? 'var(--TopoFixo)' : 'linear-gradient(0deg, var(--Primary-color) 0%, rgba(220, 79, 0, 0) 100%)'}`,
            borderBottom: `${TopoFixoBool != "1" && '2px solid var(--Primary-color)'}`,
            zIndex: '1001',
            backgroundColor: '#000000'

            

          }}>



            <div style={{
              display: 'flex',
            }}>
              <ProfileImg style={{
                color: 'var(--Secondary-Text-color)',
              }}>{name.slice(0, 1)}</ProfileImg>

              <StyledSpan style={{
                textAlign: 'left',
                marginLeft: '10px'
              }}>{t("Seja bem vindo,")} <br/>{name}</StyledSpan>

            </div>


            <img src={logoDrawer} style={{
              width: '70px',
              marginRight: '10px',
             

            }}/>
         
          
          </HeaderLocal>
        </>
      }

    </>
  );
};

export default Header;
