import React, { useEffect, useRef, useState } from 'react'
import { BodyDesktop, BodyMobile, DivCards, DivContainer, DivPai, DivPaiDesktop, TituloPagina } from "./styled";

import TableRowMobile from './tableRowMobile';
import './styles.css'
import ListAltIcon from '@mui/icons-material/ListAlt';

import Header from '../Header'
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import Play from './play.png'
import { useCustomizacao } from '../../Customizacao';
import { Live } from '../Live';




const GroupsTable: React.FC = () => {
  const [isMobile, setIsMobile] = useState(true);
  const [isIconRotated, setIsIconRotated] = useState(false);
  const { t, i18n } = useTranslation('listaGrupos')
  const {linkLive, liveBool} = useCustomizacao()


  const handleSortByTimeClick = () => {
    setIsIconRotated(!isIconRotated);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 769);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  //....
  const contentRef = useRef(null);

  const handleScroll = (event: any) => {
    event.stopPropagation();
  };

  const [showLive, setShowLive] = useState(true)

  const handleShowLive = async () => {
    setShowLive(!showLive)
  }



  return (
    <>
      <Header />

      {isMobile ?
 
        <DivPai style={{ overflowY: 'hidden' }}>
          
            
          
          
   
          <BodyMobile style={{ overflowY: 'hidden' }}>

          <div style={{width: '95%', alignSelf: 'center', maxHeight: '600px', height: 'auto'}}>
              <Live/>
          </div>
          

            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
              <TituloPagina> <ListAltIcon style={{
                fill: 'var(--ItemColor)',
                fontSize: '36px',
                textAlign: 'center',
                display: `${isMobile && 'none'}`
              }}

              />{t("Sala de")}<span style={{ color: 'var(--Primary-color)' }}>{t('Jogos')}</span></TituloPagina>



            </div>




            <DivCards className="content" ref={contentRef} onTouchMove={handleScroll}>
              <TableRowMobile />
            </DivCards>



          </BodyMobile>


        </DivPai>

        :
        <DivPaiDesktop style={{ overflowY: 'hidden' }}>{/*DESKTOP*/}
        
              {liveBool &&
                <div className='live' style={{display: 'flex', flexDirection: 'column', width: '90%', justifyContent: 'space-between', paddingTop: 20, gap: 0}}>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                      <span style={{color: '#FFF', fontWeight: 'bold', fontSize: '24px', display: 'flex', alignItems: 'center', gap: 10}}>
                        <img src={Play}/> AO 
                        <span style={{color: 'var(--Primary-color)'}}>VIVO</span></span>
                      <span style={{color: '#FFF', fontSize: '14px', fontWeight: '500', border: '1px solid var(--Primary-color)', background: 'rgba(155, 240, 19, 0.20)', boxShadow: '0px 0px 5px 2px var(--Primary-color)', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', padding: '0 40px', borderRadius: '50px', gap: 20}} onClick={handleShowLive}>

                        Exibir
                        
                        <Icon icon={showLive ? `bxs:down-arrow` : `bxs:left-arrow`}/>
                      
                      </span>



                  </div>
                  {showLive && 
                    <iframe width="100%" height="600" src={linkLive ? linkLive : ''} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                  }
                  
                </div>
              }
              

          <BodyDesktop style={{ overflowY: 'hidden' }}>
            <DivContainer style={{overflowY: 'hidden'}}>


              <TituloPagina style={{
                textAlign: 'center'

              }}>{t("Sala de")} <span>{t("Jogos")}</span>
              </TituloPagina>
              <DivCards style={{width: '90%', padding: '0 0px'}} className="content" ref={contentRef} onTouchMove={handleScroll}>
                <TableRowMobile />
              </DivCards>

            </DivContainer>
          </BodyDesktop>
              
        </DivPaiDesktop>
      }
    </>
  );
};

export default GroupsTable;
