import React, {useEffect, useState} from "react";
import './style.css'
import {useCustomizacao} from "../../Customizacao";
import { useTranslation } from 'react-i18next';

interface LoadingProps {
  text: string;
  tipoMesa: any;
}


const Loading: React.FC<LoadingProps> = ({text, tipoMesa}) => {

  const [isMobile, setIsMobile] = useState<boolean | null>();

  const {setExibirMenu} = useCustomizacao();
  const {t, i18n} = useTranslation('loading')

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1023);
  };

  useEffect(() => {
    setExibirMenu(false);
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      setExibirMenu(true);
    }
  }, [])

  return (
    <>


      <div id="wifi-loader" style={{
        marginTop: `${isMobile && tipoMesa != "1" ? '80px' : tipoMesa == "1" ? '-20px' : '50px'}`,
        display: `${tipoMesa == "0" && 'none'}`,
        marginLeft: `${tipoMesa == "1" && "0"}`,
        position: 'relative'

      }}>
        <svg className="circle-outer" viewBox="0 0 86 86">
          <circle className="back" cx="43" cy="43" r="40"></circle>
          <circle className="front" cx="43" cy="43" r="40"></circle>
          <circle className="new" cx="43" cy="43" r="40"></circle>
        </svg>
        <svg className="circle-middle" viewBox="0 0 60 60">
          <circle className="back" cx="30" cy="30" r="27"></circle>
          <circle className="front" cx="30" cy="30" r="27"></circle>
        </svg>
        <svg className="circle-inner" viewBox="0 0 34 34">
          <circle className="back" cx="17" cy="17" r="14"></circle>
          <circle className="front" cx="17" cy="17" r="14"></circle>
        </svg>
        <div className="text" data-text={t(`${text}`)}
             style={{paddingBottom: `${isMobile ? '100px' : '30px'}`, display: `${tipoMesa == "1" && 'none'}`}}></div>
      </div>

    </>
  )
}

export default Loading
