import {NavLink} from 'react-router-dom';
import styled, {keyframes} from 'styled-components';


interface Props {
  active?: boolean;
  transition?: boolean;
}

export const ContainerMenu = styled.div`
    position: fixed;
    bottom: 0;
    padding: 20px;
    width: 100vw;
    height: 75px;
    z-index: 100;
    overflow-x: hidden;
    overflow-y: hidden;
    
`;

export const Menu = styled.menu`
    //position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 48px;

    font-size: 16px;
    padding: 0 0;

    
  
    /* background: var(--Primary-color); */
    
    
    border-radius: 16px;

    &::after{
      opacity: 0.5;
    }

    
`;

export const MenuItem = styled(NavLink)<Props>`
    
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    min-width: 33.4%;
    opacity: 1;

    

    cursor: pointer;
    z-index: 100;
    transition: transform var(--animation-duration);
    &:after {
        content: "";
        z-index: -1;
        width: 67px;
        height: 67px;
        border-radius: 50%;
        position: absolute;
        /* needed for the scale animation */
        transform: scale(0);
        transition: background-color var(--animation-duration), transform var(--animation-duration);
        opacity: 1;
    }

    ${props => props.active && `
        transform: translate3d(0, -14px, 0);
    `}

    ${props => props.active && `
        &:before {
            transform: scale(1);
            background-color: blue;
        }
    `}
`;

export const MenuBorder = styled.div<Props>`
    position: absolute;
   
    bottom: 49%;
    width: 164px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;

    /* The magic for the it not being a rectangle */
    clip-path: url(#menu);
    ${props => props.active && `
        transition: transform var(--animation-duration);
    `}
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

interface CircleProps {
  fading?: boolean;
}

export const Circle = styled.div<Props>`
  margin-top: 25px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.2) 100%), var(--Secondary-color);
  box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.25), inset 0px 1px 1px rgba(255, 255, 255, 0.25);
  transition: transform var(--animation-duration);
  display: flex;
  align-items: center;
  justify-content: center;


        
  &:after {
        content: "";
        z-index: -1;
        border-radius: 50%;
       
        /* needed for the scale animation */
        transform: scale(0);
        transition: background-color var(--animation-duration), transform var(--animation-duration);
        
    }

    ${props => props.active && `
        transform: translate3d(0, 0, 0);
    `}

    ${props => props.active && `
        &:before {
            transform: scale(1);
            background-color: blue;
        }
    `}
`;

export const svgContainer = styled.div`
    width: 0;
    height: 0;
`;

export const Icon = styled.div`
    
        svg {
            width: 30px;
            height: 30px;
        }
`;
