import {createContext, ReactNode, useContext, useState} from 'react';

interface TabsContextType {
  activeTab: number;
  setActiveTab: (tabIndex: number) => void;
}

interface TabsProviderProps {
  children: ReactNode;
}

export const TabsContext = createContext<TabsContextType>({} as TabsContextType);

export const TabsProvider = ({children}: TabsProviderProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const value: TabsContextType = {
    activeTab,
    setActiveTab,
  };

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};

export function useTabsContext() {
  const context = useContext(TabsContext);

  return context;
}
