import styled from 'styled-components';

type Props = {
  degradeColor?: string;
  imagem?: string;
}


export const Image = styled.div<Props>`
 width: 68px;
  height: 32px;
  
  background-image: url(${props => props.imagem});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  align-self: right;
`;

export const HeaderDiv = styled.div`
    position: fixed;
    width: 100%;
   
    padding: 10px;
    

    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
    align-items: center;
  
    gap: 10px;
    overflow-y: none;
    z-index: 1000;
  
    outline: none;
`;

export const DivAux = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  align-items: center;  
  width: 100%;
`;


export const DivLeftHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
`;


export const TituloGrupo = styled.h2`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #FFFFFF;
    margin-right: 20px;

`;


export const DivGreenRed = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  margin: auto;
  gap: 20px;
`;

export const NumResults = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    color: #FFFFFF;
    margin-left: -15px;
`;


export const BolaGreen = styled.div`
  background: #05AD30;
  width: 15px;
  height: 15px;
  border-radius: 50px;
  box-shadow: 0 0 2px 0 black;
`;


export const BolaRed = styled.div`
  background: #C80A0A;
  width: 15px;
  height: 15px;
  border-radius: 50px;

  box-shadow: 0 0 2px 0 black;

  align-self: left;
  align-content: left;
`;


export const ImagemRight = styled.div`
  width: 50px;
  height: 50px;
  background: white;
`;
