import styled from 'styled-components'
import bg from '../../assets/app-bg.png'
import Lottie from 'react-lottie';

interface BackgroundDivProps {
  corPrimaria?: string;
  filter?: string;
}


export const DivPai = styled.div`
    position: fixed;
    
    display: flex;
    flex-direction: column;
    align-items: right;
    
    width: 100%;
    overflow-y: hidden;
    height: 100%;
    max-height: 100vh;
    background: black;
    background: linear-gradient(0deg, ${(props: BackgroundDivProps) => props.corPrimaria}30, ${(props: BackgroundDivProps) => props.corPrimaria}30), url(${bg});
    
    background-size: cover;
    background-position: center;
    

    @media screen and (min-width: 770px){
        justify-content: right;
        align-items: right;
        align-self: right;
        height: 100%;
        
    }
    
`;

export const ContainerMensagens = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y:auto;
    

    border-radius: 8px;

    @media screen and (min-width: 769px){
        padding: 0 20px;
    }

    @media screen and (max-width: 769px){
        margin: auto;
        width: 100%;
        margin-top: 0;
        border-radius: 0px 0px 8px 8px;
    }

    
`;

export const ContainerMensagensAux = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    overflow-y: auto;

    @media screen and (min-width: 769px){
        background: linear-gradient(89.72deg, #000000 0.31%, rgba(0, 0, 0, 0) 51.1%, #000000 99.82%);
        box-shadow: inset 0px 15px 6px rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        margin-bottom: 20px;
        align-items: center;
    }
`;

export const MensagensLimitador = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media screen and (min-width: 769px){
        max-width: 450px;
    }
`;

export const Background = styled.img`
    width: 100%;
    height: 100vh;
    z-index: 999;
`;

export const ButtonAssineAgora = styled.button`
    background: white;
    color: #f8049c;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1em;
    font-family: 'Montserrat', sans-serif;
    width: 150px;
    height: 60px;
    border-radius: 20px;
    border: none;
    position: absolute;
    left: 40px;

`;


export const ContainerSemAcesso = styled.div`
    height: 80vh;
    width: calc(100% - 140px);
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    margin: auto;

    align-items: center;

    gap: -10px;
    z-index: -100;
    
    padding-bottom: 20px;
    margin-bottom: 60px;
    margin-top: 20px;
 
    border-radius: 8px;

    @media screen and (max-width: 769px){
        width: 100%;
        margin-top: 0;
        border-radius: 0px 0px 8px 8px;
        height: 90vh;
    }
`;


export const DivSemAcesso = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    @media screen and (min-width: 769px){
        gap: 12px;
    }
`;

export const LogoSemAcesso = styled.img`
    width: 200px;
    margin-bottom: -40px;
    @media screen and (min-width: 769px){
        border-radius: 8px;
        margin-bottom: 0px;
    }
`;

export const TituloSemAcesso = styled.h1`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: center;

    color: #FFFFFF;
    @media screen and (min-width: 769px){
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        text-align: center;
    }
`;

export const TextSemAcesso = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    /* identical to box height */

    text-align: center;

    color: #DADADA;
    @media screen and (min-width: 769px){
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        /* identical to box height, or 30px */

        text-align: center;
    }
`;

export const BtPrimary = styled.button`
    background: var(--Primary-color);
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    text-align: center;

    color: var(--Secondary-Text-color);

    height: 40px;
    width: auto;
    margin-top: 5px;
    margin-left: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 769px){
        box-shadow: 0px 0px 5px 2px var(--Secondary-Text-color) var(--Secondary-Text-color);
        border-radius: 30px;
        padding: 20px;
        height: 52px;
        margin-top: 8px;
    }

    @media screen and (max-width: 769px){
        width: 100%;
        margin-top: 20px;
        margin-left: 0px;
    }

    &:hover{
        cursor: pointer;
    }
`;

export const LoadingContainer = styled(Lottie)<BackgroundDivProps>`
    
`
