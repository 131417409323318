import styled from 'styled-components';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {NavLink} from "react-router-dom";

type Props = {
  degradeColor?: string;
  imagem?: string;
}

export const ContainerTopBar = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  height: 20px;
  width: 100%;
  align-items: center;
  border-radius: 6px;
  margin-top: 80px;

  p {
    color: #000000;
  }

  @media screen and (min-width: 770px){
    justify-content: space-between;
    padding: 0;
  }
`;

export const ContainerInnerInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerInfo = styled.div`
  display: flex;
  align-items: center;
  h1 {
    color: #FFF;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
  }
`;

export const BotaoVoltar = styled(ChevronLeftIcon)`
  cursor: pointer;
`;

export const ContainerVoltar = styled(NavLink)`
  cursor: pointer;
  display: flex;
  align-content: center;
`;

export const ContainerIcon = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.div<Props>`
  width: 100%;
  height: 32px;
  border-radius: 4px;
  background-image: url(${props => props.imagem});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;
