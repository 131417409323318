import styled from "styled-components";


export const HeaderLocal = styled.header`
    width: 100%;
    height: 68px;
    /* background-image: none !important; */
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    

  
    /* background: linear-gradient(0deg, var(--Primary-color) 0%, rgba(220, 79, 0, 0) 100%); */
    /* border-bottom: 2px solid var(--Primary-color); */
    overflow-y: hidden;
    position: fixed;
    background-image: var(--TopoFixo);
    background-repeat: repeat;


    @media screen and (max-width: 769px){
        justify-content: flex-start;
        padding-right: 0;
        padding-left: 20px;
        top: 0px;
        height: 68px;
    }
`;

export const ProfileImg = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    
`;

export const StyledSpan = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */

    display: flex;
    align-items: center;
    text-align: right;

    color: var(--Secondary-Text-color);
`;
