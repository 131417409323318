import React, {useState} from 'react';
import { useCustomizacao } from '../../Customizacao';
import { Icon } from '@iconify/react';
import Play from '../groups-table/play.png'



export const Live = ({tela = "main"}) => {
    const [showLive, setShowLive] = useState(true)
    
    const {linkLive, liveBool} = useCustomizacao()

  const handleShowLive = async () => {
    setShowLive(!showLive)
  }
    
    return (
        <>
        {liveBool &&
                <div className='live' style={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between', paddingTop: tela == "main" ? 20 : 10, gap: 10, height: '100%'}}>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                      <span style={{color: '#FFF', fontWeight: 'bold', fontSize: tela == "main" ? '24px' : '16px', display: 'flex', alignItems: 'center', gap: 5}}>
                        <img src={Play} style={{width: tela != "main" && '15px'}}/> AO 
                        <span style={{color: 'var(--Primary-color)'}}>VIVO</span></span>
                        {tela == "main" &&
                        <span style={{color: '#FFF', fontSize: '14px', fontWeight: '500', border: '1px solid var(--Primary-color)', background: 'rgba(155, 240, 19, 0.20)', boxShadow: '0px 0px 5px 2px var(--Primary-color)', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', padding: '0 40px', borderRadius: '50px', gap: 20}} onClick={handleShowLive}>

                        Exibir
                        
                        <Icon icon={showLive ? `bxs:down-arrow` : `bxs:left-arrow`}/>
                      
                      </span>
                        }
                      



                  </div>
                  {showLive && 
                    <iframe src={linkLive ? linkLive : ''} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" height="100%"></iframe>
                  }
                  
                </div>
              }

        </>
    )
}