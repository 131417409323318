import styled from "styled-components";


export const DivPai = styled.div`
    width: 100%;
    display: flex;
    align-items: right;
    justify-content: right;
    overflow-x: hidden;
    padding-top: 40px;
    
`;

export const ContainerUsuario = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    width: 80%;
    margin: auto;
`;


export const HeaderLocal = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 55px 0;
    padding-bottom: 20px;

    
`;

export const TituloPagina = styled.h1`
    color: var(--Text-color);
    display: flex;
    align-items: center;
    gap: 10px;
 

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-transform: uppercase;

    color: #FFFFFF;

  @media screen and (max-width: 769px){ 
    align-self: center;
  }
`;

export const DivBts = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
 
`;

export const BtPrimary = styled.button`
    background: var(--Primary-color);
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    text-align: center;

    color: var(--Secondary-Text-color);

    height: 40px;
    width: 100px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;


    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.3);
    border-radius: 20px;

    @media screen and (max-width: 769px){
        width: 100%;
        
        margin-left: 0px;
    }

    &:hover{
        cursor: pointer;
    }
`;


export const Body = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;
    width: 100%;
    overflow-x: hidden;


    @media screen and (max-width: 769px){
        flex-direction: column;
    }
`;

export const BodyTitulos = styled.div`
    display: grid;
    grid-template-columns: 20% 80%;
`;

export const DivImg = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

export const SubTitulos = styled.h2`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* Black */

    color: var(--Secondary-Text-color);
   
`;

export const ImgPerfil = styled.div`
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: black;
    color: var(--Secondary-Text-color);
    font-size: 64px;
    text-align: center;
    display: flex;

    justify-content: center;
    align-items: center;
   
`;

export const DivInformacaoGeral = styled.div`
    width: 30%;

`;

export const DivInputsInformaçoes = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  

    
`;

export const StyledLabel = styled.label`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  /* Black */

  color: var(--Secondary-Text-color);


  @media screen and (max-width: 769px){
    width: 100%;
    text-align: left;
    align-items: left;

    color: var(--Secondary-Text-color);
  }
`;

export const StyledInput = styled.input`
  border: none;
 
  color: var(--Text-color);
  background: #FFFFFF;
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
font-size: 18px;
  height: 52px;
  border-radius: 4px;
  padding-bottom: 0px;
  box-sizing: border-box;
  padding-left: 25px;
  font-family: 'Montserrat';
  width: 100%;
  -webkit-appearance: none; /* Remova o estilo padrão do iOS */
  /* box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.6); */
  -webkit-box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.2);


  &::placeholder {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #999999;
  }

  
  

  &:focus {
    outline: none;
    /* box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.6); */
    -webkit-box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
  }

  @media screen and (min-width: 770px) {
    margin-bottom: 20px;
  }
`;


//MOBILE

export const BodyMobile = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    align-items: center;
    justify-content: center;
    width: 100%;

    padding: 20px;
    margin: 0;
    margin-top: 75px;
`;

export const ContainerInput = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const ListPlanos = styled.ul`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 200%;
    /* or 32px */


    /* Black */

    color: var(--Text-color);
    width: 100%;
    align-self: left;


`;

export const ItemListPlanos = styled.li`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 200%;

    color: var(--Text-color);
`;


export const DivColumns = styled.div`
    
`;

export const DivNecessaria = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
    padding: 0px;
  
`;


export const SpanAlterarSenha = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    display: flex;
    width: 100%;
    text-align: left;
    align-self: left;
    justify-content: left;
    text-decoration: underline;
  
    /* Red */

    color: var(--LinksColor-primary);

`;
