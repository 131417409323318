import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {useCustomizacao} from '../Customizacao'
import EsqueciSenhaPage from '../pages/EsqueciSenha'
import Grupo from '../pages/Grupo'
import ListaGrupos from '../pages/ListaGrupos'
import Loginpage from '../pages/Login'
import NovaSenhaPage from '../pages/RedefinirSenha'
import RegisterPage from '../pages/Register'
import ResetPasswordPerfil from '../pages/ResetPasswordPerfil'
import Userpage from '../pages/Usuario'
import Ebookpage from '../pages/ebook'
import Cassino from '../assets/cassino.jpg'
import GroupTable from "../components/groups-table";
import {ContainerPages} from "../pages/containerpages";
import ExtensionPage from '../pages/ExtensionPage'

export default function Router(): JSX.Element {
  const {coresRoot, dominio} = useCustomizacao();
  //...

  return (
    <>
      <style>
        {coresRoot}
      </style>
      <BrowserRouter>
        <Routes>
          { window.location.href.includes('ExtensionPage') ? <Route path="/ExtensionPage/:jogadaId/:subdominio/:tipoMesa" element={<ExtensionPage/>}></Route> :

          window.location.href === 'http://localhost:3000/' || window.location.href === 'https://appbetbooster.vercel.app/' || window.location.href === dominio ?
         
          <Route path="/" element={<ContainerPages/>}>
            <Route path="/" element={<Loginpage/>}></Route>
            <Route path="/Registro" element={<RegisterPage/>}></Route>
            <Route path="/Perfil" element={<Userpage/>}></Route>
            {/* <Route path='/Login'element={<Loginpage/>}></Route> */}
            <Route path='/ListaGrupos' element={<ListaGrupos/>}>
              <Route path='/ListaGrupos' element={<GroupTable/>}></Route>
              {/* <Route path={`/:nomeCliente/ListaGrupos/HomeGrupo`} element={<MineGameComponent />}></Route> */}
            </Route>
            <Route path='/Grupo/:chatId/:afiliadoId/:tipoMesa/:nomeGrupo' element={<Grupo/>}></Route>
            <Route path='/ebook' element={<Ebookpage/>}></Route>
            <Route path='/EsqueciSenha/' element={<EsqueciSenhaPage/>}></Route>
            <Route path='/resetpassword=/:resetToken' element={<NovaSenhaPage/>}></Route>
            <Route path='/resetpassword' element={<ResetPasswordPerfil/>}></Route>

            <Route path='/CassinoIMG' element={<><img src={Cassino}/></>}></Route>
          </Route>
          :
<Route path="/:nomeCliente" element={<ContainerPages/>}>
            <Route path="/:nomeCliente/" element={<Loginpage/>}></Route>
            <Route path="/:nomeCliente/Registro" element={<RegisterPage/>}></Route>
            <Route path="/:nomeCliente/Perfil" element={<Userpage/>}></Route>
            {/* <Route path='/Login'element={<Loginpage/>}></Route> */}
            <Route path='/:nomeCliente/ListaGrupos' element={<ListaGrupos/>}>
              <Route path='/:nomeCliente/ListaGrupos' element={<GroupTable/>}></Route>
              {/* <Route path={`/:nomeCliente/ListaGrupos/HomeGrupo`} element={<MineGameComponent />}></Route> */}
            </Route>
            <Route path='/:nomeCliente/Grupo/:chatId/:afiliadoId/:tipoMesa/:nomeGrupo' element={<Grupo/>}></Route>
            <Route path='/:nomeCliente/ebook' element={<Ebookpage/>}></Route>
            <Route path='/:nomeCliente/EsqueciSenha/' element={<EsqueciSenhaPage/>}></Route>
            <Route path='/:nomeCliente/resetpassword=/:resetToken' element={<NovaSenhaPage/>}></Route>
            <Route path='/:nomeCliente/resetpassword' element={<ResetPasswordPerfil/>}></Route>

            <Route path='/:nomeCliente/CassinoIMG' element={<><img src={Cassino}/></>}></Route>
          </Route>

        }
          


        </Routes>
      </BrowserRouter>
    </>
  )
}
