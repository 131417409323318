import styled from 'styled-components'

interface IProps {
  corPrimaria?: string;
  imagem?: string;
  analisando?: boolean;
}

export const TopBar = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
 


`

export const InfoTopbar = styled.div`
  margin-top: 0px;
  padding-bottom: 4px; //...
  h1 {
    color: #FFF;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    
    display: flex;
    align-items: center;
    text-transform: capitalize;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 5px rgba(0, 0, 0, 0.2);
    margin: 0px;
  }
`

export const ContainerPage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100vw;
    height: 100%;
    max-height: 100vh;
   
    overflow-y: hidden;
    overflow-x: hidden;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100vw;
 
    padding: 10px;
    gap: 8px;
    overflow-y: scroll;
    overflow-x: hidden;
`;

export const Image = styled.div<IProps>`
  width: 68px;
  height: 68px;
  border-radius: 4px;
  background-image: url(${props => props.imagem});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  align-self: right;
`;

export const ContainerInfos = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const ContainerDica = styled.div<IProps>`
    background: ${props => props.corPrimaria}80;
    /* Orange */
    color: #FFF;
    width: 70vw;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center; 
    align-items: center;
    
    border: 1px solid ${props => props.corPrimaria};
    box-shadow: 0px 0px 5px 2px ${props => props.corPrimaria}50;
    border-radius: 25px;
    border: 1px solid var(--Primary-color);
    box-shadow: 0px 0px 5px 1px var(--Primary-color);
    border-radius: 50px;
    height: 64px;
    gap: 6px;
    padding: 10px;
    font-size: 18px;
    

    span{
      font-family: 'Montserrat';
      font-style: normal;
     
      text-align: center;
      color: #FFFFFF;
     
    }

    h1{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      color: #FFFFFF;
      margin: 0;
    }
`

export const ContainerLinkJogo = styled.div<IProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  


  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;

    color: #BDA192;
    width: 50%;
    

  }
`;

export const BotaoGame = styled.button<IProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props: IProps) => props.corPrimaria};
  border-radius: 50px;
  border: 1px solid ${(props: IProps) => props.corPrimaria};
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.5);
  padding: 0px;
  gap: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  height: 36px;
  width: 145px;
  

  text-align: right;

  color: #FFFFFF;
`


export const TextoInfoJogo = styled.p<IProps>`
padding-left: ${props => props.analisando ? '40px' : '0px'};
position: relative;
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;

gap: 8px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    

    text-align: left;
    color: #FFF;
    span {
        color: var(--Primary-color);
        font-weight: 700;
        font-size: 20px;
        @media screen and (max-width: 361px){
          font-size: 19px;
          margin-right: 0px;
        }
    }
`

export const TextoDetalhes = styled.p`
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    color: #FFF;

    &:nth-child(4){
      margin-top: -4px;
    }
`

export const StyledText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 20px;
  /* identical to box height */
  text-align: center;

  color: #FFFFFF;
`;

export const IframeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
    
  background: #000000;
  border: 1px solid var(--Primary-color);
  border-radius: 8px;
  width: 100%;
  height: 60vh;



  iframe {
    width: 100%;
    height: 100%;

    object-fit: fill;
    display: block;
    border-radius: 8px;
    padding: 7px;
  }
`;
