import styled from 'styled-components'
import {Box, Checkbox} from "@mui/material";

export const StyledBox = styled(Box)`

    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20vh;
    height: 350px;
    color: #000;
    outline: none;
    -webkit-focus-ring-color: transparent;

`;

export const ImagemModal = styled.img`
    width: 100px;
    height: 100px;
`;

export const DivContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    outline: none;
    width: 450px;
    margin: auto;
    
    height: 450px;
    
    -webkit-focus-ring-color: transparent;
    background: rgba(0, 0, 0, 0.7);
    gap: 20px;
    border: 1px solid var(--Primary-color);
    box-shadow: 0px 0px 7px 2px rgba(255, 255, 255, 0.2);
    border-radius: 12px;

    @media screen and (max-width: 500px){
        width: 95vw;
        
    }
`;

export const DivDoX = styled.div`
    
`;


export const DivButtons = styled.div`
    display: flex;
    flex-direction: column;
    
    align-items: center;
    justify-content: center;
    outline: none;
    -webkit-focus-ring-color: transparent;

    @media screen and (max-width: 770px){
        margin-top: -90px;
    }
`;


export const StyledImg = styled.img`
    width: auto;
    height: 500px;
    object-fit: contain;
    outline: none;
    -webkit-focus-ring-color: transparent;
    
    

    @media screen and (max-width: 770px){
        width: 100%;
        
    }
`;


export const StyledButton = styled.button`
    background: var(--ButtonBackground-primary);
    color: var(--ButtonTextColor-primary);
    border: none;
    width: 129px;
    height: 40px;
    outline: none;
    -webkit-focus-ring-color: transparent;
    border-radius: 20px;
    box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.2), inset 0px -10px 9px rgba(0, 0, 0, 0.05), inset 0px 1px 1px rgba(255, 255, 255, 0.5);
    border-radius: 20px;
`;

export const Htwo = styled.h2`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    color: #FFFFFF;
    width: 80%;
    
`;


export const StyledLittleSpan = styled.span`
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 24px;
/* identical to box height, or 200% */

letter-spacing: -0.22px;

color: #BDA192;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
`;

export const CustomCheckbox = styled(Checkbox)`
  &.Mui-checked {
    color: var(--Primary-color); /* Altere a cor de fundo desejada para quando o checkbox estiver marcado */
  }
`;
