import styled from 'styled-components';


export const PaiLogin = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: right;
  overflow-y: hidden;
  @media screen and (max-width: 769px){
    margin-left: 0px;
    
    height: 80vh;
    justify-content: center;
    align-items: center;
  }
`

export const DivFilho = styled.div`
    width: calc(100%-130px);
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 769px){
      width: 90vw;
      height: 80%;
      margin: auto;
      align-items: center;
      background: rgba(0,0,0,0.2);
      border-radius: 10px;
      
    }
`;

export const TituloPagina = styled.h1`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 39px;
    width: 100%;
    text-align: center;
    color: var(--Secondary-Text-color);
    
`;

